import { DiscoverModalContainerContext } from '@sugar-discover/react-bootstrap-wrapper';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useWindowResizeListener } from './window-resize.hook';

export const useViewportDimensions = () => {
  const containerRef = useRef();
  const container = useContext(DiscoverModalContainerContext);
  containerRef.current = container;
  const { width: resultWidth, height: resultHeight } = useResizeDetector({
    targetRef: containerRef,
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setWidth((resultWidth || container?.clientWidth) + 15);
    setHeight(resultHeight || container?.clientHeight);
  }, [container, resultHeight, resultWidth]);
  const {
    width: windowWidth,
    height: windowHeight,
  } = useWindowResizeListener();
  const retVal = useMemo(() => {
    if (container) {
      return { width, height };
    } else {
      return { width: windowWidth, height: windowHeight };
    }
  }, [container, height, width, windowHeight, windowWidth]);
  return retVal;
};

export const ViewportDimensionsInjector = ({ children }) => {
  const dimensions = useViewportDimensions();
  return children(dimensions);
};
