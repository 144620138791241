import {
  createContext,
  forwardRef,
  useContext,
  useRef,
  useEffect,
  ReactNode,
  ForwardedRef,
} from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import _, {
  filter,
  includes,
  isArray,
  isEmpty,
  isNumber,
  isString,
  map,
  toString,
} from 'lodash';
import { ISearchableDropdownOption } from './searchable-dropdown.interfaces';

// largely borrowed from https://mui.com/material-ui/react-autocomplete/ demo
const LISTBOX_PADDING = 8;
function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (_.has(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  return (
    <Typography
      key={dataSet.key}
      component='li'
      {..._.omit(dataSet[0], '$$typeof')}
      noWrap
      style={inlineStyle}
    >
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const ListboxComponent = forwardRef(
  (props: { children?: ReactNode }, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...other } = props;
    const itemData = [];
    _.forEach(children as object, (item: any) => {
      itemData.push(item);
      itemData.push(...(item?.children || []));
    });
    const itemCount = itemData.length;
    const itemSize = 48;

    const getChildSize = () => {
      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemCount * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={getChildSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export const createDefaultSelectedOptions = ({
  defaultValue,
  options = [],
}: {
  defaultValue?: ISearchableDropdownOption | ISearchableDropdownOption[];
  options: ISearchableDropdownOption[];
}) => {
  if (isEmpty(defaultValue) || isEmpty(options)) {
    return options;
  }

  const defaultSelectedOptions = filter(
    isArray(defaultValue)
      ? defaultValue ?? {}
      : ([defaultValue] as ISearchableDropdownOption[]),
    'value',
  );

  const selectedValues = map(defaultSelectedOptions, 'value');

  const _selectedOptions = map(options, ({ value, label }) => {
    if (includes(selectedValues, value)) {
      return {
        value,
        label,
        isSelected: true,
      } as ISearchableDropdownOption;
    }

    return {
      value,
      label,
      isSelected: false,
    } as ISearchableDropdownOption;
  });

  return _selectedOptions;
};

export const makeDropdownOption: (
  value: any,
  label?: string | number,
) => ISearchableDropdownOption = (value, label) => ({
  value,
  label: isString(label) || isNumber(label) ? label : toString(value),
});
