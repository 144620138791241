import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import { Hierarchy, Viz } from '../../../discovery/VizUtil';
import { ShelfTypes } from '../../../discovery/interfaces';
import { TOTALS_FLAG } from '../../../discovery/charts/pivot/QueryPivotUtils';
import {
  FISCAL_CALENDAR_START_DATE,
  FISCAL_CALENDAR_YEAR_TYPE,
  NULL_DISPLAY,
  NULL_TOKEN,
  USE_FISCAL_REPORTING,
  VIZ,
  Types,
  DynamicFields,
  FieldTypes,
} from '../../Constants';
import { reverseSlugLookup } from '../../Constants';
import { createOrdinalName } from '../../../discovery/charts/ChartUtils';
import { IDiscovery } from '../../../discovery';
import {
  IVizQueryExecuteQueryResults,
  IVizQueryResult,
  IVizQueryResultColumnInfo,
} from '../../graphql/viz-query.interfaces';
import { getIsReadOnlyUser } from './AccountSelectors';
import { ITimeCalcAttribute } from '../../../datasets';

/**
 * buildNameFormArrayParts
 * @param theArray
 * @param indexes
 */
function buildNameFormArrayParts(theArray, indexes) {
  const parts = indexes.reduce((acc, i) => {
    return [...acc, _.get(theArray, i)];
  }, []);
  return parts.join('_').toUpperCase();
}

export function getVizQuerySorts(viz) {
  try {
    return JSON.parse(viz.options.querySort);
  } catch (err) {
    return [];
  }
}

export interface IQuerySortItem {
  shelfName: string;
  fieldName: string;
  direction: 'asc' | 'dsc' | 'remove_sort';
  priority: number; // added in OpenDiscoveryReducer?
  type?: typeof Types;
}

export interface IQuerySortByFieldName {
  [fieldName: string]: IQuerySortItem[];
}

export interface ISortedVizQueryResultColumnInfo
  extends IVizQueryResultColumnInfo {
  isClientSideAggregation?: boolean;
}
export interface ISortedVizQueryResult extends IVizQueryResult {
  columnInfo: ISortedVizQueryResultColumnInfo[];
  querySort: IQuerySortByFieldName;
}

export interface ISortedQueryData extends IVizQueryExecuteQueryResults {
  executeQuery: ISortedVizQueryResult;
}

// many charts have a `transformResult` method that overrides this sorting. We should offload sorting to the charts
export const getSortedQueryData = (
  presentViz: IDiscovery,
  querySortOverrides = null,
): null | IVizQueryExecuteQueryResults | ISortedQueryData => {
  const queryResultsOriginal = presentViz.vizQueryResults
    ? presentViz.vizQueryResults.data
    : null;
  if (!queryResultsOriginal) {
    return null;
  }
  const { chartType } = presentViz.viz;
  const layouts = presentViz.viz.layout;
  const chartSpec = ChartSpecs[chartType];
  const metricShelfNames = _(chartSpec.shelves)
    .values()
    .filter({ shelfType: ShelfTypes.MEASURE })
    .map('name')
    .value();

  if (chartType === 'pivot') {
    return queryResultsOriginal;
  }

  /*
    isColumn is set on chartSpec and is used to flag a shelf as a column
    Needed for some business rules, such as...
    When there is a column and sort by one metric, it actually sorts by sum of all metrics
  */
  const columnName = _.findKey(chartSpec.shelves, 'isColumn');

  // build a lookup db for shelves
  const layoutKeys = _.keys(layouts);
  const layoutdb = layoutKeys.reduce((acc, layoutName) => {
    acc = acc.concat(layouts[layoutName]);
    return acc;
  }, []);

  let shelfColumnNames;
  let groupIndexes = [0];
  const groupNamesUsing = _.findKey(chartSpec.shelves, 'groupNames');
  if (groupNamesUsing) {
    shelfColumnNames = _.map(layouts[groupNamesUsing], 'name');
    groupIndexes = queryResultsOriginal.executeQuery.columnNames.reduce(
      (acc, item, indx) => {
        if (_.includes(shelfColumnNames, item)) {
          acc.push(indx);
        }
        return acc;
      },
      [],
    );
  }

  const queryResults: IVizQueryExecuteQueryResults = JSON.parse(
    JSON.stringify(queryResultsOriginal),
  );
  const executeQuery: IVizQueryResult = get(queryResults, 'executeQuery', {
    columnNames: [],
    columnInfo: [],
    results: [],
  });
  let { results } = executeQuery;
  const { options } = presentViz.viz;

  const metricShelfValueIndexes = executeQuery.columnInfo.reduce(
    (accum, column, index) => {
      if (column.columnType === 'MEASURE') {
        // determine what shelf does this belong to
        const shelfId = Viz.findShelfContainingField(
          layouts,
          column.attributeName,
        );
        if (_.isNil(accum[shelfId])) {
          accum[shelfId] = [];
        }
        accum[shelfId].push(index);
      }
      return accum;
    },
    {},
  );

  let querySort;
  try {
    querySort = JSON.parse(options.querySort as string);
  } catch (e) {
    querySort = {};
  }

  const sortManager = new SortFunctionManager();

  // add in all the indexes
  const numItemsInColumns = layouts[columnName]
    ? layouts[columnName].length
    : 0;
  querySort = querySortOverrides || querySort;
  querySort = mapValues(querySort, (val, key) => {
    let index = executeQuery.columnNames.indexOf(`${val.fieldName}`);
    const indexOfDynamicOrdinal = executeQuery.columnNames.indexOf(
      createOrdinalName(key),
    );
    const objOfSetOrdinal = _.find(layoutdb, obj => {
      return (
        obj.name === key &&
        obj.ordinalAttribute &&
        obj.ordinalAttribute.length > 0
      );
    });
    const indexOfSetOrdinal = objOfSetOrdinal
      ? executeQuery.columnNames.indexOf(`${objOfSetOrdinal.ordinalAttribute}`)
      : -1;
    if (indexOfSetOrdinal >= 0) {
      index = indexOfSetOrdinal;
    } else if (indexOfDynamicOrdinal >= 0) {
      index = indexOfDynamicOrdinal;
    } else if (_.includes(metricShelfNames, val.shelfName)) {
      if (numItemsInColumns > 0) {
        return { ...val, index: -1 };
      }

      // support more than one possible metric shelf
      const metricShelf = _(chartSpec.shelves)
        .values()
        .find({ name: val.shelfName });
      const valIndex = _.findIndex(layouts[metricShelf.id], {
        name: val.fieldName as string,
      });
      try {
        index = metricShelfValueIndexes[metricShelf.id][valIndex];
      } catch (e) {
        console.error('no value index');
      }
    }
    const priority = _.isNil(columnName)
      ? val.priority
      : chartSpec.shelves[columnName].name === val.shelfName
      ? val.priority * 10
      : val.priority;

    return { ...val, priority, index };
  });

  // handle times
  let userSelectedTimeSorts = _.filter(_.values(querySort), {
    type: Types.TIME_CALC,
  });
  userSelectedTimeSorts = _.orderBy(
    userSelectedTimeSorts,
    ['priority'],
    ['desc'],
  );
  if (userSelectedTimeSorts.length > 0) {
    const timeObj = userSelectedTimeSorts[0];
    const timeShelfName = _.findKey(
      chartSpec.shelves,
      item => _.toLower(item.name) === _.toLower(timeObj.shelfName),
    );
    const timeSorts = _<ITimeCalcAttribute>(layouts[timeShelfName])
      .filter({ attributeType: Types.TIME_CALC as FieldTypes })
      .map(item => {
        const _priority = _.find(Hierarchy.TIME_ATTRIBUTES, {
          displayText: item.fieldListDisplayName,
        }).order;
        const index = executeQuery.columnNames.indexOf(item.name);
        let sortFieldIndex = executeQuery.columnNames.indexOf(
          createOrdinalName(item.name),
        );
        if (sortFieldIndex < 0) {
          sortFieldIndex = index;
        }
        return {
          ...timeObj,
          index,
          fieldName: item.name,
          priority: +`${timeObj.priority}.${_priority}`,
          sortFieldIndex,
        };
      })
      .reduce((acc, item) => {
        acc[item.fieldName] = item;
        return acc;
      }, {});
    querySort = _.omitBy(querySort, { type: Types.TIME_CALC });
    _.assign(querySort, timeSorts);
  }

  // default sort if no other sort is specified and there is a time field on the x-axis
  const xaxisFields = _.get(layouts, 'XAXIS', []);
  const xaxisTimeFields = xaxisFields.filter(
    item => item.attributeType === Types.TIME_CALC,
  );
  if (_.isEmpty(querySort) && !_.isEmpty(xaxisTimeFields)) {
    const fieldName = xaxisTimeFields[0].name;
    const index = executeQuery.columnNames.indexOf(fieldName);
    let sortFieldIndex = executeQuery.columnNames.indexOf(
      createOrdinalName(fieldName),
    );
    if (sortFieldIndex < 0) {
      sortFieldIndex = index;
    }
    querySort[fieldName] = {
      direction: 'asc',
      fieldName,
      index: 0,
      priority: 1,
      shelfName: _.get(chartSpec, 'shelves.XAXIS.name', 'X-Axis'),
      sortFieldIndex,
      type: Types.TIME_CALC,
    };
  }

  // add to sortManager
  _.forEach(querySort, val => {
    const fun = item => {
      const index = val.index < 0 ? item.length - 1 : val.index;
      const sortFieldIndex = _.get(val, 'sortFieldIndex', index);
      const sortValue = item[sortFieldIndex];
      return sortValue === NULL_DISPLAY ? 0 : sortValue;
    };
    sortManager.addFunc(fun, val.direction, val.priority);
  });

  // add sum of m0...
  executeQuery.columnNames.push('sum');
  executeQuery.columnInfo.push({
    columnType: 'MEASURE',
    columnName: 'sum',
    isClientSideAggregation: true,
  } as ISortedVizQueryResultColumnInfo);
  const aggregateHashes = results
    .filter(arr => !_.includes(arr, TOTALS_FLAG))
    .reduce((acc, arr) => {
      const id = buildNameFormArrayParts(arr, groupIndexes);

      // add up all fields that come from metric shelves
      const total = _(metricShelfValueIndexes)
        .toPairs()
        .filter(([shelfId]) => {
          const shelf = chartSpec.shelves[shelfId];
          return _.get(shelf, 'includeInSortAggregation', true);
        })
        .reduce((totalAccum, [, shelfFieldIndexes]) => {
          const shelfTotal = (shelfFieldIndexes as any[]).reduce(
            (valueAcc, index) => {
              const val = arr[index];
              const valToAdd =
                val === NULL_DISPLAY ? 0 : _.isNaN(+val) ? 0 : +val;
              valueAcc += valToAdd;
              return valueAcc;
            },
            0,
          );
          return shelfTotal + totalAccum;
        }, 0);

      if (!acc[id]) {
        acc[id] = 0;
      }
      acc[id] += total;
      return acc;
    }, {});
  results = results.map(row => {
    const id = buildNameFormArrayParts(row, groupIndexes);
    row.push(aggregateHashes[id]);
    return row;
  });
  // end add sum
  if (sortManager.length > 0) {
    results = sortManager.order(results);
  }
  queryResults.executeQuery = {
    ...executeQuery,
    results,
    querySort,
  } as ISortedVizQueryResult;

  return queryResults;
};

export class SortFunctionManager {
  sortFunctionsList: any[];

  constructor() {
    this.reset();
  }

  reset() {
    this.sortFunctionsList = [];
  }

  addFunc(func, direction, priority = 1) {
    this.sortFunctionsList.push({ func, direction, priority });
  }

  get length() {
    return this.sortFunctionsList.length;
  }

  _getOrderedList() {
    return orderBy(this.sortFunctionsList, ['priority'], ['asc']);
  }

  getFunctions() {
    const list = this._getOrderedList();
    return list.map(item => {
      return item.func;
    });
  }
  getDirections() {
    const list = this._getOrderedList();
    return list.map(item => {
      return item.direction;
    });
  }
  order(collection) {
    return _.orderBy(collection, this.getFunctions(), this.getDirections());
  }
}

const getDiscoveryId = (state: any, props) =>
  _.isNil(props?.discoveryId)
    ? state.discover.displayDiscovery
    : props?.discoveryId;

const getOpenDiscoveries = (state: any) => state.discover.openDiscoveries;

const getActive = createSelector(
  [getDiscoveryId, getOpenDiscoveries],
  (discoveryId, openDiscoveries) => {
    if (discoveryId && openDiscoveries) {
      return _.get(openDiscoveries, `${discoveryId}.present`, null);
    } else {
      return null;
    }
  },
);

const focusedData = createSelector([getActive], _.property('focusedData'));

const focusedDataPoints = createSelector(
  [getActive],
  _.property('focusedDataPoints'),
);

const getIsDirty = createSelector([getActive], _.property('dirty'));

const getActiveViz = createSelector(
  [getActive, (state, props) => props?.viz],
  (open, propsViz) => {
    // favor a viz passed by props over the one in state
    return _.isNil(propsViz) ? _.get(open, 'viz', {}) : propsViz;
  },
);
const getActiveDataset = createSelector([getActive], open => {
  return _.get(open, 'dataset', {});
});
const getActiveDatasetAnnotations = createSelector(
  [getActiveDataset],
  dataset => {
    return _.get(dataset, 'annotations', []);
  },
);
const getActiveDatasetAttributes = createSelector([getActiveViz], viz => {
  return _.get(viz, 'dataset.attributes', []);
});
const getActiveVizChartSpec = createSelector(
  [getActiveViz],
  viz => ChartSpecs[viz.chartType],
);
const getActiveVizLayout = createSelector(
  [getActiveViz],
  viz => viz.layout || {},
);
const getActiveVizOptions = createSelector([getActiveViz], open =>
  _.get(open, 'options', {}),
);
export const getAllowedTabs: (state: any, props?: any) => any = createSelector(
  [getIsReadOnlyUser],
  isReadOnlyUser => {
    return isReadOnlyUser ? ['detail'] : ['layout', 'format', 'detail'];
  },
);
export const getConfigPanelDetail: (
  state: any,
  props: any,
) => any = createSelector(
  [getAllowedTabs, getActiveVizOptions],
  (allowedTabs, options) => {
    const { configPanelDetail } = options || {};
    return _.includes(allowedTabs, configPanelDetail)
      ? configPanelDetail
      : null;
  },
);
const getActiveVizTimeHierarchiesString = createSelector(
  [getActiveVizOptions],
  options => _.get(options, 'timeHierarchies'),
);
const getActiveVizTimeHierarchies = createSelector(
  [getActiveVizTimeHierarchiesString],
  timeHierarchies =>
    _.isEmpty(timeHierarchies) ? undefined : JSON.parse(timeHierarchies),
);
const getActiveVizCalcFieldsString = createSelector(
  [getActiveVizOptions],
  options => _.get(options, 'calcFields'),
);
const getActiveVizCalcFields = createSelector(
  [getActiveVizCalcFieldsString],
  calcFields => (_.isEmpty(calcFields) ? [] : JSON.parse(calcFields)),
);
const getActiveVizFiltersString = createSelector(
  [getActiveVizOptions],
  options => _.get(options, 'filters'),
);
const getActiveVizFilters = createSelector(
  [getActiveVizFiltersString],
  filters =>
    _.isEmpty(filters)
      ? {}
      : _(JSON.parse(filters as string))
          .omitBy(
            ({
              expression: {
                left: { operator = undefined, operands = undefined } = {},
              } = {},
            } = {}) =>
              _.includes(['dynamicField', 'currentUser'], operator) &&
              !reverseSlugLookup(_.head(operands)),
          )
          .value(),
);
const getActiveVizQuerySortString = createSelector(
  [getActiveVizOptions],
  options => _.get(options, 'querySort'),
);
const getActiveVizFiscalSetting = createSelector(
  [getActiveVizOptions],
  options => _.get(options, USE_FISCAL_REPORTING),
);
const getLiveQueryOption = createSelector([getActiveVizOptions], options =>
  _.get(options, 'useLiveQuery'),
);
const getActiveVizQuerySort = createSelector(
  [getActiveVizQuerySortString],
  querySort => (_.isEmpty(querySort) ? {} : JSON.parse(querySort)),
);
const getCustomFormatToggles = createSelector([getActiveViz], viz => {
  return Viz.getCustomFormatTogglesFromViz(viz);
});
const getAvailableFields = createSelector([getActiveViz], viz => {
  return Viz.getAllAvailableFields(viz);
});
const getActiveVizPanelWidth = createSelector(
  [getActiveVizOptions],
  options => {
    return parseInt(_.get(options, 'leftPanelWidth', VIZ.LEFT_PANEL_MIN_WIDTH));
  },
);
const getActiveDatasetFiscalYearSetting = createSelector(
  [getActiveDatasetAnnotations],
  annotations => _.find(annotations, { key: FISCAL_CALENDAR_YEAR_TYPE })?.value,
);
const hasVizDatasetFiscalCalendarSetting = createSelector(
  [getActiveDatasetAnnotations],
  annotations => {
    return (
      _.size(
        _.filter(annotations, anno => {
          return (
            anno.key === FISCAL_CALENDAR_START_DATE ||
            anno.key === FISCAL_CALENDAR_YEAR_TYPE
          );
        }),
      ) === 2
    );
  },
);

export const getSlicerNames: (state: any, props: any) => any = createSelector(
  [getActive],
  (discovery: any) => {
    const slicers = discovery?.viz?.layout?.SLICER ?? [];
    return _(slicers)
      .map('name')
      .without(NULL_TOKEN)
      .reject(_.isNil)
      .value();
  },
);

export const getActiveSlicers: (state: any, props: any) => any = createSelector(
  [getActive],
  (discovery: any) => {
    const slicers = discovery?.viz?.layout?.SLICER ?? [];
    return _(slicers)
      .without(NULL_TOKEN)
      .reject(_.isNil)
      .value();
  },
);

export const getSelectedSlicers: (
  state: any,
  props: any,
) => any = createSelector([getActiveVizOptions], options => {
  try {
    return JSON.parse(_.get(options, 'slicerSelections', '[]'));
  } catch (err) {
    return [];
  }
});

export const isFiscalCalendarActive = (state, ownProps) => {
  return (
    hasVizDatasetFiscalCalendarSetting(state, ownProps) &&
    getActiveVizFiscalSetting(state, ownProps) === 'true'
  );
};

export const getDynamicFieldConfig = createSelector(
  _.identity as any,
  state => {
    const {
      discover: { activeFilterField },
    } = state as { main: any; discover: any };
    const dynamicField = _.find(activeFilterField.annotations, {
      key: 'REF_TO_FIELD',
    })?.value;
    const [sugarModule, field] = _.split(dynamicField, ':');
    const fieldSlug = _.get(DynamicFields, [sugarModule, field]);
    if (!sugarModule || !field || !fieldSlug) {
      return;
    }
    return { sugarModule, field, fieldSlug };
  },
);

export const VIZ_SELECTORS = {
  getDiscoveryId,
  getLiveQueryOption,
  getOpenDiscoveries,
  getActive,
  getActiveViz,
  getActiveVizChartSpec,
  getActiveVizLayout,
  getActiveVizOptions,
  getActiveVizTimeHierarchies,
  getActiveVizCalcFields,
  getActiveVizFilters,
  getIsDirty,
  getCustomFormatToggles,
  getAvailableFields,
  getActiveVizQuerySort,
  getActiveVizFiscalSetting,
  getActiveDataset,
  getActiveDatasetAttributes,
  getActiveDatasetAnnotations,
  hasVizDatasetFiscalCalendarSetting,
  getActiveDatasetFiscalYearSetting,
  getActiveVizPanelWidth,
  focusedData,
  focusedDataPoints,
  isFiscalCalendarActive,
};
