import {
  FormGroup,
  FormControl,
  ControlLabel,
} from '@sugar-discover/react-bootstrap-wrapper';

export const FormControlAdapter = ({
  input,
  meta,
  label,
  control,
  className,
  ...otherProps
}) => {
  const Comp = control || FormControl;
  return (
    <FormGroup className={className}>
      {label ? <ControlLabel>{label}</ControlLabel> : null}

      <Comp {...input} type='text' {...otherProps} className={className} />
    </FormGroup>
  );
};
