import { DefaultFontName } from '../../../components/ui/fonts';

import * as SugarColorPalette from '@sugarcrm/maple-syrup/build/color/js/sugarColorPalette';

// All colors defined here are provided as the Discover Theme

// Sugar Palettes
export const SugarPrimaryPalette = {
  LightBlue: '#D6EEFF',
  MediumBlue: '#0679C8',
  DarkBlue: '#005D9C',
};
export const SugarSecondaryPalette = {
  LightOcean: SugarColorPalette.ColorBaseIndigo200,
  LightPacific: SugarColorPalette.ColorBaseCyan200,
  LightTeal: SugarColorPalette.ColorBaseTeal200,
  LightGreen: SugarColorPalette.ColorBaseEmerald200,
  LightArmy: SugarColorPalette.ColorBaseLime200,
  LightYellow: SugarColorPalette.ColorBaseYellow200,
  LightOrange: SugarColorPalette.ColorBaseOrange200,
  LightRed: SugarColorPalette.ColorBaseRed200,
  LightCoral: SugarColorPalette.ColorBaseFuschia200,
  LightPink: SugarColorPalette.ColorBasePink200,
  LightPurple: SugarColorPalette.ColorBaseViolet200,
  LightIndigo: SugarColorPalette.ColorBaseIndigo200,
  MediumOcean: SugarColorPalette.ColorBaseIndigo500,
  MediumPacific: SugarColorPalette.ColorBaseCyan500,
  MediumTeal: SugarColorPalette.ColorBaseTeal500,
  MediumGreen: SugarColorPalette.ColorBaseEmerald500,
  MediumArmy: SugarColorPalette.ColorBaseLime500,
  MediumYellow: SugarColorPalette.ColorBaseYellow400,
  MediumOrange: SugarColorPalette.ColorBaseOrange500,
  MediumRed: SugarColorPalette.ColorBaseRed500,
  MediumCoral: SugarColorPalette.ColorBaseFuschia500,
  MediumPink: SugarColorPalette.ColorBasePink500,
  MediumPurple: SugarColorPalette.ColorBaseViolet500,
  MediumIndigo: SugarColorPalette.ColorBaseIndigo400,
  DarkOcean: SugarColorPalette.ColorBaseIndigo800,
  DarkPacific: SugarColorPalette.ColorBaseCyan800,
  DarkTeal: SugarColorPalette.ColorBaseTeal800,
  DarkGreen: SugarColorPalette.ColorBaseEmerald800,
  DarkArmy: SugarColorPalette.ColorBaseLime800,
  DarkYellow: SugarColorPalette.ColorBaseYellow700,
  DarkOrange: SugarColorPalette.ColorBaseOrange800,
  DarkRed: SugarColorPalette.ColorBaseRed800,
  DarkCoral: SugarColorPalette.ColorBaseFuschia800,
  DarkPink: SugarColorPalette.ColorBasePink800,
  DarkPurple: SugarColorPalette.ColorBaseViolet800,
  DarkIndigo: SugarColorPalette.ColorBaseIndigo600,
};
export const SugarGrayPalette = {
  Black: SugarColorPalette.ColorBaseBlack,
  Gray95: SugarColorPalette.ColorBaseGray950,
  Gray90: SugarColorPalette.ColorBaseGray900,
  Gray80: SugarColorPalette.ColorBaseGray800,
  Gray70: SugarColorPalette.ColorBaseGray700,
  Gray60: SugarColorPalette.ColorBaseGray600,
  Gray50: SugarColorPalette.ColorBaseGray500,
  Gray40: SugarColorPalette.ColorBaseGray400,
  Gray30: SugarColorPalette.ColorBaseGray300,
  Gray20: SugarColorPalette.ColorBaseGray200,
  Gray10: SugarColorPalette.ColorBaseGray100,
  White: SugarColorPalette.ColorBaseWhite,
};

/* Exporting flattened constants for Discover Theme */
export const {
  Gray90,
  Gray80,
  Gray70,
  Gray60,
  Gray50,
  Gray40,
  Gray30,
  Gray20,
  Gray10,
  White,
} = SugarGrayPalette;
export const { LightBlue, MediumBlue, DarkBlue } = SugarPrimaryPalette;
export const {
  MediumGreen,
  MediumOcean,
  MediumRed,
  MediumYellow,
  LightRed,
  DarkRed,
} = SugarSecondaryPalette;

export const SuccessColor = SugarColorPalette.ColorBaseGreen500;
export const DangerColor = SugarSecondaryPalette.MediumRed;
export const WarningColor = SugarSecondaryPalette.MediumYellow;
export const InfoColor = SugarSecondaryPalette.MediumPacific;
export const PrimaryColor = SugarPrimaryPalette.MediumBlue;
export const GrayIconColor = SugarColorPalette.ColorBaseGray500;
export const DisabledIconColor = SugarColorPalette.ColorBaseGray300;

export const AccentColor = '#005087';
export const DimText = '#E5E5E5';

/* Main Menu */
export const ContentText = '#3B414F';
export const ContentTextFocus = '#022049';
export const ContentBackground = SugarColorPalette.ColorBaseWhite;
export const ActiveLinkText = PrimaryColor;
export const Strokes = '#D8D8D8';
export const ContentBlack = SugarColorPalette.ColorBaseBlack;

/* Strokes, Xs, and Dividers*/
export const TabHighlight = PrimaryColor;
export const TabBackground = '#F6F6F6';
export const TabHighlightHover = '#BFC7D0';
export const TabHighlightDisabled = SugarColorPalette.ColorBaseGray400;
export const PanelBackground = SugarColorPalette.ColorBaseGray100;
export const MediumBorder = '#D1D3D4';
export const PanelBorder = '#D4D3D3';
export const ErrorColor = '#E60000';
export const SubtotalBackground = '#EFF2F5';
export const FieldPillBackgroundHover = 'rgba(151,194,234, 0.25)';
export const FieldPillBackgroundHoverBorder = 'rgba(151,194,234, 0.25)';
export const FieldPillBackgroundDrag = 'rgba(151,194,234, 0.25)';
export const FieldPillBackgroundActive = '#003968';
export const FieldPillBackgroundActiveBorder = '#003968';

/* Icons */
export const ToolbarIconFill = '#A7AAB1';

/* Toolbar Icons and Close*/
export const ToolIconColor = '#A7AAB1';
export const ToolIconColorSecondary = '#81848F';
export const ToolIconHoverColor = ActiveLinkText;
export const ToolIconSecondaryHoverColor = '#7FA5FD';
export const PanelIconColor = '#81848F';
export const PanelIconHoverColor = ActiveLinkText;
export const PanelIconActiveColor = '#012147';
export const PanelIconDisabledColor = '#D3D4D8';
export const IconGreenColor = '#458534';
export const IconBlueColor = '#446BA2';
export const IconActiveLightColor = '#819BB4';
export const IconActiveDarkColor = '#03376A';
export const PanelIconSecondaryColor = '#C0C1C7';
export const PanelNavIconColor = '#818490';
export const PanelNavBackground = '#EFF2F5';
export const DefaultButtonTextColor = PrimaryColor;
export const DefaultButtonBorderColor = SugarColorPalette.ColorBaseGray300;
export const DefaultButtonTextColorHover = PrimaryColor;
export const DefaultButtonBorderColorHover = '#D3DBE1';
export const DefaultButtonTextColorActive = DefaultButtonTextColorHover;
export const DefaultButtonBorderColorActive = DefaultButtonBorderColorHover;
export const DefaultButtonTextSecondaryColorActive = '#8090A3';
export const DefaultButtonFillColor = '#F8FAFD';
export const DefaultButtonFillColorHover = '#F2F5F9';
export const ChartLabelTextColor = '#435370';
export const IconTextHoverColor = PrimaryColor;
export const IconPrimaryHoverColor = SugarColorPalette.ColorBaseGray500;
export const IconSecondaryColorHover = '#8AB6EA';
export const ButtonColorPrimary = PrimaryColor;
export const DisabledPrimaryButtonColor = 'rgba(6,121,200, 0.3)';
export const ButtonColorPrimaryHover = AccentColor;
export const ButtonBorderColorPrimaryHover = ButtonColorPrimaryHover;
export const RequiredTextColor = SugarColorPalette.ColorBaseRed500;
export const ErrorStatusTextColor = SugarColorPalette.ColorBaseRed500;
export const ErrorStatusBackground = SugarColorPalette.ColorBaseRed100;
export const ValidStatusTextColor = '#21B91E';
export const PhoneNumberTextColor = '#4A90E2';
export const TableHeaderBackground = PanelBackground;
export const TableHeaderBackgroundDark = '#DFE4EC';
export const TableHeaderBorderDark = '#B7BDC8';
export const TableHeaderModalBackground = '#FBFBFB';
export const TableBodyBackground = SugarColorPalette.ColorBaseWhite;
export const TableRowHover = '#F8F9F9';
export const TableBorderColor = SugarColorPalette.ColorBaseGray300;
export const PivotHeaderBackground = SugarColorPalette.ColorBaseWhite;
export const PivotCell = SugarColorPalette.ColorBaseWhite;
export const PivotSortHover = '#F2F3F5';
export const PivotCellHighlight = '#E0FAFF';
export const PivotCellHighlightHover = '#DAF2F8';
export const PivotCellHighlightSecondary = '#FFF5E1';
export const PivotCellHighlightSecondaryHover = '#F7EDDB';
export const PivotFlatHeaderBackground = TableHeaderBackgroundDark;
export const PivotFlatCell = SugarColorPalette.ColorBaseWhite;
export const PivotTotals = TableHeaderBackgroundDark;
export const TooltipColor = '#303C50';
export const SugarToastBackground = ContentBackground;

/* Font */
export const BaseFont = DefaultFontName;
export const baseFontColor = '#3b414f';
export const StrongFont = DefaultFontName;

/* LEGACY - TODO: Convert to new branding... */
export const CorvanaFaintBlue = '#B5D6F1';
export const CorvanaBrightBlue = PrimaryColor;
export const hoverHighlight = '#F2F3F3';
export const positiveColor = '#5CB85C';
export const warningColor = '#F3C431';
export const negativeColor = SugarColorPalette.ColorBaseRed500;
export const negativeBackgroundColor = SugarColorPalette.ColorBaseRed100;
export const CorvanaMediumBorder = SugarColorPalette.ColorBaseGray300;
export const CorvanaLightText = '#A7AAB1';
export const CorvanaBackgroundLight = PanelBackground;
export const CorvanaBackgroundMedium = '#F2F3F3';
export const CorvanaBackgroundDark = '#5D616D';
export const CorvanaMediumText = '#81848F';
export const errorBackground = SugarColorPalette.ColorBaseRed100;
export const CorvanaBrandOrangeHighlight = '#FE9D00';
export const LoginButtonColor = PrimaryColor;
export const LoginHoverColor = AccentColor;
export const LoginDisabledColor = DisabledPrimaryButtonColor;

/* Font Weights */
export const LightFontWeight = 300;
export const RegularFontWeight = 400;
export const MediumFontWeight = 500;
export const SemiboldFontWeight = 600;
export const StrongFontWeight = 700;
export const CorvanaButtonIconColor = '#A7AAB1';
export const anomalyColor = negativeColor;
export const DropdownTextPrimary = '#1F1F1F';
export const DropdownBackgroundHover = '#c6e7fd';
export const DropdownTextHover = PrimaryColor;
export const InputTextColor = '#373A3C';
export const DropdownButtonBorderColor = '#C7C9CA';

export const StandardButtonColor = '#81848F';
export const BorderColor = SugarColorPalette.ColorBaseGray300;
export const TableInputDisabledBackgroundColor = '#F6F6F6';
export const TableHeaderColor = '#F9FAFA';
export const TextInputBorderColor = SugarColorPalette.ColorBaseGray300;
export const TableHeaderBackgroundColor = '#F9FAFB';
export const TableHeaderTextColor = '#3B414F';
export const TableRowSecondaryTextColor = '#818490';
export const TableRowHoverColor = '#F8F9F9';
export const TableTextErrorColor = '#f00';
export const SVGIconColor = '#818490';
export const SVGIconHoverColor = '#004FCD';
export const TabHighlightColor = '#3B414F';
export const TabDefaultColor = '#818490';
export const TabActiveColor = '#012247';
export const TabDisabledColor = '#D1D3D4';
export const MenuHoverBackgroundColor = '#f5f5f5';
export const VizBarColorLight = '#e0ecff';

export const InfoColorHeaderText = '#15406A';

export const HeaderGrey = '#F6F7F9';
export const ErrorTextColor = '#C83A26';
export const ContentTextColor = '#3B414F';

export const CorbotoLoadingColor = '#004BFC';
export const TooltipBackgroundVColor = '#303C50';
export const TooltipBackgroundVColorText = SugarColorPalette.ColorBaseGray300;

export const ModalButtonHoverFontColor = '#4C5362';

export const ActivityFeedChartBackground = '#FCFCFC';
export const ActivityFeedChartBorder = '#E2E2E2';

export const DisabledFormControlBackgroundColor = '#f2f3f3';

export const DarkBorderColor = '#9DA0A8';
export const TooltipBackgroundColor = '#303C50';
export const FunnelBackgroundColor = '#F9FAFB';

export const ValLineStroke = '#0F71DC';
export const ValAreaFill = '#CEE3F8';
export const BrushGrabberFill = '#CBCDCE';
export const ChartTooltipBackground = '#1380cb';
export const ChartTooltipHoverBackground = '#0d598d';
export const ScoreRectFill = '#999999';
export const LineChartDotStroke = '#5f5f5f';
export const PipelineChangesForecastColor = MediumOcean;
export const PipelineChangesIncreasesColor = '#18BA83';
export const PipelineChangesDecreasesColor = '#E42D02';
export const PipelineChangesClosedWonColor = '#FED032';

export const NavDropdownBackground = '#E4E9ED';
export const Black = SugarColorPalette.ColorBaseBlack;

export const ModalHeaderBackground = '#EFF0F0';
export const SensitivityHeaderText = '#2D313E';
export const DisabledText = '#AAA7A7';

export const GlyphIconColor = '#6E717C';
export const MobileIconButton = '#555555';
export const MobileIconButtonHover = '#222222';

export const MainNavHover = '#f7f8f9';
export const ShareDropdownColor = 'rgba(59, 65, 79, 0.48)';
export const FormControlShadowColor = 'rgba(0, 0, 0, 0.075)';
export const SlicerShadowColor = 'rgba(0, 0, 0, 0.35)';
export const PanelShadowColor = 'rgba(0, 0, 0, 0.15)';

export const SettingsProfileDropzoneActive = '#666666';

export const SelectRowPropColor = '#EAF3FF';

export const PivotGreen = MediumGreen;
export const PivotYellow = MediumYellow;
export const PivotRed = MediumRed;
export const PivotWhite = SugarColorPalette.ColorBaseWhite;
export const PivotBlue = MediumBlue;

export const AnomalyColor = '#B9261D';
export const FixedErrorModalShadowColor = '#2e2e2e';

export const InvalidBullet = '#e6e6e8';
export const PivotCellBorder = '#eee';
export const TextCellColor = '#9699A2';

export const Clear = SugarColorPalette.ColorBaseBlack;
export const MainHeaderNavActive = '#00ffff';

export const AnomolyPrimaryPlotLine = '#4569A5';

export const BiggestMoverTopBullet = '#2ab800';
export const BiggestMoverBottomBullet = '#c83a26';
export const Green = '#008000';

export const FocusedOuterShadow = 'rgba(15, 113, 220, 0.6)';

export const ColorBoxBorderColor = '#b4b2b2';
export const TextInputBorderColorFocus = '#66afe9';
export const TextInputBorderColorError = '#a04442';
export const TextInputBorderColorErrorFocused = '#843534';
export const TextInputAdornmentErrorColor = '#a94442';
export const TextInputAdornmentErrorBackground = '#f2dede';
export const TextInputErrorGlow = '#ce8483';
export const TextInputBoxShadow = 'rgb(0 0 0 / 8%)';
export const DatasetModuleJoinsAreaBorder = '#cccccc';
export const DatasetIngestionTableHeader = '#efefef';
export const DatasetIngestionJoinsTableInputLabel = '#777777';
export const DatePreviewBox = SugarColorPalette.ColorBaseGray100;
export const DatePreviewBoxBorder = 'rgba(0, 0, 0, 0.3)';
export const ClearWhite = SugarColorPalette.ColorBaseWhite;
export const TooltipTextColor = ContentBackground;

export const DashletLibraryHighlightRowBackground = CorvanaLightText;
export const DropdownOptionHoverBackground = SugarColorPalette.ColorBaseGray100;
export const ContrastColorDark = DropdownTextPrimary;
export const ContrastColorLight = ContentBackground;
export const ActiveTabTextColor = MediumBlue;
export const TableRowBackgroundHover = 'rgb(248, 249, 249)';

export const TimePickerHeaderBackground = Strokes;
export const SelectedDateBackgroundColor = DefaultButtonTextColorActive;
export const SelectedDateRangeBackgroundColor = '#ebf4f8';
export const SelectedDateTextColor = ContentBackground;
export const TimePickerHoverBackground = ActiveLinkText;

export const TimePickerBorder = MediumBorder;
export const TimePickerHeaderBorder = TimePickerBorder;
export const TimePickerHeaderText = '#666';
export const SlicerTimePickerHeaderBackground =
  SugarColorPalette.ColorBaseWhite;

export const DropdownButtonBackgroundColor = ContentBackground;
export const ModalBackdropColor = TooltipBackgroundColor;
export const ModalFontColor = ContentText;

export const ActiveFilterTypeTextColor = DefaultButtonTextColorActive;

export const ProfileContentBackground = SugarColorPalette.ColorBaseBlack;

export const PrimaryButtonColor = SugarColorPalette.ColorBaseBlue600;
export const PrimaryButtonTextColor = SugarColorPalette.ColorBaseWhite;
export const PrimaryButtonColorDisabled = '#5AA4D8';
export const PrimaryButtonColorHover = '#0463a4';
export const PrimaryButtonColorActive = '#005087';
export const PrimaryButtonColorTextDisabled = SugarColorPalette.ColorBaseWhite;

export const SecondaryButtonColor = SugarColorPalette.ColorBaseWhite;
export const SecondaryButtonColorHover = SecondaryButtonColor;
export const SecondaryButtonColorDisabled = SugarColorPalette.ColorBaseWhite;
export const SecondaryButtonTextColor = SugarColorPalette.ColorBaseGray500;
export const SecondaryButtonTextColorHover = '#222222';
export const SecondaryButtonTextColorActive = SecondaryButtonTextColor;
export const SecondaryButtonTextColorDisabled = '#6F6F6F';
export const SecondaryButtonBorderColor = SugarColorPalette.ColorBaseGray300;
export const SecondaryButtonBorderColorHover = SugarGrayPalette.Gray50;
export const SecondaryButtonBorderColorActive = '#E5E5E5';
export const SecondaryButtonBorderColorDisabled = '#EEEEEE';

export const SuccessButtonColor = positiveColor;
export const SuccessButtonBorderColor = '#4cae4c';
export const SuccessButtonColorHover = '#449d44';
export const SuccessButtonBorderColorHover = '#398439';

export const InfoButtonColor = '#5bc0de';
export const InfoButtonBorderColor = '#46b8da';
export const InfoButtonColorHover = '#31b0d5';
export const InfoButtonBorderColorHover = '#269abc';

export const WarningButtonColor = '#f0ad4e';
export const WarningButtonBorderColor = '#eea236';
export const WarningButtonColorHover = '#ec971f';
export const WarningButtonBorderColorHover = '#d58512';

export const DangerButtonColor = '#d9534f';
export const DangerButtonBorderColor = '#d43f3a';
export const DangerButtonColorHover = '#c9302c';
export const DangerButtonBorderColorHover = '#ac2925';

export const LinkButtonTextColor = SugarColorPalette.ColorBaseBlue600;
export const LinkButtonTextColorHover = SugarColorPalette.ColorBaseBlue700;
export const LinkButtonTextColorActive = SugarColorPalette.ColorBaseBlue800;
export const LinkButtonTextColorDisabled = SugarColorPalette.ColorBaseBlue500;

export const NavIconSelected = SugarColorPalette.ColorBaseWhite;
export const PlaceholderText = SugarColorPalette.ColorBaseGray500;
export const DefaultIconColor = SugarColorPalette.ColorBaseGray400;
export const ActiveTabIndicator = SugarColorPalette.ColorBaseBlue600;
export const BaseWhite = SugarColorPalette.ColorBaseWhite;
export const ModalBackground = SugarColorPalette.ColorBaseWhite;
export const ModalBackgroundBorder = SugarColorPalette.ColorBaseWhite;
export const DropdownBorderColor = MediumBlue;
export const FilterSelectItemCheckbox = MediumBorder;
export const FilterToolBarIconStringHover = DarkBlue;
