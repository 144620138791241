import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { grey } from '@mui/material/colors';
import { CSSProperties } from '@mui/styles';

import withStyles from '@mui/styles/withStyles';

import { useDiscoverTheme, hbox } from '../../../common/emotion';
import { Dropdown, MenuItem } from '@sugar-discover/react-bootstrap-wrapper';
import PortalDropdownMenu from '../../../common/widgets/PortalDropdownMenu';
import {
  SCROLL_CONTAINER_MAX_HEIGHT,
  SLICER_MENU_MAX_HEIGHT,
  MAX_SLICER_WIDTH,
  MIN_SLICER_WIDTH,
  ISlicerWidgetThemeProp,
  useSlicerTheme,
  MOBILE_WIDTH_OFFSET,
  DASHLET_WIDTH_OFFSET,
  SELECT_ALL_ITEM_HEIGHT,
} from './common';

const noTopBorder = () => ({
  borderTopWidth: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
});

export const noBottomBorder = () => ({
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
});

export const SlicerCheckbox: typeof Checkbox = withStyles(
  ({ colors: { PrimaryColor } = {} as any }: any = {}) => ({
    root: {
      color: grey[400],
      '&$checked': {
        color: PrimaryColor,
      },
      '& input[type=checkbox]': {
        marginTop: -4,
        marginLeft: -4,
        width: 16,
        height: 16,
      },
      padding: 0,
      marginRight: 5,
      marginLeft: 10,
      width: 8,
      height: 8,
    },
    checked: {},
  }),
)(props => <Checkbox size='small' color='primary' {...props} />) as any;

export const SlicerCheckboxLabel: typeof FormControlLabel = withStyles(
  ({ colors: { baseFontColor, LightFontWeight } = {} as any }: any) => ({
    root: {
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 0,
      marginLeft: 0,
    },
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: baseFontColor,
      fontWeight: LightFontWeight,
      marginLeft: 0,
      marginRight: 0,
    },
  }),
)((props: any) => <FormControlLabel {...props} />) as any;

export const ScrollWrapper = styled.div(
  ({
    theme: { width, colors: { ContentBackground } = {} as any } = {} as any,
  }: any) =>
    css({
      flex: 1,
      width,
      backgroundColor: ContentBackground,
      maxHeight: SCROLL_CONTAINER_MAX_HEIGHT,
      overflowX: 'visible',
      overflowY: 'auto',
    }),
);

const SlicerButtonContainer = styled.div`
  button[disabled] {
    opacity: 0.3;
  }
`;

export const SlicerMenuContainer = ({ id, children }) => {
  const {
    viewHeight,
    isMobile,
    isOpen,
    colors: { ContentBackground } = {} as any,
  } = useSlicerTheme();
  return (
    <SlicerButtonContainer
      id={id}
      css={css({
        pointerEvents: 'all',
        display: isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        backgroundColor: `${ContentBackground} !important`,
        maxHeight: isMobile
          ? viewHeight
          : SLICER_MENU_MAX_HEIGHT + SELECT_ALL_ITEM_HEIGHT,
        userSelect: 'none',
      })}
    >
      {children}
    </SlicerButtonContainer>
  );
};

export const SlicerWidgetContainer = ({
  width,
  children,
  className,
  containerRef,
}) => {
  const { isDashletMode, isMobile } = useSlicerTheme();
  const {
    darkMode,
    colors: {
      DefaultButtonFillColor,
      ModalHeaderBackground,
      ContentText,
      MediumBorder,
      IconPrimaryHoverColor,
    },
  } = useDiscoverTheme();

  const mobileWidth =
    (isMobile || isDashletMode) && width <= 0 ? '100%' : `${width}px`;

  const getStyles = () => css`
    width: ${mobileWidth};
    margin-right: ${isDashletMode ? 0 : 10}px;
    margin-bottom: 10px;

    .combo-dropdown .dropdown {
      width: 100% ${isDashletMode && '!important'};
    }
    .combo-dropdown .btn-group .dropdown-toggle {
      ${hbox()}
      align-items: center;
      height: 32px;
      overflow: hidden;
    }
    .combo-dropdown .btn-group .dropdown-toggle[id^='slicer-dropdown-'] {
      min-width: 100%;
      background-color: ${ModalHeaderBackground};
      border-color: ${MediumBorder};
      color: ${darkMode && ContentText};
      .caret {
        color: ${darkMode && IconPrimaryHoverColor};
      }
      &:hover {
        background-color: ${DefaultButtonFillColor};
        color: ${ContentText};
      }
    }
    .combo-dropdown .btn-group .dropdown-toggle span.title {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
  `;

  return (
    <div ref={containerRef} css={getStyles()}>
      <div className={`combo-dropdown ${className || ''}`}>{children}</div>
    </div>
  );
};

export const SlicerTitle = styled.span(({ theme }: any) =>
  css({
    marginLeft: theme?.isMobile || theme?.isDashletMode ? 15 : 10,
    marginRight: '20px !important',
    fontWeight: theme?.colors?.MediumFontWeight,
    fontSize: 14,
  }),
);

export const MobileDropdownCaret = () => (
  <div css={css({ width: 8, marginRight: 10, marginLeft: 10, height: 12 })}>
    <span className='caret'></span>
  </div>
);

export const DropdownCaret = () => (
  <div css={css({ width: 8, marginRight: 10, marginLeft: 10, marginTop: -3 })}>
    <span className='caret'></span>
  </div>
);

export const SlicerShadowBackdrop = styled.div(
  ({
    theme: {
      width,
      slicerDropdownToggleHeight,
      colors: { SlicerShadowColor } = {} as any,
    } = {} as any,
  }: ISlicerWidgetThemeProp) =>
    css({
      position: 'absolute',
      width,
      pointerEvents: 'none',
      borderRadius: 'inherit',
      top: -slicerDropdownToggleHeight,
      height: `calc(100% + ${slicerDropdownToggleHeight}px)`,
      boxShadow: `0 2px 12px ${SlicerShadowColor}`,
    }),
);

export const SlicerSearchMenuItem = styled(MenuItem)(() =>
  css({
    height: 40,
    width: '100%',
    maxWidth: '100%',
    'a:hover': {
      textDecoration: 'none',
    },
  }),
);

export const SlicerMenuItem = styled(MenuItem)<{
  isTimestampSlicer?: boolean;
  disableSelectAll?: boolean;
}>(({ isTimestampSlicer, disableSelectAll }) => {
  const {
    mobileMaxWidth,
    isDashletMode,
    colors: { DimText },
  } = useDiscoverTheme();

  return css`
      width: 100%;
      max-width: 100%;
      padding: ${!isTimestampSlicer && '0 10px'};

      a[role='menuitem'] {
        display: block;
        max-width: 100%;
        width: 100%;
        border: ${!isTimestampSlicer && `1px solid ${DimText}`};
        border-top: ${!disableSelectAll && 'none'};
      }

      ${isDashletMode &&
        css`
          &.slicer-widget-menu-item {
            overflow: hidden;
          }
        `}

      @media (max-width: ${mobileMaxWidth}px) {
        &.slicer-widget-menu-item {
          overflow: hidden;
        }
      }
    `;
});

export const SlicerFooterMenuItem = styled(MenuItem)(
  () => css`
    a {
      cursor: default;

      :hover,
      :focus {
        text-decoration: none !important;
      }
    }
  `,
);

export const MobileBackButton = styled.button(() => {
  const {
    colors: { Gray70 },
  } = useDiscoverTheme();

  return css`
    background: none;
    border: none;
    padding: 5px 5px 5px 10px;
    display: flex;

    .sicon {
      color: ${Gray70};
    }
  `;
});

export const SlicerDropdownToggle = styled(Dropdown.Toggle)(
  ({
    theme: {
      isOpen,
      width,
      viewWidth,
      isMobile,
      isDashletMode,
      slicerDropdownToggleHeight,
      slicerToggleFontFamily,
      colors: {
        MediumFontWeight,
        Strokes,
        SlicerShadowColor,
        ContentBackground,
      } = {} as any,
    } = {} as any,
  }: ISlicerWidgetThemeProp) => {
    const borderRadius = isMobile && !isOpen ? 5 : 3;
    const fontSize = isMobile && !isOpen ? 18 : 14;
    const boxShadow =
      isMobile && !isOpen
        ? `0px 0px 3px 0px ${SlicerShadowColor}`
        : 'none !important';
    const baseStyles: CSSProperties = {
      pointerEvents: 'all',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      borderColor: isDashletMode
        ? 'transparent !important'
        : `${Strokes} !important`,
      backgroundColor: isOpen ? `${ContentBackground} !important` : undefined,
      boxShadow,
      height: slicerDropdownToggleHeight,
      fontFamily: slicerToggleFontFamily,
      width: isOpen ? width : undefined,
      borderRadius,
      display: 'flex',
      placeContent: 'stretch flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      '.title': {
        flex: '1 1 0%',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: MediumFontWeight,
        fontFamily: slicerToggleFontFamily,
        fontSize,
      },
    };
    const sizeStyles = isDashletMode
      ? {
          width: `calc(${viewWidth} - ${DASHLET_WIDTH_OFFSET}px`,
          marginLeft: 10,
          marginBottom: 5,
        }
      : isMobile
      ? {
          width: `calc(${viewWidth} - ${MOBILE_WIDTH_OFFSET}px`,
          marginLeft: 10,
          marginBottom: 5,
        }
      : {
          maxWidth: MAX_SLICER_WIDTH,
          minWidth: MIN_SLICER_WIDTH,
          flex: 'none',
        };
    const borderStyles = isOpen
      ? {
          ...noBottomBorder(),
          '&:hover': {
            borderColor: `${Strokes} !important`,
            backgroundColor: `${ContentBackground} !important`,
            ...noBottomBorder(),
          },
        }
      : {
          transition: '.3s',
          '&:hover': {},
        };
    return css({
      ...baseStyles,
      ...sizeStyles,
      ...borderStyles,
    } as any) as any;
  },
);

export const SlicerComboDropdown = styled(Dropdown)(
  ({ theme }: any) => css`
    & > .dropdown-toggle {
      height: ${theme?.isMobile || theme?.isDashletMode ? 'auto' : '32px'};
      padding-top: ${theme?.isMobile || theme?.isDashletMode
        ? '2em'
        : 'inherit'};
      padding-bottom: ${theme?.isMobile || theme?.isDashletMode
        ? '2em'
        : 'inherit'};
    }
  `,
);

export const StyledDropdownMenu = styled(PortalDropdownMenu)(() => {
  const {
    colors: { ContentBackground },
    isDashletMode = {} as any,
    isMobile,
  } = useDiscoverTheme();
  return {
    paddingTop: 0,
    paddingBottom: 0,
    ...noTopBorder(),
    boxShadow: 'none',
    backgroundColor: isDashletMode
      ? `${ContentBackground} !important`
      : 'inherit',
    '.slicer-widget-dropdown': {
      padding: isDashletMode && 0,
      minWidth: (isDashletMode || isMobile) && 'unset !important',
      maxWidth: (isDashletMode || isMobile) && 'unset !important',
    },
  };
});

export const SlicerMenuDivider = ({ width }) => {
  const { colors: { DimText } = {} as any } = useDiscoverTheme();
  return (
    <div css={css({ width })}>
      <hr
        css={css`
          color: ${DimText};
          margin-top: 10px;
          margin-bottom: 0px;
          margin-left: 8px;
          margin-right: 8px;
        `}
      />
    </div>
  );
};

export const SelectAllItem = styled.li(() => {
  const {
    darkMode,
    colors: {
      DimText,
      PivotWhite,
      PanelBackground,
      DropdownOptionHoverBackground,
    },
  } = useDiscoverTheme();

  return css`
    padding: 0 10px;
    width: 100%;
    height: ${SELECT_ALL_ITEM_HEIGHT}px;

    > div {
      background-color: ${darkMode ? PanelBackground : PivotWhite};
      &:hover {
        background-color: ${DropdownOptionHoverBackground};
      }
    }

    label {
      width: 100%;
      margin-bottom: 0;
      padding: 3px 0px 7px 0px;
      border: 1px solid ${DimText};
    }
  `;
});
