import { useCallback, useState, createContext } from 'react';
import { concat, includes, noop } from 'lodash';

export const FieldDropdownContext = createContext<{
  openSubMenu?: string;
  setOpenSubMenu?: (menuId: string) => void;
  registerSubMenu?: (menuId: string) => void;
  registeredSubMenus?: string[];
}>({
  openSubMenu: null,
  setOpenSubMenu: noop,
  registerSubMenu: noop,
  registeredSubMenus: [],
});

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const FieldDropdownContextProvider = ({ children }: Props) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [registeredSubMenus, setRegisteredSubMenus] = useState([]);

  const setOpenMenuCallback = useCallback(
    (menuId = null) => {
      setOpenSubMenu(menuId);
    },
    [setOpenSubMenu],
  );
  const registerSubMenu = useCallback(
    menuId => {
      if (includes(registeredSubMenus, menuId)) {
        return;
      }
      setRegisteredSubMenus(concat(registeredSubMenus, [menuId]));
    },
    [registeredSubMenus, setRegisteredSubMenus],
  );
  return (
    <FieldDropdownContext.Provider
      value={{
        openSubMenu,
        setOpenSubMenu: setOpenMenuCallback,
        registerSubMenu,
        registeredSubMenus,
      }}
    >
      {children}
    </FieldDropdownContext.Provider>
  );
};
