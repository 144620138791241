import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, IButtonProps } from '../../../ui';
import { useDiscoverTheme } from '../../../common/emotion';

export const SlicerOptionSelectOnlyButton = (props: IButtonProps) => {
  const {
    colors: { ContentText, TabHighlightDisabled } = {} as any,
  } = useDiscoverTheme();
  return (
    <Button
      css={css({
        fontSize: 12,
        padding: '0px 2px',
        color: `${ContentText} !important`,
        marginRight: 10,
        backgroundColor: TabHighlightDisabled,
        '&:hover': {
          backgroundColor: TabHighlightDisabled,
        },
      })}
      {...props}
    />
  );
};

export const SlicerOptionColumn = styled.div({
  padding: 0,
});

export const SlicerOptionRow = styled.div(
  ({
    width,
    theme: {
      darkMode,
      colors: {
        ContentBackground,
        DropdownOptionHoverBackground,
        PrimaryColor,
      } = {} as any,
    } = {} as any,
  }: any) =>
    css({
      width,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ContentBackground,
      '&:hover': css({
        backgroundColor: DropdownOptionHoverBackground,
      }),
      '&:hover > div > div':
        !darkMode &&
        css({
          color: PrimaryColor,
        }),
    }),
) as any;
