/*
a HOC for auto saving forms debounced

<AutoSave debounce={1000} save={saveFunction} />
this is shown during saving...
</AutoSave>
*/

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { noop, isEqual } from 'lodash';

class component extends Component {
  constructor(props) {
    super(props);
    this.state = { values: props.values, submitting: false };
  }

  componentDidUpdate(nextProps) {
    if (this.props.active && !isEqual(this.props.active, nextProps.active)) {
      // blur occurred
      this._save();
    }
  }

  _save() {
    const { values, valid, save } = this.props;
    this.setState({ submitting: true });
    return Promise.resolve()
      .then(save.bind(save, values, valid))
      .then(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return this.state.submitting && this.props.children
      ? this.props.children
      : null;
  }
}

component.propTypes = {
  save: PropTypes.func,
};

component.defaultProps = {
  save: noop,
};

export default props => (
  <FormSpy
    {...props}
    subscription={{ values: true, active: true, valid: true }}
    component={component}
  />
);
