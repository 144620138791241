import { useState, useRef, useEffect, useLayoutEffect } from 'react';

// from https://duongasaur.medium.com/animating-unmount-in-react-with-css3-transitions-15c80fab1aff
export const useAnimationTransition = toggle => {
  const didMountRef = useRef(false);
  const componentRef: any = useRef(null);
  const [safeShowHide, setSafeShowHide] = useState(false);

  useLayoutEffect(() => {
    if (didMountRef.current) {
      setSafeShowHide(true);
    } else {
      didMountRef.current = true;
    }
  }, [toggle]);

  useEffect(() => {
    componentRef.current.addEventListener('transitionend', () => {
      setSafeShowHide(false);
    });
  }, [componentRef, setSafeShowHide]);

  return [componentRef, safeShowHide];
};
