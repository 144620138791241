import { Component } from 'react';
import { css } from '@emotion/react';
import {
  AggregationContext,
  createFilterForField,
  FilterTypes,
  StringFilterSubTypes,
} from './Filter';
import { FilterCondition } from './filter-condition';
import _, { isNil } from 'lodash';
import Discover from '../../common/redux/actions/DiscoverActions';
import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'react-recompose';
import { AggregationDropdown } from '../../components/aggregation-dropdown';
import { i18nUtils, messages } from '../../i18n';
import {
  FilterDialogTypes,
  IFilter,
} from '../../datasets/interfaces/filter.interface';
import { Viz } from '../../discovery/VizUtil';
import { FieldsDropDown, getFieldsFromViz } from './FieldsDropDown';
import { makeDropdownOption } from '../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';
import { getAttribType } from './utils';

interface IPropTypes {
  selectedField: any;
  selectedAggregation: any;
  filter: IFilter;
  changeFilter: (filter: IFilter) => void;
  viz: any;
  field: any;
  isAggregateFilter: boolean;
}

class TopBottomCondition extends Component<IPropTypes, {}> {
  onConditionChange(condition) {
    const aggContext = new AggregationContext(
      this.props.selectedField?.name,
      this.props.selectedAggregation,
    );
    this._changeFilter(condition, aggContext);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.filter.subType === StringFilterSubTypes.TOP_BOTTOM;
  }

  onAggregationChange(aggregation) {
    const aggContext = new AggregationContext(
      this.props.selectedField?.name,
      aggregation.name,
    );
    this._changeFilter(this.props.filter.expression.left, aggContext);
  }

  onAggFieldChange(field) {
    const aggContext = new AggregationContext(
      field.name,
      field.defaultAggregation,
    );
    this._changeFilter(this.props.filter.expression.left, aggContext);
  }

  _changeFilter(condition, aggContext) {
    const f = { ...this.props.filter };
    f.expression.left = condition;
    if (!_.isUndefined(aggContext)) {
      f.aggregationContext = aggContext;
    }
    this.props.changeFilter(f);
  }

  render() {
    const { viz, filter, field } = this.props;
    const { left } = filter.expression;
    const { selectedField, selectedAggregation } = this.props;
    return (
      <div
        css={css`
          display: inline-flex;
          gap: 1rem;
          align-items: center;

          input[type='checkbox'],
          label,
          .form-control {
            margin: 0 0 0 0;
          }

          & > * {
            vertical-align: middle;
            flex-shrink: 1;
            max-width: 15rem;
            max-height: 28px;
          }
        `}
      >
        <div>{messages.filters.show}</div>
        <div className='condition'>
          <FilterCondition
            id='topBottomCondition'
            onChange={condition => this.onConditionChange(condition)}
            condition={{ ...left }}
            filterType={FilterTypes.STRING}
            filterSubType={StringFilterSubTypes.TOP_BOTTOM}
          />
        </div>
        <div>{messages.filters.itemsBy}</div>
        <FieldsDropDown
          viz={viz}
          field={field}
          selected={selectedField}
          onSelect={newSelectedField => {
            this.onAggFieldChange(newSelectedField);
          }}
          dropDownPrefix={'top-bottom'}
        />
        <div>
          {!isNil(selectedField) && !selectedField?.aggregateMeasure && (
            <AggregationDropdown
              headerSx={{ minWidth: '10rem' }}
              dataType={getAttribType(selectedField)}
              defaultValue={makeDropdownOption(
                selectedAggregation ?? selectedField.defaultAggregation,
                i18nUtils.getAggregationDisplayText(
                  selectedAggregation ?? selectedField.defaultAggregation,
                ),
              )}
              onSelect={agg => {
                this.onAggregationChange(agg);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const createTopBottomFilter = field => {
  const filter = createFilterForField(field);
  filter.subType = StringFilterSubTypes.TOP_BOTTOM;
  return filter;
};

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  let { filter } = ownProps;
  const aggContext = filter.aggregationContext;
  const allFields = Viz.getAllAvailableFields(discovery.viz);
  let selectedField = _.isNil(aggContext)
    ? null
    : _.find(allFields, { name: aggContext.field });
  let selectedAggregation = _.isNil(aggContext) ? null : aggContext.aggregation;
  if (_.isNil(selectedField)) {
    // try to determine which one we should select first
    const fields = getFieldsFromViz(discovery.viz, ownProps.field, false);
    selectedField = _.isEmpty(fields.fieldsInPlay)
      ? fields.fieldsNotInPlay[0]
      : fields.fieldsInPlay[0];
  }
  if (_.isNil(selectedAggregation)) {
    selectedAggregation = selectedField?.defaultAggregation;
  }

  const emptyFilter = createTopBottomFilter(ownProps.field);
  if (_.isNil(filter)) {
    filter = emptyFilter;
  }
  return {
    datasetId: discovery.dataset.id,
    filter: { ...filter },
    viz: discovery.viz,
    selectedField,
    selectedAggregation,
    isAggregateFilter:
      state?.discover?.showFieldFilterDialog === FilterDialogTypes.AGGREGATE,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default compose<IPropTypes, any>(
  branch((props: any) => {
    return (
      _.isNil(props.filter) ||
      props.filter.subType !== StringFilterSubTypes.TOP_BOTTOM
    );
  }, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
)(TopBottomCondition);
