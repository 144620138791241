import { css } from '@emotion/react';
import { textfieldFormControlStyles } from '../../components/FormUtils/TextfieldFormControl/textfield-form-control.styles';
import { calcDialogStyles } from '../../discovery/calc/CalcDialog/calc-dialog.styles';
import { datasetPreviewTableStyles } from '../../views/DatasetPreviewTable/dataset-preview-table.styles';
import { uiTableStyles } from '../../components/UITable/ui-table.styles';
import { iconDropdownStyles } from '../../components/./icon-dropdown/icon-dropdown.styles';
import { slideInOutStyles } from '../../components/SlideInOut/slide-in-out.styles';
import { multipleEmailInputStyles } from '../../components/MultipleEmailInput/multiple-email-input.styles';
import { settingsProfileStyles } from '../../account/settings-profile/settings-profile.styles';
import { accountSettingsStyles } from '../../account/account-settings/account-settings.styles';
import { mobileAccountSettingsStyles } from '../../account/mobile-account-settings/mobile-account-settings.styles';
import { libraryStyles } from '../../views/Library/library.styles';
import { mobileLibraryStyles } from '../../views/MobileLibrary/mobile-library.styles';
import { pivotConditionalFormattingStyles } from '../../components/PivotConditionalFormatting/pivot-conditional-formatting.styles';
import { reportDetailStyles } from '../../views/ReportDetail/report-detail.styles';
import { cellMenuStyles } from '../../components/CellMenu/cell-menu.styles';
import { commonInsightStyles } from '../../components/Insights/Common/common.styles';
import { top3Styles } from '../../components/Insights/Top3/top-3.styles';
import { biggestMoversStyles } from '../../components/Insights/BiggestMovers/biggest-movers.styles';
import { errorStyles } from '../../components/Insights/Error/error.styles';
import { libraryDashletStyles } from '../../sugar-dashlet/library-dashlet/library-dashlet.styles';
import { headerNavigationStyles } from '../../components/HeaderNavigation/header-navigation.styles';
import { mobileInsightsStyles } from '../../insights/mobile-insights/mobile-insights.styles';
import { administrationStyles } from '../../views/Administration/administration.styles';
import { mobileVizFooterStyles } from '../../components/MobileVizFooter/mobile-viz-footer.styles';
import { mobileMenuButtonStyles } from '../../components/MobileMenuButton/mobile-menu-button.styles';
import { anomalyActivityStyles } from '../../components/AnomalyActivity/anomaly-activity.styles';
import { slackNotificationConfigStyles } from '../../components/SlackNotificationConfig/slack-notification-config.styles';
import { settingsAccountStyles } from '../../account/settings-account/settings-account.styles';
import { settingsNotificationsStyles } from '../../account/settings-notifications/settings-notifications.styles';
import { IDiscoverEmotionTheme } from './theme';

export const componentStyles = ({
  mobileMaxWidth,
  theme,
}: {
  mobileMaxWidth;
  theme: IDiscoverEmotionTheme;
}) => css`
  ${slackNotificationConfigStyles({ theme })}
  ${textfieldFormControlStyles}
  ${calcDialogStyles({ theme })}
  ${datasetPreviewTableStyles({ theme })}
  ${uiTableStyles({ theme })}
  ${iconDropdownStyles({ theme })}
  ${slideInOutStyles}
  ${multipleEmailInputStyles({ theme })}
  ${settingsProfileStyles({ theme })}
  ${accountSettingsStyles({ theme })}
  ${libraryStyles({ theme })}
  ${mobileLibraryStyles({ mobileMaxWidth })}
  ${pivotConditionalFormattingStyles({ theme })}
  ${reportDetailStyles({ theme })}
  ${cellMenuStyles({ theme })}
  ${commonInsightStyles({ theme })}
  ${top3Styles}
  ${biggestMoversStyles({ theme })}
  ${errorStyles}
  ${libraryDashletStyles}
  ${headerNavigationStyles}
  ${mobileInsightsStyles({ theme })}
  ${administrationStyles}
  ${mobileVizFooterStyles}
  ${mobileAccountSettingsStyles({ theme })}
  ${settingsAccountStyles}
  ${mobileMenuButtonStyles({ theme })}
  ${settingsNotificationsStyles({ theme })}
  ${anomalyActivityStyles}
`;
