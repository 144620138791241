import { useCallback, useState } from 'react';
import _ from 'lodash';

export const useSlicerMobileBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const pushBreadcrumb = useCallback(
    breadcrumb => {
      if (_.last(breadcrumbs) !== breadcrumb) {
        setBreadcrumbs(_.concat(breadcrumbs, [breadcrumb]));
      }
    },
    [breadcrumbs, setBreadcrumbs],
  );
  const popBreadcrumb = useCallback(
    breadcrumb => {
      if (_.last(breadcrumbs) === breadcrumb) {
        setBreadcrumbs(_.dropRight(breadcrumbs, 1));
      }
    },
    [breadcrumbs, setBreadcrumbs],
  );

  return {
    breadcrumbs,
    pushBreadcrumb,
    popBreadcrumb,
  };
};
