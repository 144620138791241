import {
  find,
  head,
  isFunction,
  isNil,
  filter,
  pick,
  some,
  chain,
  uniqBy,
} from 'lodash';
import { IViz } from '../interfaces';
import { IAnyAttribute } from '../../datasets';
import { useOpenVizSelector } from '../../common/redux/selectors/viz-selector.hook';
import styled from '@emotion/styled';
import {
  ISearchableDropdownOption,
  SearchableDropdown,
} from '../../ui/dropdowns/searchable-dropdown';
import { makeDropdownOption } from '../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';
import { Tooltip } from '../../components/ui/tooltip';
import { ellipsis } from '../../common/emotion/mixins';

const StyledOverflowSpan = styled.span`
  ${ellipsis()};
`;

const StyledSearchableDropdown = styled(SearchableDropdown)`
  min-width: 10rem;
`;

interface IProps {
  viz?: IViz;
  field?: IAnyAttribute;
  selected?: any;
  onSelect: any;
  useAllFields?: boolean;
  classNames?: string[];
  dropDownPrefix: string;
}

export const FieldsDropDown = ({
  field,
  useAllFields,
  onSelect,
  dropDownPrefix,
  classNames,
  selected,
}: IProps) => {
  const viz = useOpenVizSelector();
  const fieldsInViz = getFieldsFromViz(viz, field, useAllFields);
  let fields = [...fieldsInViz.fieldsInPlay];
  const onFieldChange = fieldName => {
    const field = find(fields, { name: fieldName });

    if (isFunction(onSelect)) {
      onSelect(field);
    }
  };

  fields = uniqBy([...fields, ...fieldsInViz.fieldsNotInPlay], 'name');
  const fieldMenuOptions = fields
    .filter(f => !f.hidden)
    .map(({ name: _name }) => makeDropdownOption(_name));
  return (
    <StyledSearchableDropdown
      id={`${dropDownPrefix}-fields-dropdown`}
      className={classNames?.join(' ')}
      onSelect={(options: ISearchableDropdownOption[]) => {
        const option = head(options);
        if (option) {
          onFieldChange(option?.value);
        }
      }}
      title={
        <Tooltip
          placement={'top'}
          id='verbose-filter-tooltip'
          title={isNil(selected) ? fields[0]?.name : selected.name}
          arrow
        >
          <StyledOverflowSpan className={'dropdown-title'}>
            {isNil(selected) ? fields[0]?.name : selected.name}
          </StyledOverflowSpan>
        </Tooltip>
      }
      options={fieldMenuOptions}
    />
  );
};

export const getFieldsFromViz = (viz, removeField, useAllFields) => {
  if (useAllFields) {
    return {
      fieldsInPlay: filter(
        viz.dataset?.attributes,
        f => f.name !== removeField?.name && !f.hidden,
      ),
      fieldsNotInPlay: [],
    };
  }
  const fieldsInPlay = chain(viz.layout)
    .values()
    .flatten()
    .reject(pick(removeField, 'name'))
    .value();

  const fieldOptions = viz.dataset.attributes
    .filter(f => {
      return f.name !== removeField.name;
    })
    .filter(f => {
      return some(fieldsInPlay, pick(f, 'name'));
    });
  return {
    fieldsInPlay,
    fieldsNotInPlay: fieldOptions,
  };
};
