import { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import {
  branch,
  compose,
  renderComponent,
  withHandlers,
  withState,
} from 'react-recompose';
import { DropTarget } from 'react-dnd';
import _, { get } from 'lodash';
import { client } from '../../common/ApolloClient';
import { CreateVizOptions, DiscoverQueries } from '../../common/graphql/index';
import { withSkeletonLoadingPanel } from '../../common/hoc/index';
import Discover from '../../common/redux/actions/DiscoverActions';
import { DnDTypes, VIZ, Types, setDynamicValues } from '../../common/Constants';
import URLs from '../../common/Urls';
import {
  getConfigPanelDetail,
  VIZ_SELECTORS,
} from '../../common/redux/selectors/viz-selectors';
import { MobileVizFooter } from '../../components/MobileVizFooter';
import VizSaveDialog from '../../views/VizSaveDialog';
import { VizChart } from '../viz-chart/viz-chart.component';
import DetailPanel from '../DetailPanel';
import { DiscoveryToolbar } from '../discovery-toolbar';
import VizConfigPanel from '../VizConfigPanel';
import { VizLegend } from '../charts/viz-legend';
import { ActiveFilterPanel } from '../filter/active-filter-panel';
import { FilterDialog } from '../filter/filter-dialog/filter-dialog.component';
import RemoveFieldFromVizDialog from '../RemoveFieldFromVizDialog';
import { Viz as VizUtil } from '../VizUtil';
import { QueryErrorDialog } from '../query-error';
import AddFieldToVizWarningDialog from '../AddFieldToVizWarningDialog';
import VizChartError from '../VizChartError';
import QueryWarningDialog from '../QueryWarningDialog';
import { messages } from '../../i18n';
import { SlicerPanel } from '../slicer';
import { VizPanel } from '../viz-panel';
import { UpdateVizOptions } from '../../common/graphql/util';
import { withDiscoverRouter } from '../../common/utilities/router.hoc';
import { withDiscoverOption } from '../discovery-context/discovery.context';
import {
  IDispatchToProps,
  IProps,
  IState,
  IStateToProps,
} from './viz.interfaces';
import { FilterDialogContextProvider } from '../filter/filter-dialog/filter-dialog.context';
import { IRevision, IViz } from '../interfaces';
import { SaveVersionDialog } from '../../views/save-version-dialog';
import { ErrorBoundary } from 'react-error-boundary';
import { LogErrorBoundary } from '../../components/error-boundary';
import { ADD_VISUALIZATION_QUERY_NAME } from '../../common/graphql/Fragments';

export class UnconnectedVizComponent extends Component<IProps, IState> {
  props;
  state;

  constructor(props) {
    super(props);

    const { state: routeLocationState } = props.location;

    this.state = {
      fiscalCalendarNeedsUpdate: this.needsFiscalCalendarUpdate(
        props.useFiscalCalendar,
        routeLocationState?.useFiscalCalendar,
      ),
    };
  }

  needsFiscalCalendarUpdate(currentFiscalCalendar, locationFiscalCalendar) {
    return (
      !_.isNil(locationFiscalCalendar) &&
      currentFiscalCalendar !== locationFiscalCalendar
    );
  }

  componentDidUpdate(prevProps) {
    const { state: routeLocationState } = this.props.location as any;
    if (
      this.props.dropTargetId &&
      this.props.dropTargetId !== prevProps.dropTargetId
    ) {
      /*
       keep the app state in sync with what viz trash drop target is active
       so we can rely on it in the FieldPillDragPreview to find out if it is currently over it
      */
      prevProps.setVizTrashDropTarget(this.props.dropTargetId);
    }
    if (this.props.requiresAutoSave) {
      this.props.update();
    }
    // Perform dirty checks
    if (!_.isEqual(prevProps.rawDiscovery, this.props.rawDiscovery)) {
      const { rawDiscovery, discovery } = this.props;
      const { discovery: prevDiscovery } = prevProps;
      let dirty = false;
      // Use undo/redo stack as a more reliable way to determine dirty state
      if (!_.isNil(rawDiscovery.saveCheckpoint)) {
        dirty = rawDiscovery.saveCheckpoint !== rawDiscovery.past.length - 1;
      } else {
        dirty = rawDiscovery.past.length > 0;
      }
      if (dirty && !rawDiscovery.present.dirty) {
        // Update present discovery with captured dirty state
        this.props.setVizDirty(discovery.id, dirty);
      }
      if (VizUtil.shouldClearQuery(discovery, prevDiscovery)) {
        this.props.clearQueryResults(discovery.id);
      }
    }
    if (
      this.state.fiscalCalendarNeedsUpdate &&
      !this.needsFiscalCalendarUpdate(
        this.props.useFiscalCalendar,
        routeLocationState?.useFiscalCalendar,
      )
    ) {
      this.setState({
        fiscalCalendarNeedsUpdate: false,
      });
    }
  }

  componentDidMount() {
    // Get location state from react router
    const { state }: { state: any } = this.props.location;
    if (!_.isEmpty(state?.dynamicValues)) {
      setDynamicValues(state?.dynamicValues);
    }

    if (
      state &&
      (!_.isNil(state.filters) ||
        !_.isNil(state.removeFiltersOnFields) ||
        !_.isNil(state.calcs) ||
        !_.isNil(state.metrics) ||
        !_.isNil(state.toShelves) ||
        !_.isNil(state.slicers))
    ) {
      // Apply any selected viz state
      this.props.applyVizState({
        filters: state.filters,
        prependFilters: true,
        removeFiltersOnFields: state.removeFiltersOnFields,
        calcs: state.calcs,
        metrics: state.metrics,
        toShelves: state.toShelves,
        slicers: state.slicers,
        slicerSelections: state.slicerSelections,
      });
    } else {
      this.props.setRouterApplyingState(false);
    }
    if (state && !_.isNil(state.monitorEventId)) {
      // Apply the selected monitor event for display prioritization
      this.props.applyMonitorEventId(state.monitorEventId);
    }
    if (this.state.fiscalCalendarNeedsUpdate) {
      this.props.setUsingFiscalCalendar(
        state?.useFiscalCalendar,
        this.props?.discovery?.id,
      );
    }
  }

  onSaveVersion() {
    this.props.setShowVersionDialog(true);
  }

  onSaveIconClick(saveAs = false) {
    this.props.setSaveActive(true);
    if (saveAs) {
      this.props.setShowSaveAsDialog(true);
    } else if (_.isUndefined(this.props.viz.id)) {
      this.props.setShowSaveDialog(true);
    } else {
      this.props.update();
    }
  }

  render() {
    const saveAsProps = {
      showSaveDialog: this.props.showSaveAsDialog,
      discovery: { ...this.props.discovery, dirty: false },
      viz: { ...this.props.viz },
      saveActive: this.props.saveActive,
      saveError: this.props.saveError,
      setSaveActive: this.props.setSaveActive,
      setShowSaveDialog: this.props.setShowSaveAsDialog,
      saveNew: ({ name: newName, optionalViz }) =>
        this.props.saveAs(newName, optionalViz),
      update: _.noop,
      changeDiscoveryName: _.noop,
      changeDiscoveryTags: _.noop,
      closeViz: _.noop,
      cancelDiscoverySave: () => this.props.cancelDiscoverySaveAs(),
    };

    // VizSaveDialog should eventually use ModalPortal
    const saveDialogProps = _.pick(this.props, [
      'saveNew',
      'changeDiscoveryName',
      'closeViz',
      'saveError',
      'discovery',
      'viz',
      'saveActive',
      'cancelDiscoverySave',
      'changeDiscoveryName',
      'isReadOnlyUser',
      'clearSaveError',
      'update',
    ]);

    const showSaveDialog =
      this.props.showSaveDialog || this.props.showSaveAsDialog;

    const { showVersionDialog } = this.props;

    return this.props.connectDropTarget(
      <div className={'viz-container'}>
        <DiscoveryToolbar
          onSave={() => {
            this.onSaveIconClick();
          }}
          onSaveVersion={() => this.onSaveVersion()}
          onSaveAs={() => this.onSaveIconClick(true)}
          exportEnabled={
            !this.props.hasVizQueryError && this.props.hasVizQueryResults
          }
        />
        <div className={'viz-detail'}>
          <div
            className={`left-panel ${
              _.isEmpty(this.props.configPanelDetail) ? 'exit' : 'enter'
            }`}
          >
            <VizConfigPanel
              vizId={this.props.vizId}
              key={`vizConfigPanel-${this.props.vizId}`}
            />
          </div>

          {this.props.isMobile && <VizLegend vizId={this.props.vizId} />}
          {this.props.isMobile && this.props.showFiltersPanel && (
            <ActiveFilterPanel
              discoveryId={this.props.vizId}
              viz={this.props.viz}
              useFiscalCalendar={this.props.useFiscalCalendar}
            />
          )}

          <div className={'content'}>
            <div className='viz-chart-top-panel'>
              <hr />
              {!this.props.isMobile && <SlicerPanel />}
              {this.props.showFilterDialog && (
                <FilterDialogContextProvider
                  discoveryId={this.props.discovery.id}
                >
                  <FilterDialog vizId={this.props.discovery.id} />
                </FilterDialogContextProvider>
              )}
            </div>
            <div className={'viz-chart'}>
              {!this.props.routerApplyingState && (
                <ErrorBoundary
                  key={`vizErrorBoundary-${this.props.vizId}`}
                  fallbackRender={VizChartError}
                  onError={LogErrorBoundary}
                >
                  <VizChart
                    key={`vizChart-${this.props.vizId}`}
                    vizId={this.props.vizId}
                  />
                </ErrorBoundary>
              )}
            </div>
          </div>

          {!this.props.isMobile && (
            <VizPanel
              vizId={this.props.vizId}
              isMobile={this.props.isMobile}
              initialPanelWidth={_.get(
                this.props,
                'viz.options.legendPanelWidth',
                VIZ.LEGEND_PANEL_WIDTH,
              )}
              useFiscalCalendar={this.props.useFiscalCalendar}
            />
          )}

          {!this.props.isMobile && (
            <div className='right-panel'>
              <DetailPanel
                key={`vizDetailPanel-${this.props.vizId}`}
                vizId={this.props.vizId}
              />
            </div>
          )}
          {this.props.isMobile && (
            <MobileVizFooter
              onOpenInsights={this.props.openMobileInsights}
              vizId={this.props.vizId}
              showInsightsBubble={
                this.props.hasInsights && !this.props.insightsChecked
              }
              insightsLoading={this.props.insightsLoading}
              hasInsights={this.props.hasInsights}
            />
          )}
        </div>

        {showSaveDialog && (
          <VizSaveDialog
            key={`save-dialog-${this.props.vizId}`}
            {...(this.props.showSaveDialog ? saveDialogProps : saveAsProps)}
            isSaveAs={!!this.props.showSaveAsDialog}
          />
        )}

        {showVersionDialog && (
          <SaveVersionDialog
            reportName={this.props.discovery.name}
            vizId={this.props.vizId}
            onSave={this.props.update}
            onCancel={() => this.props.setShowVersionDialog(false)}
          />
        )}

        <RemoveFieldFromVizDialog />
        <AddFieldToVizWarningDialog />
        <QueryWarningDialog vizId={this.props.vizId} />
        <QueryErrorDialog vizId={this.props.vizId} />
      </div>,
    );
  }
}

const mapStateToProps = (state, props): IStateToProps => {
  const openDiscovery = state.discover.openDiscoveries[props.vizId];
  if (!openDiscovery) {
    return {
      error: messages.viz.discoveryNotFound,
    };
  }
  const discovery = openDiscovery.present;
  let missingFieldsInfo = {} as any;

  if (!_.isNil(discovery.viz)) {
    missingFieldsInfo = VizUtil.findMissingFields(discovery.viz);
    // favor missing field info that comes in on the viz
    const missingFields = !_.isEmpty(discovery.viz.missingFields)
      ? discovery.viz.missingFields
      : missingFieldsInfo.missingFields;
    const missingDependantFields = !_.isEmpty(
      discovery.viz.missingDependantFields,
    )
      ? discovery.viz.missingDependantFields
      : missingFieldsInfo.missingDependantFields;
    const goodFields = !_.isEmpty(discovery.viz.goodFields)
      ? discovery.viz.goodFields
      : missingFieldsInfo.goodFields;
    const missingFilters = !_.isEmpty(discovery.viz.missingFilters)
      ? discovery.viz.missingFilters
      : missingFieldsInfo.missingFilters;

    discovery.viz = {
      ...discovery.viz,
      missingFields,
      missingDependantFields,
      goodFields,
      missingFilters,
    };
  } else {
    discovery.viz = {};
  }

  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, props) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, props) === 'true';

  return {
    loading: discovery.loading,
    error: discovery.error,
    rawDiscovery: openDiscovery,
    discovery,
    configPanelDetail: getConfigPanelDetail(state, {
      discoveryId: discovery.id,
    }),
    viz: discovery.viz,
    saveActive: state.discover.saveActive,
    saveError: state.discover.saveError,
    showSaveDialog: state.discover.showSaveDialog,
    showVersionDialog: state.discover.showVersionDialog,
    showSaveAsDialog: state.discover.showSaveAsDialog,
    showFilterDialog: state.discover.showFieldFilterDialog,
    isMobile: state.main.isMobile,
    interactivePanelDetail: _.get(discovery, 'viz.options.panelDetail', null),
    hasInsights: discovery.hasInsights,
    hasVizQueryError: !!discovery.vizQueryError,
    hasVizQueryResults: !!discovery.vizQueryResults,
    insightsChecked: discovery.mobileInsightsChecked,
    insightsLoading: discovery.insightsLoading,
    useFiscalCalendar,
  };
};

const mapDispatchToProps = (dispatch, ownProps): IDispatchToProps => {
  return {
    setSaveActive: value => {
      dispatch(Discover.setSaveActive(value));
    },
    setSaveError: error => {
      dispatch(Discover.setSaveError(error));
    },
    clearSaveError: () => {
      dispatch(Discover.setSaveError(undefined));
    },
    openMobileInsights: () => {
      dispatch((Discover.mobileInsightsChecked as any)(ownProps.vizId));
      URLs.goTo(`/insights/${ownProps.vizId}`);
    },
    setDisplayDiscovery: id => {
      dispatch(Discover.setDisplayDiscovery(id));
    },
    setShowSaveDialog: value => {
      dispatch(Discover.setShowSaveDialog(value));
    },
    setShowVersionDialog: value => {
      dispatch(Discover.setShowVersionDialog(value));
    },
    setShowSaveAsDialog: value => {
      dispatch(Discover.setShowSaveAsDialog(value));
    },
    setVizDirty: (id, dirty) => {
      dispatch(Discover.setDiscoveryDirty(id, dirty));
    },
    setSaveCheckpoint: (id, updatedOn, revisions) => {
      dispatch(Discover.setDiscoverySaveCheckpoint(id, updatedOn, revisions));
      dispatch(Discover.setDiscoveryDirty(id, false));
    },
    closeVizDialog: () => {
      dispatch(Discover.setShowSaveDialog(false));
      dispatch(Discover.setShowSaveAsDialog(false));
      dispatch(Discover.setShowVersionDialog(false));
      dispatch(Discover.setSaveActive(false));
    },
    setVizIdAfterSave: (id, creatorName, creator) => {
      dispatch(
        Discover.updateVizWithId(ownProps.vizId, id, creatorName, creator),
      );
    },
    clearQueryResults: id => {
      dispatch(Discover.clearQueryResults(id));
    },
    closeViz: id => {
      dispatch(Discover.setDiscoveryDirty(id, false));
      dispatch(Discover.setShowSaveDialog(false));
      const force = true;
      dispatch(Discover.closeDiscovery(id), { force });
    },
    _removeField: (id, field, shelf, layout) => {
      const priorPeriodRefs = VizUtil.fieldIsReferencedByPriorPeriodCalc(
        layout,
        field,
      );
      if (
        field.attributeType === Types.PRIOR_PERIOD_CALC ||
        !_.isEmpty(priorPeriodRefs)
      ) {
        // need to prompt the user first
        dispatch(Discover.showConfirmRemoveField(id, field, shelf));
      } else {
        dispatch(Discover.removeFieldFromVisualization(id, field, shelf));
      }
    },
    setVizTrashDropTarget: targetId => {
      dispatch(Discover.setVizTrashDropTarget(targetId));
    },
    changeDiscoveryName: (id, name) => {
      dispatch(Discover.changeDiscoveryName(id, name));
    },
    changeDiscoveryTags: (id, tags) => {
      dispatch(Discover.setTagsForViz(id, tags));
    },
    copyVizState: (fromId, toId, toName) => {
      dispatch(Discover.copyVizState(fromId, toId, toName));
    },
    copyUndoStack: (fromId, toId, toName) => {
      dispatch(Discover.copyUndoStack(fromId, toId, toName));
    },
    applyVizState: state => {
      dispatch(Discover.applyVizState({ id: ownProps.vizId, ...state }));
      ownProps.setRouterApplyingState(false);
    },
    applyMonitorEventId: monitorEventId => {
      dispatch(Discover.applyMonitorEventId(ownProps.vizId, monitorEventId));
    },
    setPanelDetail: panel => {
      dispatch(Discover.setPanelDetail(ownProps.vizId, panel));
    },
    cancelDiscoverySave: () => {
      dispatch(Discover.setSaveError(undefined));
      dispatch(Discover.cancelDiscoverySave());
    },
    cancelDiscoverySaveAs: () => {
      dispatch(Discover.cancelDiscoverySaveAs());
    },
    setUsingFiscalCalendar: (useFiscalCalendar, id?) => {
      if (!_.isNil(id)) {
        dispatch(
          Discover.setSettingForViz({
            id,
            setting: 'useFiscalCalendar',
            value: useFiscalCalendar,
          }),
        );
      }
    },
    updateVizSetting: (id, key, value) => {
      dispatch(Discover.updateVizSetting(id, key, value));
    },
  };
};
const handlers = {
  updateVizForSave: props => optionalViz => {
    const { isPrivate } = props.viz;
    let viz = _.isNil(optionalViz) ? props.viz : optionalViz;
    viz = VizUtil.updateVizForSave(
      viz,
      props.discovery.id,
      props.discovery.name,
      props.discovery.tags,
    );
    return { ...viz, private: isPrivate };
  },
  // here we have access to the props set in mapStateToProps where we don't in mapDispatchToProps
  removeField: props => (id, field, shelf, layout) => {
    props._removeField(id, field, shelf, layout, props.viz.chartType);
  },
};

const handleSaveError = (error, ownProps) => {
  ownProps.setSaveError(error.message);
  if (!ownProps.showSaveDialog && !ownProps.showSaveAsDialog) {
    ownProps.setSaveActive(true);
    ownProps.setShowSaveDialog(true);
  }
};

const SaveVizMutation = graphql(DiscoverQueries.CreateVisualizationMutation, {
  options: CreateVizOptions,
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: Partial<IProps>;
    mutate: any;
  }): any => ({
    saveNew({ name, optionalViz, closeAfterSave = false }) {
      ownProps.clearSaveError();
      const viz = ownProps.updateVizForSave(optionalViz);
      viz.name = name;
      mutate({
        variables: {
          viz,
        },
      })
        .then(
          ({
            data,
          }: {
            data: {
              addVisualization: Partial<IViz>;
            };
          }) => {
            if (closeAfterSave) {
              ownProps.closeViz(ownProps.discovery.id);
              return;
            }

            // viz save dialog can update description
            ownProps.updateVizSetting(
              ownProps.discovery.id,
              'description',
              optionalViz?.options?.description,
            );

            // Visualization successfully saved, remove dirty flag and close dialog if open
            ownProps.setSaveCheckpoint(
              ownProps.discovery.id,
              data[ADD_VISUALIZATION_QUERY_NAME].updatedOn,
              data[ADD_VISUALIZATION_QUERY_NAME].revisions,
            );

            ownProps.setVizIdAfterSave(
              data[ADD_VISUALIZATION_QUERY_NAME].id,
              data[ADD_VISUALIZATION_QUERY_NAME].creatorName,
              data[ADD_VISUALIZATION_QUERY_NAME].creator,
            );
            ownProps.setDisplayDiscovery(data[ADD_VISUALIZATION_QUERY_NAME].id);
            ownProps.closeVizDialog();
          },
        )
        .catch(error => {
          handleSaveError(error, ownProps);
        });
    },
    saveAs(newName, optionalViz) {
      ownProps.clearSaveError();
      const apolloClient = client;
      const viz = ownProps.updateVizForSave(optionalViz);
      const copiedFromId = viz.id;
      viz.name = newName;
      viz.id = undefined;
      mutate({
        variables: {
          viz,
        },
      })
        .then(({ data }) => {
          const savedId = get(data, `${ADD_VISUALIZATION_QUERY_NAME}.id`);
          const savedName = newName;

          // Force the reload of the discovery query. the renderOpen method that handle the '/open' route needs that cache to be fresh.
          // However, The update option of the mutation runs before the refetchQueries finish. So, we have to resort to this...
          apolloClient
            .query({
              query: DiscoverQueries.DiscoveriesQuery,
              fetchPolicy: 'network-only',
            })
            .then(() => {
              ownProps.closeVizDialog();

              URLs.goTo(`/open/${savedId}`);
              // to make sure the newly created discovery is opened before we copy the state over, we pop that request out of the current execution stack
              setTimeout(() => {
                ownProps.copyVizState(copiedFromId, savedId, savedName);
              }, 1);

              // To preserve the undo/redo stack, the viz has to load as normal (wiping forcing undo/redo empty) before we try to copy over the undo/redo.
              // done too soon and it will get wiped out.
              setTimeout(() => {
                ownProps.copyUndoStack(copiedFromId, savedId, savedName);
              }, 300);

              // NOTE: we copy the view state of the viz first, with minimal wait to avoid screen flicker, but that is too soon for the undo/redo
            })
            .catch(() => {
              console.warn(`Save as failed for ${newName}`);
            });
        })
        .catch(error => {
          handleSaveError(error, ownProps);
        });
    },
  }),
});

const UpdateVizMutation = graphql(DiscoverQueries.UpdateVisualizationMutation, {
  options: UpdateVizOptions,
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: Partial<IProps>;
    mutate: any;
  }): any => ({
    update({
      optionalViz,
      closeAfterSave,
    }: { optionalViz?: IViz; closeAfterSave?: boolean } = {}) {
      ownProps.clearSaveError();
      const viz = ownProps.updateVizForSave(optionalViz);
      if (!_.isEmpty(optionalViz?.name)) {
        viz.name = optionalViz.name;
      }
      mutate({
        variables: {
          viz,
        },
      })
        .then(
          ({
            data,
          }: {
            data: {
              updateVisualization: {
                updatedOn: string;
                revisions: IRevision[];
              };
            };
          }) => {
            if (closeAfterSave) {
              ownProps.closeViz(ownProps.discovery.id);
              return;
            }

            // Visualization successfully saved, remove dirty flag and close dialog if open
            ownProps.setSaveCheckpoint(
              ownProps.discovery.id,
              data.updateVisualization.updatedOn,
              data.updateVisualization.revisions,
            );
            ownProps.closeVizDialog();
          },
        )
        .catch(error => {
          handleSaveError(error, ownProps);
        });
    },
  }),
});

const trashTarget = {
  drop(props, monitor) {
    // don't perform the drop if an inner drop zone already handled it
    if (monitor.didDrop()) {
      console.log('skipping drop in trash');
    } else {
      const { field } = monitor.getItem();
      const { shelf } = monitor.getItem();
      if (!_.isNil(field) && !_.isNil(shelf)) {
        props.removeField(
          props.discovery.id,
          field,
          shelf.id,
          props.viz.layout,
        );
      }
    }
  },
};

const trashCollect = (connectComponent: { dropTarget: any }, monitor) => {
  return {
    connectDropTarget: connectComponent.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    dropTargetId: monitor.targetId,
  };
};

export const VizComponent = compose<any, any>(
  withDiscoverRouter,
  withDiscoverOption({ option: 'showFiltersPanel' }),
  withState('routerApplyingState', 'setRouterApplyingState', true),
  (connect as any)(mapStateToProps, mapDispatchToProps),
  (withState as any)('requiresAutoSave', 'setRequiresAutoSave', false),
  (withHandlers as any)(handlers),
  withSkeletonLoadingPanel(),
  SaveVizMutation,
  UpdateVizMutation,
  (branch as any)(
    (props: any) => props.error,
    renderComponent((props: any) => <div>Error: {props.error}</div>),
  ),
  DropTarget(DnDTypes.VIZ_FIELD, trashTarget, trashCollect),
)(UnconnectedVizComponent as any);
