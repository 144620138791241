import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../../../common/emotion/theme';

export const FullScreenPanel = styled.div(() => {
  const {
    darkMode,
    colors: { PivotWhite, PanelBackground },
  } = useDiscoverTheme();

  return css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background-color: ${darkMode ? PanelBackground : PivotWhite};
    display: flex;
    flex-direction: column;
  `;
});

export const FSPHeader = styled.div(() => {
  const {
    colors: { Black },
  } = useDiscoverTheme();

  return css`
    padding: 10px 30px;
    color: ${Black};
    width: 100%;
    position: sticky;
    top: 0;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      margin: 0;
    }
  `;
});

export const FSPTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-style: inherit;
  font-size: 16px;
  color: inherit;
  line-height: 1;
`;

export const FSPBody = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
`;
