import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';

export const settingsProfileStyles = ({
  theme: {
    colors: {
      RegularFontWeight,
      DisabledFormControlBackgroundColor,
      ActiveLinkText,
      ErrorColor,
      SettingsProfileDropzoneActive,
    },
  },
}) => css`
  .settings-profile {
    height: calc(100vh - 52px - 50px);
    width: 100vw;
    overflow: auto;
  }
  .settings-profile__profile-image {
    width: 63px;
    height: 63px;
    background-position: top center;
    background-size: cover;
    border-radius: 63px;
    margin: auto;
    display: inline-block;
    cursor: pointer;
  }
  .settings-profile__profile-image.disabled {
    cursor: default;
  }
  .disabled-form-control #phone[name='phoneNumber'] {
    background-color: ${DisabledFormControlBackgroundColor};
  }
  .settings-profile__photo-area {
    padding: 8px;
    text-align: center;
  }
  .settings-profile__change-photo-link {
    cursor: pointer;
    ${font({ size: '12px', weight: RegularFontWeight, color: ActiveLinkText })}
    margin-top: 4px;
    white-space: nowrap;
  }
  .settings-profile__form {
    margin-top: 16px;
  }
  .settings-profile__form .form-control {
    height: 40px;
  }
  .settings-profile__form .form-control.error {
    border-color: ${ErrorColor};
  }
  .settings-profile__dropzone {
    height: 105px;
    width: 105px;
    margin: auto;
  }
  .settings-profile__dropzone--active {
    border-color: ${SettingsProfileDropzoneActive};
  }
`;
