import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Popper from '@mui/material/Popper';
import { useDiscoverTheme } from '../../common/emotion/theme';
import { svgColorizePartial } from '../../common/emotion/mixins';
import { CheckIcon } from '../../components/Icons';

export const ListButtonStyled = styled(ListItemButton)(() => {
  const {
    colors: { PanelIconColor, MediumBlue },
  } = useDiscoverTheme();

  return css`
    font-size: 0.75rem;
    font-weight: 300;
    padding: 4px 8px 4px 14px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;

    &:hover {
      ${svgColorizePartial({
        fromColor: PanelIconColor,
        toColor: MediumBlue,
      })(`.nested-dropdown-chevron`)}
    }

    .MuiSvgIcon-root {
      max-width: 20px;
    }
  `;
});

export const PaperStyled = styled(Paper)(() => {
  const {
    darkMode,
    colors: { MediumBorder, MediumBlue, PivotWhite },
  } = useDiscoverTheme();

  return css`
    box-shadow: none;
    border: 1px solid ${MediumBorder};
    background-color: ${darkMode ? MediumBlue : PivotWhite};
    background-image: unset;
  `;
});

export const CheckIconStyled = styled(CheckIcon)`
  position: absolute;
  left: -14px;
  top: 4px;
`;

export const MenuItemSpacedForCheck = styled(ListItem)`
  ${ListButtonStyled} {
    padding-left: 8px;
  }
  padding: 0 0 0 18px;
`;

export const PopperStyled = styled(Popper)`
  &#time-hierarchy-submenu {
    ${MenuItemSpacedForCheck} {
      padding: 0;
    }
  }
`;
