import { Component, ComponentClass, ComponentType } from 'react';
import { compose } from 'react-recompose';
import { Nav, NavItem } from '@sugar-discover/react-bootstrap-wrapper';
import { SettingsProfile } from '../settings-profile';
import Notifications from '../Notifications';
import { SettingsI18n } from '../settings-i18n';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import _, { capitalize, isNil, keys } from 'lodash';
import URLs from '../../common/Urls';
import { messages } from '../../i18n';
import { FullScreenPortal } from '../../common/widgets/dialogs/full-screen-portal';
import { ButtonContainer, PrimaryButton } from '../../ui';

type IComponent = ComponentClass<any, any> | ComponentType<any>;

interface ISettings {
  [homePanel: string]: IComponent;
  notifications: IComponent;
  password?: IComponent;
  locale?: IComponent;
}

interface IAccountSettingsProps {
  match: any;
  history: any;
}

interface IAccountSettingsState {
  settings: ISettings;
  homePanel: string;
}

class UnconnectedAccountSettings extends Component<
  IAccountSettingsProps,
  IAccountSettingsState
> {
  state: IAccountSettingsState;

  constructor(props) {
    super(props);

    const homePanel = 'user';
    const settings: ISettings = {
      [homePanel]: SettingsProfile,
      notifications: Notifications,
      locale: SettingsI18n,
    };

    this.state = {
      settings,
      homePanel,
    };
  }

  onClose() {
    URLs.goToLastOrHome('/account');
  }

  render() {
    return (
      <FullScreenPortal
        className='account-settings'
        titlePanel={messages.account.profile}
        buttonGroup={
          <ButtonContainer>
            <PrimaryButton
              onClick={() => {
                this.onClose();
              }}
            >
              {messages.editDatasetPanel.saveChangesAndExit}
            </PrimaryButton>
          </ButtonContainer>
        }
      >
        <div className='account-settings__content'>
          <Route
            path={`${this.props.match.url}/:tabURL?`}
            render={props => {
              const panel = props.match.params.tabURL || this.state.homePanel;
              return [
                <Nav key='nav-pills' bsStyle='pills' stacked activeKey={panel}>
                  {keys(this.state.settings).map(view => (
                    <NavItem
                      key={view}
                      eventKey={view}
                      onSelect={selectedView =>
                        this.props.history.push(
                          `${this.props.match.url}/${selectedView}`,
                        )
                      }
                    >
                      {capitalize(_.get(messages.account, view))}
                    </NavItem>
                  ))}
                </Nav>,
                <div
                  key='settings-panel-content'
                  className='account-settings__panel-content'
                >
                  <h3>{capitalize(_.get(messages.account, panel))}</h3>
                  <Switch>
                    <Route
                      path={`${this.props.match.url}/:tabURL?`}
                      render={({ match }) => {
                        const matchedPanel =
                          match.params.tabURL || this.state.homePanel;
                        const Component = this.state.settings[matchedPanel];
                        if (matchedPanel === this.state.homePanel) {
                          return (
                            <Route
                              path={`${props.match.url}/:formField?`}
                              render={routeProps => (
                                <Component {...routeProps} />
                              )}
                            />
                          );
                        }
                        return !isNil(Component) ? (
                          <Component />
                        ) : (
                          <Redirect
                            to={`${this.props.match.url}/${this.state.homePanel}`}
                          />
                        );
                      }}
                    />
                  </Switch>
                </div>,
              ];
            }}
          />
        </div>
      </FullScreenPortal>
    );
  }
}

export const AccountSettings = compose(withRouter)(
  UnconnectedAccountSettings as ComponentType<IAccountSettingsProps>,
);
