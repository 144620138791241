import LineSvgIcon from '../../images/sdd/viz/visualization-selection-line.svg';
import ColumnSvgIcon from '../../images/sdd/viz/visualization-selection-column.svg';
import StackSvgIcon from '../../images/sdd/viz/visualization-selection-stacked.svg';
import ForecastSvgIcon from '../../images/sdd/viz/visualization-selection-forecast-changes.svg';
import ListSvgForecast from '../../images/sdd/viz/forecast-changes.svg';
import ListSvgColumn from '../../images/sdd/viz/column.svg';
import ListSvgLine from '../../images/sdd/viz/line-chart.svg';
import ListSvgStack from '../../images/sdd/viz/stacked-column.svg';
import WaterfallSvgIcon from '../../images/sdd/viz/waterfall.svg';
import _, { flatten, map, reject, some } from 'lodash';
import {
  getDateTypesFromShelfAsOrdinals,
  getHardCodedOrdinalsFromShelfAsOrdinals,
} from './charts/ChartUtils';
import {
  ChartSpecIds,
  TRENDLINE_TOGGLE_SELECTOR,
  Types,
} from '../common/Constants';
import BaseChartSpec, {
  ENABLE_REPORT_LINK,
  NON_NUMERIC_CALC,
  Validations,
  generateMeasuresFromMetrics,
} from './BaseChartSpec';
import { LegendShapes } from './charts/legend-shape';
import { setChartSpecs } from './VizUtil.common';
import FunnelSpec from './charts/Funnel/chartSpec';
import StackLineSpec from './charts/StackLineCombo/chartSpec';
import PivotChartSpec from './charts/pivot/chartSpec';
import LineChartSpec from './charts/Line/chartSpec';
import ColumnLineSpec from './charts/ColumnLineCombo/chartSpec';
import StackBarSpec from './charts/StackBar/chartSpec';
import { Viz } from './VizUtil';
import { IQueryCalc, ShelfTypes } from './interfaces';
import {
  getCaseQueryCalcs,
  getExistingFieldNames,
  getFormattedShelfName,
  getSnapshotDateFieldCalc,
  makeUniqueFieldName,
  shelvesInPlay,
  waterfallExtraCalcs,
  waterfallQueryMeasures,
} from './charts/waterfall/waterfall.util';

const ChartSpecs = {
  pipeline_changes: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'pipeline_changes',
        name: 'chartSpecs.pipeline_changes.chartName',
        icon: <ForecastSvgIcon />,
        legendShape: LegendShapes.DASHED_LINE,
        listIcon: <ListSvgForecast />,
        placeholderImage:
          '/assets/images/sdd/chart/canvas-icon-forecast-changes.svg',
        shelves: {
          // shelves duplicated from pivot. We are going to extend shelf functionality later
          [ChartSpecIds.ROWS]: {
            id: ChartSpecIds.ROWS,
            name: 'pivot.rowsShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.SELECTION,
            limits: { min: 2, max: 2 },
          },
          [ChartSpecIds.COLUMNS]: {
            id: ChartSpecIds.COLUMNS,
            name: 'pivot.columnsShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.SELECTION,
            limits: { min: 2, max: 2 },
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'pivot.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
            limits: { min: 1, max: 5 },
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.pipeline_changes.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
        customFormatToggles: [
          ENABLE_REPORT_LINK,
          {
            name: 'rowSubtotals',
            displayLabel: 'pivot.rowSubtotalsToggle',
            type: 'subtotals',
            isAvailable: _.stubTrue,
            default: true,
            isAdvanced: true,
          },
          {
            name: 'rowGrandTotals',
            displayLabel: 'pivot.rowsGrandTotalsToggle',
            type: 'grandtotals',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'colGrandTotals',
            displayLabel: 'pivot.colsGrandTotalsToggle',
            type: 'grandtotals',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'recordCounts',
            displayLabel: 'pivot.recordCountsToggle',
            type: 'counts',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'canCollapse',
            displayLabel: 'pivot.canCollapse',
            type: 'show',
            isAvailable: _.stubTrue,
            default: false,
          },
        ],
        supportsLegendSelection: true,
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.ROWS]),
        Validations.OneOf([this.shelves.COLUMNS]),
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
        Validations.FieldLimit,
      ];
    }
    mapShelvesToQueryVariables(_viz) {
      return ChartSpecs.pivot.mapShelvesToQueryVariables(_viz);
    }
    mapSubtotalsToQuery(_viz) {
      const rowFields = _.get(_viz, 'layout.ROWS', []);
      const colFields = _.get(_viz, 'layout.COLUMNS', []);
      if (_.isEmpty(rowFields) && _.isEmpty(colFields)) {
        return {
          subtotals: [],
        };
      }
      const rowAttributesNames = ChartSpecs.pivot.getFieldsAndOrdinals(
        rowFields,
        _viz,
      );
      const colSubTotalAttrs = [
        ..._.filter(
          rowFields,
          f =>
            f.attributeType === Types.TIMESTAMP ||
            f.attributeType === Types.TIME_CALC,
        ),
        ..._.filter(
          colFields,
          f =>
            f.attributeType !== Types.TIMESTAMP &&
            f.attributeType !== Types.TIME_CALC,
        ),
      ];
      const colAttributeNames = ChartSpecs.pivot.getFieldsAndOrdinals(
        colSubTotalAttrs,
        _viz,
      );
      return {
        subtotals: [
          ...[{ attributeNames: rowAttributesNames }],
          ...[{ attributeNames: colAttributeNames }],
        ],
      };
    }
  })(),
  area: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'area',
        name: 'chartSpecs.area.chartName',
        hidden: true,
        placeholderImage: '/assets/images/sdd/chart/canvas-icon-line.svg',
        icon: <LineSvgIcon />,
        listIcon: <ListSvgLine />,
        legendShape: LegendShapes.CIRCLE,
        shelves: {
          [ChartSpecIds.LINES]: {
            id: ChartSpecIds.LINES,
            name: 'chartSpecs.area.linesShelf',
            accepts: [Types.ANY],
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.XAXIS]: {
            id: ChartSpecIds.XAXIS,
            name: 'chartSpecs.area.xAxisShelf',
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'chartSpecs.area.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
            limits: { min: 2, max: 2 },
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.area.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
        Validations.FieldLimit,
      ];
    }
    mapShelvesToQueryVariables(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          attributeNames: [],
          measures: [],
        };
      }

      const attributeNames: string[] = viz.layout.XAXIS.map(g => g.name).concat(
        viz.layout.LINES.map(c => c.name),
      );

      return {
        attributeNames: _.uniq(attributeNames),
        measures: generateMeasuresFromMetrics(viz),
      };
    }
  })(),
  area_line: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'area_line',
        name: 'chartSpecs.areaLine.chartName',
        hidden: true,
        placeholderImage: '/assets/images/sdd/chart/canvas-icon-line.svg',
        icon: <LineSvgIcon />,
        listIcon: <ListSvgLine />,
        legendShape: LegendShapes.CIRCLE,
        shelves: {
          [ChartSpecIds.LINES]: {
            id: ChartSpecIds.LINES,
            name: 'chartSpecs.areaLine.linesShelf',
            accepts: [Types.NUMBER, Types.CALC],
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.XAXIS]: {
            id: ChartSpecIds.XAXIS,
            name: 'chartSpecs.areaLine.xAxisShelf',
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'chartSpecs.areaLine.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
            limits: { min: 2, max: 2 },
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.area.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
        Validations.FieldLimit,
      ];
    }
    mapShelvesToQueryVariables(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          attributeNames: [],
          measures: [],
        };
      }

      const attributeNames: string[] = viz.layout.XAXIS.map(g => g.name);

      return {
        attributeNames: _.uniq(attributeNames),
        measures: viz.layout.LINES.concat(viz.layout.VALUES).map(v => {
          return {
            attributeName: v.name,
            aggregation: v.defaultAggregation,
            resultSetFunction: 'NONE',
          };
        }),
      };
    }
  })(),

  line: new LineChartSpec(),

  bar: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'bar',
        name: 'chartSpecs.bar.chartName',
        placeholderImage: '/assets/images/sdd/chart/canvas-icon-column.svg',
        icon: <ColumnSvgIcon />,
        listIcon: <ListSvgColumn />,
        legendShape: LegendShapes.SQUARE,
        shelves: {
          [ChartSpecIds.COLUMNS]: {
            id: ChartSpecIds.COLUMNS,
            name: 'chartSpecs.bar.columnsShelf',
            aggregateMeasures: false,
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
            isColumn: true,
          },
          [ChartSpecIds.XAXIS]: {
            id: ChartSpecIds.XAXIS,
            name: 'chartSpecs.bar.xAxisShelf',
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
            groupNames: true,
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'chartSpecs.bar.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.area.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
        supportsLegendSelection: true,
        customFormatToggles: [
          ENABLE_REPORT_LINK,
          {
            name: TRENDLINE_TOGGLE_SELECTOR,
            displayLabel: 'chartSpecs.properties.trendline.label',
            type: 'property',
            isAvailable: _.stubTrue,
            default: false,
          },
        ],
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
      ];
    }
    mapShelvesToQueryVariables(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          attributeNames: [],
          measures: [],
        };
      }
      let attributeNames = viz.layout.XAXIS.map(c => c.name).concat(
        viz.layout.COLUMNS.map(g => g.name),
      );

      // Check to see if any are date_part calculations. If so add ordinal selections for these to facilitate sorting
      attributeNames = attributeNames.concat(
        getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
        getHardCodedOrdinalsFromShelfAsOrdinals(viz.layout.XAXIS),
        getDateTypesFromShelfAsOrdinals(viz.layout.COLUMNS),
        getHardCodedOrdinalsFromShelfAsOrdinals(viz.layout.COLUMNS),
      );

      attributeNames = _.uniq(attributeNames);

      const measures = generateMeasuresFromMetrics(viz);

      return {
        attributeNames,
        measures,
      };
    }
  })(),

  column_line: new ColumnLineSpec(),

  stack: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'stack',
        name: 'chartSpecs.stack.chartName',
        placeholderImage:
          '/assets/images/sdd/chart/canvas-icon-stacked-column.svg',
        icon: <StackSvgIcon />,
        listIcon: <ListSvgStack />,
        legendShape: LegendShapes.SQUARE,
        shelves: {
          [ChartSpecIds.STACK]: {
            id: ChartSpecIds.STACK,
            name: 'chartSpecs.stack.stackShelf',
            aggregateMeasures: false,
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.XAXIS]: {
            id: ChartSpecIds.XAXIS,
            name: 'chartSpecs.stack.xAxisShelf',
            accepts: NON_NUMERIC_CALC,
            shelfType: ShelfTypes.SELECTION,
            groupNames: true,
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'chartSpecs.stack.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
            isColumn: true,
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.area.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
        supportsLegendSelection: true,
        customFormatToggles: [
          {
            name: 'asPercentage',
            displayLabel: 'chartSpecs.stack.asPercentageToggle',
            type: 'property',
            isAvailable: _.stubTrue,
          },
          {
            name: TRENDLINE_TOGGLE_SELECTOR,
            displayLabel: 'chartSpecs.properties.trendline.label',
            type: 'property',
            isAvailable: _.stubTrue,
            default: false,
          },
          ENABLE_REPORT_LINK,
        ],
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
      ];
    }
    mapShelvesToQueryVariables(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          attributeNames: [],
          measures: [],
        };
      }
      // we want all fields in the STACK shelf plus any ordinalAttributes for those fields
      const stackAttributes = viz.layout.STACK.map(g => g.name);
      const ordinalAttributesStacks = viz.layout.STACK.filter(
        g => !_.isEmpty(g.ordinalAttribute),
      ).map(g => g.ordinalAttribute);
      const ordinalAttributesXAxis = viz.layout.XAXIS.filter(
        g => !_.isEmpty(g.ordinalAttribute),
      ).map(g => g.ordinalAttribute);
      const ordinalAttributes = _.concat(
        ordinalAttributesStacks,
        ordinalAttributesXAxis,
      );
      const attributeNames: string[] = viz.layout.XAXIS.map(c => c.name)
        .concat(stackAttributes)
        .concat(ordinalAttributes);

      // Check to see if any are date_part calculations. If so add ordinal selections for these to facilitate sorting
      const ordinalTimes = [
        ...getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
        ...getDateTypesFromShelfAsOrdinals(viz.layout.STACK),
      ];

      return {
        attributeNames: _.uniq(attributeNames.concat(ordinalTimes)),
        measures: generateMeasuresFromMetrics(viz),
      };
    }
  })(),

  stack_bar: new StackBarSpec(),

  pivot: new PivotChartSpec(),

  funnel: new FunnelSpec(),
  stack_line: new StackLineSpec(),
  waterfall: new (class extends BaseChartSpec {
    constructor() {
      super({
        id: 'waterfall',
        name: 'chartSpecs.waterfall.chartName',
        icon: <WaterfallSvgIcon />,
        legendShape: LegendShapes.DASHED_LINE,
        listIcon: <WaterfallSvgIcon />,
        placeholderImage: '/assets/images/sdd/chart/canvas-icon-waterfall.svg',
        shelves: {
          [ChartSpecIds.ENTRANCE]: {
            id: ChartSpecIds.ENTRANCE,
            name: 'chartSpecs.waterfall.entranceShelf',
            accepts: [Types.BOOLEAN],
            isRequired: true,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.CHANGE]: {
            id: ChartSpecIds.CHANGE,
            name: 'chartSpecs.waterfall.changeShelf',
            accepts: [Types.BOOLEAN],
            isRequired: false,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.EXIT]: {
            id: ChartSpecIds.EXIT,
            name: 'chartSpecs.waterfall.exitShelf',
            accepts: [Types.BOOLEAN],
            isRequired: true,
            shelfType: ShelfTypes.SELECTION,
          },
          [ChartSpecIds.VALUES]: {
            id: ChartSpecIds.VALUES,
            name: 'pivot.valuesShelf',
            accepts: [Types.ANY],
            isRequired: true,
            shelfType: ShelfTypes.MEASURE,
            limits: { min: 1, max: 5 },
          },
          [ChartSpecIds.SLICER]: {
            id: ChartSpecIds.SLICER,
            name: 'chartSpecs.pipeline_changes.slicer',
            accepts: [Types.STRING, Types.TIMESTAMP],
            isRequired: false,
            shelfType: ShelfTypes.SLICER,
          },
        },
        customFormatToggles: [
          ENABLE_REPORT_LINK,
          {
            name: 'rowSubtotals',
            displayLabel: 'pivot.rowSubtotalsToggle',
            type: 'subtotals',
            isAvailable: _.stubTrue,
            default: true,
            isAdvanced: true,
          },
          {
            name: 'rowGrandTotals',
            displayLabel: 'pivot.rowsGrandTotalsToggle',
            type: 'grandtotals',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'colGrandTotals',
            displayLabel: 'pivot.colsGrandTotalsToggle',
            type: 'grandtotals',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'recordCounts',
            displayLabel: 'pivot.recordCountsToggle',
            type: 'counts',
            isAvailable: _.stubTrue,
            default: false,
            isAdvanced: true,
          },
          {
            name: 'canCollapse',
            displayLabel: 'pivot.canCollapse',
            type: 'show',
            isAvailable: _.stubTrue,
            default: false,
          },
        ],
        supportsLegendSelection: true,
      });
      this.validationRules = [
        Validations.HasLayout,
        Validations.OneOf([this.shelves.ENTRANCE]),
        Validations.OneOf([this.shelves.EXIT]),
        Validations.OneOf([this.shelves.VALUES]),
        Validations.TypeSafe,
        Validations.FieldLimit,
      ];
    }
    mapSubtotalsToQuery(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          subtotals: [],
        };
      }

      const existingFieldNames = getExistingFieldNames(viz.layout, viz.dataset);
      const snapshotAttributeName = getSnapshotDateFieldCalc(
        viz.layout,
        viz.dataset,
      ).attributeName;

      const subtotalQueryAttrs = map(shelvesInPlay(viz), shelfId => ({
        attributeNames: [
          makeUniqueFieldName(
            existingFieldNames,
            getFormattedShelfName(viz.chartType, shelfId),
          ),
          snapshotAttributeName,
        ],
      }));

      return {
        subtotals: subtotalQueryAttrs,
      };
    }
    mapCalcsToQuery(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          calcs: [],
        };
      }

      // generate all required calcs
      const baseQueryCalcs: IQueryCalc[] =
        Viz.mapCalcsToQuery(viz)?.calcs || [];

      const caseQueryCalcs: IQueryCalc[] = getCaseQueryCalcs(viz);

      // replace base query calcs with any generated from this method
      const vizQueryCalcs: IQueryCalc[] = reject(
        baseQueryCalcs,
        ({ attributeName }) => some(caseQueryCalcs, { attributeName }),
      );

      const firstMetricCalcs = waterfallExtraCalcs(viz.layout, viz.dataset);

      return {
        calcs: flatten([
          vizQueryCalcs,
          caseQueryCalcs,
          firstMetricCalcs,
          getSnapshotDateFieldCalc(viz.layout, viz.dataset),
        ]),
      };
    }
    mapShelvesToQueryVariables(viz) {
      if (!this.validate(viz)?.valid) {
        return {
          attributeNames: [],
          measures: [],
        };
      }

      const existingFieldNames = getExistingFieldNames(viz.layout, viz.dataset);

      const attributeNames = map(shelvesInPlay(viz), shelfId =>
        makeUniqueFieldName(
          existingFieldNames,
          getFormattedShelfName(viz.chartType, shelfId),
        ),
      );

      const measureAttributes = waterfallQueryMeasures(viz);

      return {
        attributeNames: _.uniq(attributeNames).concat(
          getSnapshotDateFieldCalc(viz.layout, viz.dataset).attributeName,
        ),
        measures: measureAttributes,
      };
    }
  })(),
};

setChartSpecs(ChartSpecs);

export { ChartSpecs };
