import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  memo,
  MouseEvent,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { List } from 'react-virtualized';
import Calendar from 'rc-calendar';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import enUS from 'rc-calendar/lib/locale/en_US';
import { Moment } from 'moment';
import { SlicerOptionRow } from '../../slicer-option/slicer-option.styles';
import { RightChevron } from '../../../../icons';
import { SCROLL_ROW_HEIGHT } from '../../slicer-widget/common';
import {
  DatePickerWrapper,
  SlicerLabel,
  SlicerOptionSubMenuWrapper,
  StyledList,
} from './timestamp-slicer-options.styles';
import { SlicerOptionSubMenu } from './slicer-option-sub-menu';
import { useSlicerDimensions } from '../../slicer-widget/common/slicer-dimensions.hook';
import { messages } from '../../../../i18n';
import { ISlicerOptionsCommonProps } from '../../slicer-widget/slicer-options';
import { ClickOutsideListener } from '../../../../components/ClickOutsideListener';
import moment, { globalDateWithoutTimeFormat } from '../../../../common/Moment';
import { TimestampSlicerTypes } from '../../interfaces';
import { useAnimationTransition } from './timestamp-slicer-options.hook';
import { useAccount } from '../../../../common/utilities/account';

const timestampMenuItems = [
  messages.slicer.setTimeFrame,
  messages.slicer.since,
  messages.slicer.between,
];

export const rangeCalendarWidth = 502;

interface ITimestampSlicerOptionsProps extends ISlicerOptionsCommonProps {
  selectTimeFrame: (options: string[]) => void;
  pushBreadcrumb: (val: string) => void;
  breadcrumbs: string[];
  clear: () => void;
}

interface IOptionRowProps
  extends Omit<ITimestampSlicerOptionsProps, 'slicerErrorVisible'> {
  id: string;
  name: string;
  timestampMenuItem: string;
  style: any;
  listHeight: number;
}

export const OptionRow = memo<IOptionRowProps>(
  ({
    id,
    name,
    timestampMenuItem,
    style,
    width,
    selectTimeFrame,
    options,
    breadcrumbs,
    pushBreadcrumb,
    clear,
    listHeight,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [subMenuRightPosition, setSubMenuRightPosition] = useState(false);
    const [betweenCalendarWidth, setBetweenCalendarWidth] = useState(
      rangeCalendarWidth,
    );
    const [hoverValue, setHoverValue] = useState([]);
    const isMobile = useSelector((state: any) => state.main.isMobile);
    const isBetweenType = useMemo(
      () => _.last(options).option === TimestampSlicerTypes.BETWEEN,
      [options],
    );
    const isSinceType = useMemo(
      () => _.last(options).option === TimestampSlicerTypes.SINCE,
      [options],
    );
    const [animationRef, safeShowHide] = useAnimationTransition(isOpen);
    const { currentUser: { i18nPrefs = {} } = {} } = useAccount();

    const handleOnClick = (e: MouseEvent<HTMLElement>) => {
      pushBreadcrumb(timestampMenuItem);
      setIsOpen(prev => !prev);
      const subMenuWidth =
        timestampMenuItem === messages.slicer.between
          ? rangeCalendarWidth
          : rangeCalendarWidth / 2;

      const elRect = e.currentTarget.getBoundingClientRect();

      setSubMenuRightPosition(
        !isMobile && window.innerWidth - elRect.right < subMenuWidth,
      );
      setBetweenCalendarWidth(
        elRect.left < rangeCalendarWidth ? elRect.left - 2 : rangeCalendarWidth,
      );
    };

    useEffect(() => {
      if (isMobile && breadcrumbs.length === 0) {
        setIsOpen(false);
      }
    }, [breadcrumbs, isMobile]);

    useEffect(() => {
      moment.updateLocale(moment.locale(), {
        invalidDate: '',
      });
      return () =>
        moment.updateLocale(moment.locale(), {
          invalidDate: messages.slicer.invalidDate,
        });
    }, []);

    const setRangeTimeFrame = useCallback(
      (vals: string[], includeCurrentPeriod: boolean) => {
        selectTimeFrame([
          ...vals,
          `${includeCurrentPeriod}`,
          TimestampSlicerTypes.RANGE,
        ]);
      },
      [selectTimeFrame],
    );

    const setSinceTimeFrame = useCallback(
      (val: Moment) => {
        if (_.isNil(val)) {
          clear();
        } else {
          selectTimeFrame([
            val.startOf('day').format(moment.ISO8601),
            TimestampSlicerTypes.SINCE,
          ]);
        }
      },
      [clear, selectTimeFrame],
    );

    const setBetweenTimeFrame = useCallback(
      (val: Moment[]) => {
        if (_.isNil(val) || val.length < 2) {
          clear();
        } else {
          selectTimeFrame([
            val[0].startOf('day').format(moment.ISO8601),
            val[1].endOf('day').format(moment.ISO8601),
            TimestampSlicerTypes.BETWEEN,
          ]);
        }
      },
      [clear, selectTimeFrame],
    );

    const sinceTypeSelectedValue = () => {
      if (isSinceType) {
        const head = _.head(options);
        if (head.isSelected) {
          return _.head(options).option;
        }
      }
      return null;
    };

    const betweenTypeSelectedValue = () => {
      if (isBetweenType) {
        return [
          moment(_.head(options).option),
          moment(_.last(_.dropRight(options)).option),
        ];
      }
      return [null, null];
    };

    const onHoverChange = hoverValue => {
      if (hoverValue.length == 1) {
        setHoverValue([...hoverValue, ...hoverValue]);
      } else {
        setHoverValue(hoverValue);
      }
    };

    useEffect(() => {
      if (isBetweenType) {
        setHoverValue([
          moment(_.head(options).option),
          moment(_.last(_.dropRight(options)).option),
        ]);
      } else {
        setHoverValue([]);
      }
    }, [isBetweenType, options]);

    const getFormat = () =>
      _.isEmpty(i18nPrefs?.dateFormat)
        ? globalDateWithoutTimeFormat
        : i18nPrefs?.dateFormat;

    const renderSubMenu = timestampMenuItem => {
      const subMenu = {
        [timestampMenuItems[0]]: (
          <SlicerOptionSubMenu
            options={options}
            selectRange={setRangeTimeFrame}
            name={name}
          />
        ),
        [timestampMenuItems[1]]: (
          <DatePickerWrapper className='corvana-calendar-picker since-calendar'>
            <Calendar
              format={getFormat()}
              selectedValue={moment(sinceTypeSelectedValue())}
              locale={enUS}
              showOk={false}
              onChange={setSinceTimeFrame}
            />
          </DatePickerWrapper>
        ),
        [timestampMenuItems[2]]: (
          <DatePickerWrapper className='corvana-calendar-picker between-calendar'>
            <RangeCalendar
              selectedValue={betweenTypeSelectedValue()}
              hoverValue={hoverValue}
              onHoverChange={onHoverChange}
              format={getFormat()}
              locale={enUS}
              showOk={false}
              onSelect={setBetweenTimeFrame}
            />
          </DatePickerWrapper>
        ),
      };

      if (_.isNil(subMenu[timestampMenuItem])) {
        return null;
      }

      return subMenu[timestampMenuItem];
    };

    return (
      <SlicerOptionRow
        id={id}
        style={isMobile ? { ...style, height: 'initial' } : style}
        width={width}
        className='combo-menu-item'
      >
        <ClickOutsideListener
          isChildrenVisible={isOpen}
          onClickAway={() => !isMobile && setIsOpen(false)}
        >
          <SlicerLabel
            height={style.height}
            isOpen={isOpen}
            onClick={handleOnClick}
            className='main-timestamp-option'
          >
            <span>{timestampMenuItem}</span>
            <RightChevron />
          </SlicerLabel>
          <SlicerOptionSubMenuWrapper
            ref={animationRef}
            isSubMenuOpen={isOpen}
            isRightPosition={subMenuRightPosition}
            betweenCalendarWidth={betweenCalendarWidth}
            listHeight={listHeight}
          >
            {(safeShowHide || isOpen) && renderSubMenu(timestampMenuItem)}
          </SlicerOptionSubMenuWrapper>
        </ClickOutsideListener>
      </SlicerOptionRow>
    );
  },
);

export const TimestampSlicerOptions = memo<ITimestampSlicerOptionsProps>(
  ({
    width: propsWidth,
    options,
    name,
    slicerErrorVisible,
    selectTimeFrame,
    breadcrumbs,
    pushBreadcrumb,
    clear,
  }) => {
    const { height, width } = useSlicerDimensions({
      optionsLength: timestampMenuItems.length,
      slicerErrorVisible,
      propsWidth,
    });

    const RowRenderer = useCallback(
      ({ key, index, style }) => {
        const timestampMenuItem = timestampMenuItems[index];

        return (
          <OptionRow
            id={`slicer-option-row-${_.kebabCase(name)}__${_.kebabCase(
              timestampMenuItem,
            )}`}
            name={name}
            key={key}
            style={style}
            width={width}
            listHeight={height}
            timestampMenuItem={timestampMenuItem}
            options={options}
            selectTimeFrame={selectTimeFrame}
            breadcrumbs={breadcrumbs}
            pushBreadcrumb={pushBreadcrumb}
            clear={clear}
          />
        );
      },
      [
        name,
        width,
        height,
        options,
        selectTimeFrame,
        breadcrumbs,
        pushBreadcrumb,
        clear,
      ],
    );

    return (
      <StyledList>
        <List
          width={propsWidth}
          height={height}
          style={{ overflow: 'inherit', maxHeight: height }}
          rowCount={timestampMenuItems.length}
          rowHeight={SCROLL_ROW_HEIGHT}
          rowRenderer={RowRenderer}
        />
      </StyledList>
    );
  },
);
