import { useState, useEffect, useRef } from 'react';
import FieldPill from '../FieldPill';
import { ChartSpecIds, DnDTypes, Types } from '../../common/Constants';
import { useDrop } from 'react-dnd';
import ShelfDropLocation from '../ShelfDropLocation';
import _ from 'lodash';
import classnames from 'classnames';
import { Hierarchy } from '../VizUtil';
import { Tooltip } from '../../components/ui/tooltip';
import { messages } from '../../i18n';
import {
  useOpenVizChartSpec,
  useOpenVizLayoutSelector,
  useOpenVizSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { FieldDropdown } from '../../components/field-dropdown';
import { DropMonitor, IDropProps, IShelfProps } from './shelf.interface';
import { InfoIcon } from '../../icons';
import { ShelfTitle } from './shelf.styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const DropFieldsHere = props => {
  return (
    <div className={'drop-fields-here'}>
      {messages.layoutPanel.dropFieldsHere}
      {props?.required && <span className={'required-text'}>*</span>}
    </div>
  );
};

export const Shelf = ({
  shelf,
  discoveryId,
  missingFields = [],
  onContextMenuAction = _.noop,
}: IShelfProps) => {
  const [dropErrorMessage, setDropErrorMessage] = useState('');
  const [isValidDrop, setIsValidDrop] = useState(true);
  const chartSpec = useOpenVizChartSpec({ discoveryId });
  const layout = useOpenVizLayoutSelector({ discoveryId });
  const viz = useOpenVizSelector({ discoveryId });
  const validation = chartSpec?.validate(viz);

  const shelfRef = useRef(null);

  const [dropProps, drop] = useDrop({
    drop: (props: any, monitor: DropMonitor) =>
      shelfTarget.drop(
        { ...props, layout, shelf, chartSpec, onContextMenuAction },
        monitor,
      ),
    hover: (props: any, monitor: DropMonitor) =>
      shelfTarget.hover(
        {
          ...props,
          shelf,
          chartSpec,
          setIsValidDrop,
          setDropErrorMessage,
        },
        monitor,
      ),
    accept: DnDTypes.VIZ_FIELD,
    collect,
  });

  useEffect(() => {
    if (shelfRef?.current) {
      drop(shelfRef.current);
    }
  }, [drop]);

  const renderShelfPills = () => {
    let fields = [];
    const { isRequired } = chartSpec.shelves[shelf.id];
    if (layout[shelf.id]) {
      fields = layout[shelf.id].reduce((accum, f, idx) => {
        const isFieldMissing = _.some(missingFields, obj =>
          _.isEqual(obj.name, f.name),
        );
        const invalid =
          validation.error &&
          validation.error.errorFields &&
          validation.error.errorFields.find(ef => ef.name === f.name);
        accum.push(
          <ShelfDropLocation
            key={`shelf-dnd-${shelf.id}_${idx}`}
            index={idx}
            shelf={shelf}
            chartSpec={chartSpec}
            layout={layout}
            discoveryId={discoveryId}
          />,
        );
        accum.push(
          <FieldPill
            field={f}
            shelf={shelf}
            key={`${shelf.name}_${f.name}`}
            isFieldMissing={isFieldMissing}
            invalid={invalid && 'drop-invalid'}
            renderFieldDropdown={isDragging => (
              <FieldDropdown
                discoveryId={discoveryId}
                field={f}
                shelf={shelf}
                doMenuAction={key => onContextMenuAction(key, f)}
                isFieldMissing={isFieldMissing}
                isDragging={isDragging}
              />
            )}
          ></FieldPill>,
        );
        if (idx === layout[shelf.id].length - 1) {
          accum.push(
            <ShelfDropLocation
              key={`shelf-dnd-${shelf.id}_${idx + 1}`}
              index={idx + 1}
              shelf={shelf}
              chartSpec={chartSpec}
              layout={layout}
              discoveryId={discoveryId}
            />,
          );
        }
        return accum;
      }, []);
    }

    fields.push(
      <DropFieldsHere
        key={`${shelf.id}_dropFieldsHere`}
        required={isRequired}
      />,
    );

    return fields;
  };

  const shelfClasses = classnames('shelf', {
    'drop-in-process': dropProps.isDragInProcess,
    'drop-valid': isValidDrop,
    'drop-invalid': !isValidDrop,
    'drop-hover': dropProps.isOver,
  });

  const showValidationError =
    dropProps.isDragInProcess && !isValidDrop && dropProps.isOver;

  return (
    <div ref={shelfRef}>
      <ShelfTitle>
        <label>{_.get(messages, shelf.name, shelf.name)}</label>
        {[
          ChartSpecIds.ENTRANCE,
          ChartSpecIds.CHANGE,
          ChartSpecIds.EXIT,
        ].includes(shelf.id) && (
          <Tooltip
            arrow
            placement={'bottom'}
            title={
              messages.chartSpecs.waterfall[shelf.id.toLocaleLowerCase()]
                .tooltip
            }
            slotProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 250,
                    maxHeight: 200,
                  },
                },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <div>
              <InfoIcon hover size={12} />
            </div>
          </Tooltip>
        )}
      </ShelfTitle>

      <Tooltip
        arrow
        placement={'right'}
        title={dropErrorMessage ?? ''}
        open={showValidationError}
      >
        <div className={shelfClasses}>{renderShelfPills()}</div>
      </Tooltip>
    </div>
  );
};

const shelfTarget = {
  drop(props, monitor) {
    if (monitor.didDrop() || !validateDrop(props, monitor)) {
      // this was already handled by some nested drop target
      return;
    }
    let { field, shelf: fromShelf } = monitor.getItem();
    const { shelf: toShelf } = props;
    if (!_.isEqual(toShelf?.id, 'SLICER') && !_.isEmpty(field.children)) {
      const inPlay: any = Object.values(props.layout).reduce(
        (fields: any, shelf: any) => {
          return [...fields, ...shelf];
        },
        [],
      );
      // get default field
      let defaultField;
      if (field.attributeType === Types.TIMESTAMP) {
        defaultField = field.children.find(c => {
          return _.isEqual(c.timeAttribute, Hierarchy.TIME_ATTRIBUTES.DATE);
        });
      }
      // get first child field not in-play
      let firstOneLeft;
      field.children.some(child => {
        const isInPlay = inPlay.find(f => f.name === child.name);
        if (_.isNil(isInPlay)) {
          firstOneLeft = child;
          return true;
        }
        return false;
      });
      if (
        !_.isNil(defaultField) &&
        !inPlay.find(f => f.name === defaultField.name)
      ) {
        field = defaultField;
      } else if (!_.isNil(firstOneLeft)) {
        field = firstOneLeft;
      }
    }

    const shelfId = props.shelf.id;
    const shelfFields = _.get(props, `layout.${shelfId}`, []);
    const isFieldInShelfAlready = shelfFields.find(f => _.isEqual(f, field));
    if (!isFieldInShelfAlready) {
      // it's not already in the shelf, go ahead & add it now
      props.onContextMenuAction(shelfId, field, fromShelf);
    }
  },
  hover(props, monitor) {
    const { field, shelf: fromShelf } = monitor.getItem();

    const dropError =
      props.chartSpec.validateFieldForShelfWithError &&
      props.chartSpec.validateFieldForShelfWithError(
        field,
        props.shelf?.id,
        props.layout,
        fromShelf,
      );
    if (dropError !== messages.baseChart.noErrors) {
      if (_.isFunction(props.setDropErrorMessage)) {
        props.setDropErrorMessage(dropError);
        props.setIsValidDrop(false);
      }
    } else {
      props.setIsValidDrop(true);
    }
  },
};
const collect = (monitor: DropMonitor): IDropProps => {
  return {
    isOver: monitor.isOver(),
    isDragInProcess: !!monitor.getItemType(),
    field: monitor.getItem() ? monitor.getItem().field : null,
    canDrop: monitor.canDrop(),
  };
};

const validateDrop = (props, monitor: DropMonitor) => {
  const { field, shelf: fromShelf } = monitor.getItem();
  const toShelfId = props.shelf.id;
  const { layout } = props;
  const res = props.chartSpec.validateFieldForShelf(
    field,
    toShelfId,
    layout,
    fromShelf,
  );

  return res;
};
