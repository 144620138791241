import React, { useState } from 'react';
import {
  IDatasetAnnotations,
  IDatasetSettings,
} from '../../edit-dataset-settings.interfaces';
import {
  FunnelConfigurationSection,
  FunnelConfigurationGrid,
  FunnelConfigurationSplitLabel,
  FunnelConfigurationSplitInput,
} from './funnel-configuration.styles';
import _, { head } from 'lodash';
import { messages } from '../../../../i18n';
import { IAttribute } from '../../../../datasets/interfaces';
import {
  ISearchableDropdownOption,
  SearchableDropdown,
} from '../../../../ui/dropdowns/searchable-dropdown';
import { useDatasetSettingsContext } from '../../edit-dataset-settings.context';
import { makeDropdownOption } from '../../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';

interface IProps {
  onChange: (val: IDatasetSettings) => void;
}

const funnelFieldTypes = {
  id: 'STRING',
  time: 'TIMESTAMP',
};

export const FunnelConfiguration = ({ onChange }: IProps) => {
  const { dataset, datasetSettings } = useDatasetSettingsContext();

  const [datasetAnnotations, setSugarCrmDatasetAnnotationRequest] = useState<
    IDatasetAnnotations
  >(datasetSettings?.datasetAnnotations);

  const selectableDatasetAttributes: IAttribute[] = _.filter(
    _.get(dataset, 'attributes', []),
    _attrib => {
      return _.includes(_.values(funnelFieldTypes), _attrib.attributeType);
    },
  );

  const onFieldNameSelect = (annotationName: string, fieldName: string) => {
    const datasetSettingsSaveRequest: IDatasetSettings = {
      datasetId: dataset.id,
      datasetAnnotations: {
        ...datasetAnnotations,
        [annotationName]: fieldName,
      },
    };
    onChange(datasetSettingsSaveRequest);
    setSugarCrmDatasetAnnotationRequest(prev => ({
      ...prev,
      [annotationName]: fieldName,
    }));
  };

  const renderConfigInput = (id, funnelFieldType, defaultValue) => {
    return (
      <FunnelConfigurationSplitInput>
        <SearchableDropdown
          id={id}
          defaultValue={defaultValue}
          options={_.map(
            _.filter(selectableDatasetAttributes, {
              attributeType: funnelFieldType,
            }),
            ({ name: _name }) => makeDropdownOption(_name),
          )}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const option = head(options) ?? {};
            onFieldNameSelect(id, option?.value);
          }}
        />
      </FunnelConfigurationSplitInput>
    );
  };

  const objectIdAttribDefaultValueName = _.get(
    datasetAnnotations,
    'objectIdAttrib',
  );
  const objectIdAttribDefaultValue = objectIdAttribDefaultValueName
    ? makeDropdownOption(objectIdAttribDefaultValueName)
    : undefined;

  const createdDateAttribDefaultValueName = _.get(
    datasetAnnotations,
    'createdDateAttrib',
  );

  const createdDateAttribDefaultValue = createdDateAttribDefaultValueName
    ? makeDropdownOption(createdDateAttribDefaultValueName)
    : undefined;

  const closeDateAttribDefaultValueName = _.get(
    datasetAnnotations,
    'closeDateAttrib',
  );

  const closeDateAttribDefaultValue = closeDateAttribDefaultValueName
    ? makeDropdownOption(closeDateAttribDefaultValueName)
    : undefined;

  return (
    <FunnelConfigurationSection>
      <FunnelConfigurationSection>
        <FunnelConfigurationGrid>
          <FunnelConfigurationSplitLabel>
            {messages.editDatasetPanel.objectIdAttrib}
          </FunnelConfigurationSplitLabel>
          {renderConfigInput(
            'objectIdAttrib',
            funnelFieldTypes.id,
            objectIdAttribDefaultValue,
          )}
          <FunnelConfigurationSplitLabel>
            {messages.editDatasetPanel.createdDate}
          </FunnelConfigurationSplitLabel>
          {renderConfigInput(
            'createdDateAttrib',
            funnelFieldTypes.time,
            createdDateAttribDefaultValue,
          )}
          <FunnelConfigurationSplitLabel>
            {messages.editDatasetPanel.closeDate}
          </FunnelConfigurationSplitLabel>
          {renderConfigInput(
            'closeDateAttrib',
            funnelFieldTypes.time,
            closeDateAttribDefaultValue,
          )}
        </FunnelConfigurationGrid>
      </FunnelConfigurationSection>
    </FunnelConfigurationSection>
  );
};
