import { useMemo } from 'react';
import { useViewportDimensions } from '../../../../common/utilities/dimensions.hook';
import {
  SCROLL_CONTAINER_MAX_HEIGHT,
  SCROLL_ROW_HEIGHT,
  useSlicerTheme,
} from '.';

interface ISlicerDimensionsProps {
  optionsLength: number;
  slicerErrorVisible: boolean;
  propsWidth: number;
  isDashletTag?: boolean;
}

interface ISlicerDimensionsResult {
  height: number;
  width: number;
}

export const useSlicerDimensions = ({
  optionsLength,
  slicerErrorVisible,
  propsWidth,
  isDashletTag,
}: ISlicerDimensionsProps): ISlicerDimensionsResult => {
  const { isMobile } = useSlicerTheme();
  const { height: windowHeight } = useViewportDimensions();
  const calcHeight = optionsLength * SCROLL_ROW_HEIGHT;
  let height =
    isMobile && !isDashletTag
      ? windowHeight - 250
      : Math.min(SCROLL_CONTAINER_MAX_HEIGHT, calcHeight);
  if (isMobile && slicerErrorVisible) {
    height = windowHeight - 295;
  }

  const isScrolling = calcHeight > SCROLL_CONTAINER_MAX_HEIGHT;
  const width = useMemo(() => (isScrolling ? propsWidth - 12 : propsWidth), [
    propsWidth,
    isScrolling,
  ]);

  return {
    height,
    width,
  };
};
