import { useCallback, useEffect, useState } from 'react';
import { forEach, merge, startsWith } from 'lodash';
import {
  bottom as POPPER_BOTTOM_SELECTOR,
  top as POPPER_TOP_SELECTOR,
} from '@popperjs/core';
import { useDiscoverTheme } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';
import { useObserver } from '../../../common/utilities/resize-observer';
import styled from '@emotion/styled';

export const StyledDiv = styled.div`
  flex: 1 1 0%;
  max-width: 100%;
`;

export const useDropdownStyles = ({
  renderedPlacement,
  hasTitle,
  anchorEl,
  isOpen = false,
  headerSx: providedHeaderSx,
  popperSx: providedPopperSx,
  listSx: providedListSx,
}) => {
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const {
    darkMode,
    colors: {
      Gray10,
      Gray60,
      Gray90,
      PanelBorder,
      DropdownButtonBackgroundColor,
      DropdownBorderColor,
      ContentTextColor,
    } = {},
  } = useDiscoverTheme();

  const resizeCallback = useCallback((entries: ResizeObserverEntry[]) => {
    forEach(entries, entry => {
      setAnchorElWidth(entry?.borderBoxSize[0]?.inlineSize ?? 0);
    });
  }, []);
  useObserver({ callback: resizeCallback, element: anchorEl });
  useEffect(() => {
    if (!anchorEl) {
      setAnchorElWidth(0);
    }
  }, [anchorEl]);

  const isRenderedOnTop = startsWith(renderedPlacement, POPPER_TOP_SELECTOR);
  const isRenderedOnBottom = startsWith(
    renderedPlacement,
    POPPER_BOTTOM_SELECTOR,
  );
  const openListSxOverrides: any = {};
  const openHeaderSxOverrides: any = {
    color: darkMode ? ContentTextColor : hasTitle ? Gray90 : Gray60,
  };
  if (isRenderedOnTop) {
    openListSxOverrides.borderBottomWidth = 0;
    openListSxOverrides.borderBottomLeftRadius = 0;
    openListSxOverrides.borderBottomRightRadius = 0;

    openHeaderSxOverrides.borderTopWidth = 0;
    openHeaderSxOverrides.borderTopLeftRadius = 0;
    openHeaderSxOverrides.borderTopRightRadius = 0;
  } else if (isRenderedOnBottom) {
    openListSxOverrides.borderTopWidth = 0;
    openListSxOverrides.borderTopLeftRadius = 0;
    openListSxOverrides.borderTopRightRadius = 0;

    openHeaderSxOverrides.borderBottomWidth = 0;
    openHeaderSxOverrides.borderBottomLeftRadius = 0;
    openHeaderSxOverrides.borderBottomRightRadius = 0;
  }

  const popperSx = merge(
    {
      width: anchorElWidth,
      maxWidth: anchorElWidth,
      zIndex: 11,
    },
    providedPopperSx,
  );

  const listSx = merge(
    {
      width: anchorElWidth,
      borderColor: DropdownBorderColor,
      maxHeight: '20rem',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '2px',
      overflow: 'hidden scroll',
      backgroundColor: darkMode ? DropdownButtonBackgroundColor : Gray10,
    },
    openListSxOverrides,
    providedListSx,
  );

  const borderStyle = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isOpen ? DropdownBorderColor : PanelBorder,
  };

  const headerSx = merge(borderStyle, openHeaderSxOverrides, providedHeaderSx);

  headerSx['&:focus'] = {
    borderColor: DropdownBorderColor,
  };
  headerSx['&:focus-visible'] = {
    borderColor: DropdownBorderColor,
  };

  return {
    headerSx,
    popperSx,
    listSx,
  };
};

export const useSelectItemSx = ({ providedSx = {} }) => {
  const { colors: { LightBlue, Gray90 } = {} } = useDiscoverTheme();

  return merge(
    {
      color: Gray90,
      padding: '0.1rem 0.5rem',
      fontSize: '12px',
      height: '34px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        backgroundColor: LightBlue,
      },
      '&:focus': {
        backgroundColor: LightBlue,
      },
    },
    providedSx,
  );
};

export const useHeaderSx = ({ providedSx, isOpen = false }) => {
  const {
    darkMode,
    colors: {
      Gray60,
      Gray10,
      MediumBlue,
      DropdownButtonBackgroundColor,
      ContentText,
    } = {},
  } = useDiscoverTheme();

  const headerSx = merge(
    {
      height: '28px',
      width: '100%',
      borderRadius: '2px',
      backgroundColor: darkMode ? DropdownButtonBackgroundColor : Gray10,
      fontSize: '12px',
      color: darkMode ? ContentText : Gray60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px',
      cursor: 'pointer',
      userSelect: 'none',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .caret': {
        marginLeft: '0.3rem',
        marginRight: '0.1rem',
      },
      '& span.title': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
      },
    },
    providedSx,
  );

  if (isOpen) {
    headerSx.borderColor = MediumBlue;
  }

  return headerSx;
};
