import { connect } from 'react-redux';
import { SlicerPanelContainer } from './slicer-panel.styles';
import { getSlicerNames } from '../../../common/redux/selectors/viz-selectors';
import { SlicerWidget } from '../slicer-widget';
import { AutoSizer } from 'react-virtualized';
import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useWindowResizeListener } from '../../../common';

interface ISlicerPanelProps {
  slicerNames: string[];
}

const scrollEvents = [
  'DOMMouseScroll',
  'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel',
  'touchmove',
];

export function UnconnectedSlicerPanel({
  slicerNames = [],
}: ISlicerPanelProps) {
  const [initialOpen, setInitialOpen] = useState<boolean>();
  const { width: windowWidth } = useWindowResizeListener();
  useEffect(() => {
    setInitialOpen(false);
  }, [windowWidth]);
  const containerRef = useRef(null);
  // We need this to be a ref and not state, otherwise the callbacks would
  // depend on state, which would be extremely messy.
  const currentOpenRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  // This event handler blocks all scroll events when there is a currently
  // open slicer.
  const preventDefault = useCallback(e => {
    if (currentOpenRef.current !== null) {
      e.preventDefault();
    }
  }, []);
  // Cleanup will remove all scroll listeners from the scroll container
  const cleanup = useCallback(() => {
    if (containerRef.current) {
      _.forEach(scrollEvents, evt =>
        containerRef.current?.removeEventListener(evt, preventDefault),
      );
    }
  }, [preventDefault]);
  const addScrollListeners = useCallback(() => {
    if (containerRef.current) {
      _.forEach(scrollEvents, evt =>
        containerRef.current.addEventListener(evt, preventDefault),
      );
    }
  }, [preventDefault]);
  // This just calls cleanup when this component unmounts. This shouldn't be
  // necessary, but it's better to be safe than sorry.
  useEffect(() => cleanup, [cleanup]);
  return (
    <AutoSizer
      style={{
        marginBottom: _.isEmpty(slicerNames) ? '0' : '45px',
      }}
    >
      {({ width }) => {
        return (
          <SlicerPanelContainer
            id={'slicer-panel'}
            ref={r => {
              if (r !== containerRef.current) {
                cleanup();
                containerRef.current = r;
                addScrollListeners();
              }
            }}
            style={{
              width: width - 40,
              paddingBottom: _.isEmpty(slicerNames) ? 0 : 10,
              // Don't show scrollbar when we have a slicer open
              overflowX: isOpen ? 'hidden' : 'auto',
            }}
          >
            {slicerNames.map(name => {
              return (
                <SlicerWidget
                  initialOpen={initialOpen}
                  onToggle={(isOpen, scrollLeft) => {
                    if (isOpen) {
                      setInitialOpen(undefined);
                      currentOpenRef.current = name;
                      containerRef.current.scrollLeft = scrollLeft - 20;
                      setIsOpen(true);
                    } else if (currentOpenRef.current === name) {
                      // We only want to flag that this is closed if it is the
                      // currently open one. Otherwise, it will have issues when
                      // we close a widget by opening another (and the outside
                      // click causes it to close).
                      currentOpenRef.current = null;
                      setIsOpen(false);
                    }
                  }}
                  key={`slicer-${name}`}
                  name={name}
                />
              );
            })}
          </SlicerPanelContainer>
        );
      }}
    </AutoSizer>
  );
}

export const SlicerPanel = connect(state => {
  return {
    slicerNames: getSlicerNames(state, {} as any),
  };
})(UnconnectedSlicerPanel);
