import { useEffect, useRef, memo } from 'react';
import _ from 'lodash';
import { messages } from '../../../../i18n';
import { SlicerTextSearchField } from './slicer-searchbox.styles';
import { useHasValueChanged } from '../../../../common/utilities/state-helpers.hook';

interface ISlicerSearchboxProps {
  value?: string;
  isOpen: boolean;
  onChange: (val: string) => void;
  className?: string;
}

export const SlicerSearchbox = memo<ISlicerSearchboxProps>(
  ({ isOpen, onChange, className, value = '' }) => {
    const inputRef = useRef<HTMLElement>();

    const hasOpenChanged = useHasValueChanged({
      value: isOpen,
      defaultValue: false,
    });

    useEffect(() => {
      const inputElement = _.get(inputRef, 'current');
      if (hasOpenChanged && isOpen && inputElement) {
        inputElement.focus();
      }
    }, [hasOpenChanged, isOpen, inputRef]);

    const handleOnChange = ({ target }) => {
      onChange(target.value);
    };

    const handleOnKeyDown = e => {
      if (e.key === ' ') {
        e.stopPropagation();
      }
    };

    return (
      <SlicerTextSearchField
        inputRef={inputRef}
        className={className}
        fullWidth
        value={value}
        placeholder={messages.search}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
    );
  },
);
