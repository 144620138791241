import { css } from '@emotion/react';

export const settingsNotificationsStyles = ({
  theme: {
    colors: { TabHighlightDisabled },
  },
}) => css`
  .settings-notifications .settings__tabs li.active a,
  .settings-notifications .settings__tabs li.active a:hover,
  .settings-notifications .settings__tabs li a,
  .settings-notifications .settings__tabs li a:hover {
    background-color: transparent;
  }
  .settings-notifications input[type='checkbox'],
  input[type='radio'] {
    margin-top: 2px;
  }
  .settings-notifications .radio.disabled input,
  .settings-notifications .radio.disabled label {
    color: ${TabHighlightDisabled} !important;
  }
  .settings-notifications .radio.disabled input,
  .settings-notifications .radio.disabled label {
    color: ${TabHighlightDisabled} !important;
  }
  .settings-notifications .settings__tabs li a {
    outline-width: 0px;
  }
`;
