import React from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';
import { constant } from 'lodash';
import { PopperProps } from '@mui/material/Popper/Popper';
import Popper from '@mui/material/Popper';
import * as uuid from 'uuid';

export const usePopperProps = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperId, setPopperId] = useState('');
  const [renderedPlacement, setRenderedPlacement] = useState<
    PopperPlacementType
  >();

  useEffect(() => setPopperId(`popper-${uuid.v4()}`), []);

  const isOpen = Boolean(anchorEl);
  const hasOpenChanged = useHasValueChanged({ value: isOpen });
  useEffect(() => {
    if (hasOpenChanged && !isOpen) {
      setRenderedPlacement(constant(undefined));
    }
  }, [hasOpenChanged, isOpen]);

  const handleToggle = useCallback(
    event => {
      event.preventDefault();
      const _anchorEl =
        event?.currentTarget?.closest(`#${popperId}`) ?? event?.currentTarget;
      setAnchorEl(anchorEl ? null : _anchorEl);
    },
    [anchorEl, popperId],
  );

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const PopperComponent = useMemo(() => {
    return (props: PopperProps) => (
      <Popper
        id={popperId}
        popperOptions={{
          onFirstUpdate: state => setRenderedPlacement(() => state?.placement),
        }}
        {...props}
      />
    );
  }, [popperId]);

  return {
    id: popperId,
    PopperComponent,
    anchorEl,
    handleToggle,
    handleClickAway,
    isOpen,
    renderedPlacement,
  };
};
