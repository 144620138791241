import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAccountMutation, useAccountQuery } from '../graphql';
import { messages } from '../../i18n';
import {
  FormGroup,
  ControlLabel,
  Col,
  Row,
  FormControl,
} from '@sugar-discover/react-bootstrap-wrapper';
import {
  setDatetimeFormat,
  sugarDateFormats,
  sugarTimeFormats,
} from '../../common';
import _, { kebabCase, map } from 'lodash';
import { I18nSettingsForm } from './settings-i18n.styles';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';

export function I18nSettingDropdown({
  options,
  value,
  setValue,
  disabled,
  title,
  'aria-labelledby': ariaLabelledby,
}) {
  const label = _(options)
    .chain()
    .fromPairs()
    .get(value, messages.account.useDefault)
    .value();
  const id = `i18n-setting-${_.kebabCase(title)}`;

  if (options) {
    return (
      <SelectDropdown
        title={label ?? messages.account.useDefault}
        aria-labelledby={ariaLabelledby}
      >
        <SelectItem onClick={() => setValue('')}>
          {messages.account.useDefault}
        </SelectItem>
        {map(options, ([optionKey, optionLabel]) => (
          <SelectItem onClick={() => setValue(optionKey)}>
            {optionLabel}
          </SelectItem>
        ))}
      </SelectDropdown>
    );
  }

  return (
    <FormControl
      id={id}
      disabled={disabled}
      value={value || ''}
      placeholder={messages.account.useDefault}
      onChange={event => setValue((event as any)?.target?.value)}
      aria-labelledby={ariaLabelledby}
    />
  );
}

export function I18nSetting({ title, description, isMobile, ...rest }: any) {
  const labelId = `i18n-setting-label-${kebabCase(title)}`;
  return (
    <Row>
      <Col sm={8} xs={12}>
        <Row componentClass={FormGroup}>
          <Col id={labelId} sm={3} xs={12} componentClass={ControlLabel}>
            {title}
          </Col>
          <Col sm={9} xs={12}>
            <I18nSettingDropdown
              title={title}
              {...rest}
              aria-labelledby={labelId}
            />
          </Col>
        </Row>
      </Col>
      {!isMobile && (
        <Col sm={4} xs={12} className='pull-right aligned'>
          {description}
        </Col>
      )}
    </Row>
  );
}

export function SettingsI18n() {
  const ref = useRef();
  const { userInfo, loading } = useAccountQuery();
  const { updateUser, saving } = useAccountMutation();
  const { i18nPrefs: savedI18nPrefs } = userInfo;
  const [dateFormat, setDateFormat] = useState<string>();
  const [timeFormat, setTimeFormat] = useState<string>();
  const [numGroupSeparator, setNumGroupSeparator] = useState<string>();
  const [decimal, setDecimal] = useState<string>();
  const isMobile = useSelector<any, boolean>(
    (state: any) => state.main?.isMobile,
  );
  const updateCallbackRef = useRef(updateUser);
  updateCallbackRef.current = updateUser;

  const isSaving = saving || loading;

  const i18nPrefs = useMemo(
    () =>
      _.defaults(
        {
          dateFormat,
          timeFormat,
          numGroupSeparator,
          decimal,
        },
        savedI18nPrefs,
      ),
    [dateFormat, decimal, numGroupSeparator, savedI18nPrefs, timeFormat],
  );

  useEffect(() => {
    ref.current = _.assign(ref.current, { i18nPrefs, userInfo });
    setDatetimeFormat({
      dateFormat: i18nPrefs.dateFormat,
      timeFormat: i18nPrefs.timeFormat,
    });
  }, [i18nPrefs, userInfo]);

  useEffect(() => {
    return () => {
      const { i18nPrefs, userInfo } = ref.current ?? ({} as any);
      updateCallbackRef.current({ ...userInfo, i18nPrefs });
    };
  }, []);

  return (
    <I18nSettingsForm isMobile={isMobile}>
      <I18nSetting
        isMobile={isMobile}
        title={messages.account.dateFormatTitle}
        options={sugarDateFormats}
        value={dateFormat ?? savedI18nPrefs?.dateFormat}
        setValue={setDateFormat}
        description={messages.account.dateFormatDescription}
        disabled={isSaving}
      />
      <I18nSetting
        isMobile={isMobile}
        title={messages.account.timeFormatTitle}
        options={sugarTimeFormats}
        value={timeFormat ?? savedI18nPrefs?.timeFormat}
        setValue={setTimeFormat}
        description={messages.account.timeFormatDescription}
        disabled={isSaving}
      />
      <I18nSetting
        isMobile={isMobile}
        title={messages.account.numGroupSeparatorTitle}
        value={numGroupSeparator ?? savedI18nPrefs?.numGroupSeparator}
        setValue={setNumGroupSeparator}
        description={messages.account.numGroupSeparatorDescription}
        disabled={isSaving}
      />
      <I18nSetting
        isMobile={isMobile}
        title={messages.account.decimalTitle}
        value={decimal ?? savedI18nPrefs?.decimal}
        setValue={setDecimal}
        description={messages.account.decimalDescription}
        disabled={isSaving}
      />
    </I18nSettingsForm>
  );
}
