import { Component, Context, createContext } from 'react';
import _, { isEqual, join, values } from 'lodash';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import { ChartToolTip } from '../chart-tooltip';
import ChartUtils from '../ChartUtils';
import BarChartUtils from '../BarChartUtils';
import BarLineChartUtils from '../BarLineChartUtils';
import * as ReactDOM from 'react-dom';
import { ChartSpecs } from '../../ChartSpecs';
import { Viz } from '../../VizUtil';
import Discover from '../../../common/redux/actions/DiscoverActions';
import {
  AXIS_FORMATTER,
  DATA_FORMATTER,
  HANDLE_NULL_AS,
} from '../../../common/Constants';
import { LABEL_ORIENTATION, METRIC_ORIENTATION } from '../../../common/d3/Axis';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { AxisGrid } from './axis-grid.component';
import { BasePlotType } from './base-cartesian-chart.types';
import { HorizontalAxis } from './horizontal-axis.component';
import { PrimaryVerticalAxis } from './primary-vertical-axis.component';
import {
  ScrollableContainer,
  ScrollContext,
} from './scrollable-container.component';
import { SecondaryVerticalAxis } from './secondary-vertical-axis.component';
import ReactResizeDetector from 'react-resize-detector';
import { ViewportDimensionsInjector } from '../../../common/utilities/dimensions.hook';
import { ILegendDatum } from '../viz-legend';
import { isDashletUser as isDashletUserSelector } from '../../../common/redux/selectors/AccountSelectors';
import {
  labelManagerPrimaryPlotClass,
  labelManagerSecondaryPlotClass,
} from '../../../common/d3/label-manager-provider/label-manager-provider.utils';
import { IInternationalizationPreferences } from '../../../account/interfaces';
import { IStateToProps } from './base-cartesian-chart.interfaces';
import { throttle } from 'throttle-debounce';
import { doesPointDataHaveMissingValue } from './base-cartesian-chart.util';

export class BaseCartesianChart extends Component<any, any> {
  yAxisShelves;
  showAxesToolTipPrimary;
  hideAxesTooltipPrimary;
  props;
  state;

  static defaultProps = {
    i18nPrefs: {},
  };
  hideAxesToolTipPrimary: any;
  showAxesToolTipSecondary: any;
  hideAxesToolTipSecondary: any;
  showAxesToolTipBottom: any;
  hideAxesToolTipBottom: any;
  chartWidth: any;
  chartHeight: any;
  lastEvent: boolean;
  chartCanvas: any;
  primaryPlot: BasePlotType;
  secondaryPlot: BasePlotType;
  mouseOverTooltip: any;
  offscreenWidth: number;
  offscreenHeight: number;
  axisGridWidth: number;
  axisGridHeight: number;
  preventVerticalScrolling: any;
  primaryPlotComponent: any;
  primaryTargetsPlotComponent: any;
  secondaryPlotComponent: any;
  chartPlotGroup: SVGGElement;
  clearTooltipTimeout: any;
  xAxis: Component<any, any, any>;
  alreadyMeasured;
  scrollContext: Context<{
    scrollPctLeft: number;
    scrollPctTop: number;
  }>;
  private setFocusedDataPointsThrottled: any;

  static mapDispatchToProps: (
    _dispatch: any,
    _ownProps: any,
  ) => {
    setVizLegendData: (
      _id: any,
      _legendData: ILegendDatum[],
      _legendPalette: any,
    ) => void;
    setGlobalTooltipMode: (_on: any) => void;
    setScrollPos: (_id: any, _pctLeft: any, _pctTop: any) => void;
    setFocusedData(_dataItem: any): void;
    setFocusedDataPoints(
      _pointData: any,
      _lineData: any,
      _collectDetailInfoArgs: any,
    ): void;
    openReportLink(_reportDetailInfo: any): void;
  };

  constructor(props, yAxisShelves) {
    super(props);
    this.yAxisShelves = yAxisShelves;
    this.state = {
      scrollPct: 0,
      numTicks: props.isMobile ? 5 : 7,
      yAxesLabelWidth: 0,
      showXAxisToolTip: false,
      mouseOverChart: false,
      focusedChart: null,
      scrollPctLeft: 0,
      scrollPctTop: 0,
    };

    this.setFocusedDataPointsThrottled = throttle(
      100,
      true,
      (pointData, lineData, collectDetailInfoArgs) => {
        this.props.setFocusedDataPoints(
          pointData,
          lineData,
          collectDetailInfoArgs,
        );
      },
    );

    this.scrollContext = createContext({
      scrollPctLeft: this.state.scrollPctLeft,
      scrollPctTop: this.state.scrollPctTop,
    });

    this.showAxesToolTipPrimary = this.showAxesToolTip.bind(this, 'Primary');
    this.hideAxesToolTipPrimary = this.hideAxesToolTip.bind(this, 'Primary');
    this.showAxesToolTipSecondary = this.showAxesToolTip.bind(
      this,
      'Secondary',
    );
    this.hideAxesToolTipSecondary = this.hideAxesToolTip.bind(
      this,
      'Secondary',
    );
    this.showAxesToolTipBottom = this.showAxesToolTip.bind(this, 'Bottom');
    this.hideAxesToolTipBottom = this.hideAxesToolTip.bind(this, 'Bottom');
    this.handleTooltipPosition = this.handleTooltipPosition.bind(this);
    this.setMouseOverChart = this.setMouseOverChart.bind(this);
    this.setToolTipData = this.setToolTipData.bind(this);
  }

  getI18nPrefsMatcher(i18nPrefs: IInternationalizationPreferences) {
    return join(values(i18nPrefs), ',');
  }

  componentDidUpdate(prevProps) {
    // Compute initial dimensions
    this.setChartWidth(this.getChartWidth());
    this.setChartHeight(this.getChartHeight());

    this.captureOffscreenSize();

    // BaseCartesian creates new plot components every render, so we need band-aid logic here
    if (this.props.width !== prevProps.width) {
      this.forceUpdate();
    }

    if (
      !isEqual(
        this.getI18nPrefsMatcher(prevProps.i18nPrefs),
        this.getI18nPrefsMatcher(this.props.i18nPrefs),
      )
    ) {
      this.forceUpdate();
    }

    if (
      (!_.isEqual(
        prevProps.viz.options.querySort,
        this.props.viz.options.querySort,
      ) ||
        !_.isEqual(
          prevProps.queryResults?.executeQuery,
          this.props.queryResults?.executeQuery,
        )) &&
      !this.props.disableLegend
    ) {
      this.updateLegend();
    }

    if (
      prevProps.showGlobalTooltip !== this.props.showGlobalTooltip &&
      this.lastEvent
    ) {
      // Control was just pressed. Re-dispatch the last mouse event so the tooltip data is properly formatted
      this.chartCanvas.dispatchEvent(this.lastEvent);
    }

    if (!_.isEmpty(this.props.focusedData)) {
      this.focusChart();
    }

    if (
      !_.isEqual(this.props.focusedDataPoints, prevProps.focusedDataPoints) &&
      _.isFunction(this.primaryPlot?.showAnchoredTooltip)
    ) {
      if (
        this.props.useDataPointSelectionMode &&
        this.props.dataPointSelectionMode
      ) {
        // show tooltip at chart-defined location
        this.primaryPlot.showAnchoredTooltip();
      } else if (
        this.props.useDataPointSelectionMode &&
        !_.isNil(currentEvent)
      ) {
        // show tooltip at mouse location
        const canvas = d3.select(this.chartCanvas);
        const width = this.chartWidth;
        const height = this.chartHeight;
        if (this.primaryPlot) {
          try {
            const [xRelToContainer, yRelToContainer] = (d3 as any).mouse(
              canvas.select('.primaryPlot').node(),
            );
            this.primaryPlot.globalMouseMove(
              xRelToContainer,
              yRelToContainer,
              width,
              height,
            );
          } catch {
            // do not do anything if mouse data cannot be accessed
            return;
          }
        }
      }
    }
  }

  onWindowResize = () => {
    this.alreadyMeasured = false;
  };

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.captureOffscreenSize();

    if (!this.props.disableScrolling && !this.props.disableTooltips) {
      this.addCanvasMouseListener();
    }

    if (!this.props.disableLegend) {
      this.updateLegend();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  focusChart() {
    switch (this.state.focusedChart) {
      case 'primaryPlot':
        if (
          this.secondaryPlot &&
          _.isFunction(this.secondaryPlot.hasFocusedData)
        ) {
          if (this.secondaryPlot.hasFocusedData()) {
            // let secondary plot take precedence if both primary and secondary have been selected
            this.setState({ focusedChart: 'secondaryPlot' });
            return;
          }
        }
        break;
      case 'secondaryPlot':
        if (_.isFunction(this.secondaryPlot.hasFocusedData)) {
          if (!this.secondaryPlot.hasFocusedData()) {
            // restore focus to primary plot if no data is selected
            this.setState({ focusedChart: 'primaryPlot' });
            return;
          }
        }
        break;
      default:
        break;
    }
  }

  setMouseOverChart = over => {
    if (this.state.mouseOverChart !== over) {
      this.setState({ ...this.state, mouseOverChart: over });
    }
  };

  setMouseNotOverChart = _.partial(this.setMouseOverChart, false);

  addCanvasMouseListener() {
    const canvas = d3.select(this.chartCanvas);
    const width = this.chartWidth;
    const height = this.chartHeight;
    canvas
      .on('mousemove', () => {
        // Cache last mouse event in case we need to re-dispatch it later
        this.lastEvent = (d3 as any).event;

        if (!this.props.showGlobalTooltip) {
          if (!this.mouseOverTooltip) {
            this.onHover(null, 0, 0);
          }
        } else {
          if (this.primaryPlot) {
            try {
              const [xRelToContainer, yRelToContainer] = (d3 as any).mouse(
                canvas.select('.primaryPlot').node(),
              );
              this.primaryPlot.globalMouseMove(
                xRelToContainer,
                yRelToContainer,
                width,
                height,
              );
            } catch {
              // do not do anything if mouse data cannot be accessed
            }
          }

          if (this.secondaryPlot) {
            try {
              const [xRelToContainer, yRelToContainer] = (d3 as any).mouse(
                canvas.select('.secondaryPlot').node(),
              );
              this.secondaryPlot.globalMouseMove(
                xRelToContainer,
                yRelToContainer,
                width,
                height,
              );
            } catch {
              // do not do anything if mouse data cannot be accessed
            }
          }
        }
      })
      .on('click', () => {
        this.setState({ focusedChart: null });

        if (
          this.props.useDataPointSelectionMode &&
          _.isFunction(this.primaryPlot.getChartClickPointData) &&
          _.isFunction(this.primaryPlot.getReportDetailArgs)
        ) {
          const {
            pointData = [],
            lineData = [],
          } = this.primaryPlot.getChartClickPointData(this.state.tooltipData);

          const shouldSetFocusedDataPoints = !doesPointDataHaveMissingValue({
            focusedData: this.props.focusedData,
            pointData,
          });

          if (shouldSetFocusedDataPoints) {
            const collectDetailInfoArgs = this.primaryPlot.getReportDetailArgs(
              lineData,
              pointData,
            );

            this.setFocusedDataPointsThrottled(
              pointData,
              lineData,
              collectDetailInfoArgs,
            );
          }
        } else {
          this.props.setFocusedData(null);
        }
      });
  }

  captureOffscreenSize() {
    if (ChartSpecs[this.props.viz.chartType].canScroll === false) {
      this.offscreenWidth = 0;
      this.offscreenHeight = 0;
      return;
    }
    const { axisGridWidth: width = 0, axisGridHeight: height = 0 } = this;
    this.offscreenWidth = Math.max(width - this.getChartWidth(), 0);
    this.offscreenHeight = Math.max(height - this.getChartHeight(), 0);
  }

  getLegendData(): ILegendDatum[] {
    const legendData = this.props.barData.reduce((accum, d) => {
      accum.add(BarChartUtils.getX(d, this.props.viz.layout));
      return accum;
    }, new Set());
    return [...legendData];
  }

  updateLegend() {
    const { isComboChart } = this.props;
    const legendData: ILegendDatum[] = [
      this.primaryPlot,
      this.secondaryPlot,
    ].reduce((accum, plot, idx) => {
      if (plot) {
        const title = plot.getLegendTitle(isComboChart);
        if (!_.isEmpty(title)) {
          accum.push({ title });
        }
        plot.getLegendData().forEach(d =>
          accum.push({
            ...d,
            focus: () =>
              this.setState({
                focusedChart: idx === 0 ? 'primaryPlot' : 'secondaryPlot',
              }),
          }),
        );
      }
      return accum;
    }, []);

    this.props.setVizLegendData(this.props.vizId, [...legendData]);
  }

  primaryYAxisEnabled() {
    return this.props.viz.layout[this.yAxisShelves[0].shelf].length > 0;
  }

  secondaryYAxisEnabled() {
    return (
      this.yAxisShelves.length > 1 &&
      this.props.viz.layout[this.yAxisShelves[1].shelf].length > 0
    );
  }

  alignYAxesAtZero() {
    return (
      this.primaryYAxisEnabled() &&
      this.secondaryYAxisEnabled() &&
      this.props.alignYAxesAtZero
    );
  }

  secondaryUsesPrimaryScale() {
    return (
      this.primaryYAxisEnabled() &&
      this.secondaryYAxisEnabled() &&
      this.props.secondaryUsesPrimaryScale
    );
  }

  setChartHeight(value) {
    this.chartHeight = Math.max(value, 0);
  }

  setChartWidth(value) {
    this.chartWidth = Math.max(value, 0);
  }

  getChartWidth() {
    const { hideAxis, mainChartPadding, yAxisWidth } = this.props;
    const { width } = this.props;
    const calculatedWidth = hideAxis
      ? width
      : width -
        yAxisWidth *
          (this.primaryYAxisEnabled() && this.secondaryYAxisEnabled() ? 2 : 1) -
        2 * mainChartPadding;

    return Math.max(calculatedWidth, 0);
  }

  getChartHeight() {
    const { height, hideAxis, mainChartPadding, xAxisHeight } = this.props;
    const calculatedHeight = hideAxis
      ? height
      : height - xAxisHeight - 2 * mainChartPadding;

    return Math.max(calculatedHeight, 0);
  }

  getDashletVerticalAdjustment() {
    return this.props.isDashletMode ? 20 : 0;
  }

  hoverFunction = (data, type, x, y) => {
    if (
      this.props.useDataPointSelectionMode &&
      this.props.dataPointSelectionMode
    ) {
      this.handleTooltipPosition(data, type, x, y);
    } else {
      this.onHover(data, type, x, y);
    }
  };

  render() {
    let {
      hideAxis,
      mainChartPadding,
      yAxisWidth,
      xAxisHeight,
      viz,
      advancedProps: presetPadding,
      isMobile,
      dataFormatters,
      customFormatToggles,
      linkToReport,
      enableReportLink,
      i18nPrefs,
    } = this.props;
    this.setChartWidth(this.getChartWidth());
    this.setChartHeight(this.getChartHeight());
    this.alreadyMeasured = false;
    let scrollingHorizontally = false;
    let scrollingVertically = false;
    const showDataLabels = JSON.parse(
      _.get(viz, 'options.showDataLabels', 'true'),
    );
    const nullHandling = _.get(
      viz,
      'options.nullHandling',
      HANDLE_NULL_AS.MISSING,
    );

    const supportsSummary = ChartSpecs[viz.chartType]
      ? ChartSpecs[viz.chartType].supportsSummary
      : false;
    const summaryOrientation = ChartSpecs[viz.chartType]
      ? ChartSpecs[viz.chartType].summaryOrientation
      : 'top';

    this.primaryPlot = new this.yAxisShelves[0].Plot({
      vizId: this.props.vizId,
      viz: this.props.viz,
      valuesName: this.yAxisShelves[0].shelf,
      xAxisName: 'XAXIS',
      primaryPlot: null,
      data: this.props[this.yAxisShelves[0].dataId], // the key for this should not be dynamically set
      querySort: this.props.queryResults.executeQuery.querySort,
      queryResults: this.props.queryResults,
      legendData: this.props.legendData,
      layout: viz.layout,
      width: this.chartWidth,
      height: this.chartHeight,
      showDataLabels,
      paletteOffset: 0,
      chartPadding: mainChartPadding,
      presetPadding,
      xScale: null,
      defaultXAxisHeight: this.props.xAxisHeight,
      defaultYAxisWidth: yAxisWidth,
      hoverFunction: (data, type, x, y) => this.hoverFunction(data, type, x, y),
      isMobile,
      xAxisPadding: this.props.xAxisPadding,
      yAxisPadding: this.props.yAxisPadding,
      hideAxis: this.props.hideAxis,
      labelRotation: LABEL_ORIENTATION.ANGLED,
      ...this.props.primaryPlotProps,
      customFormatToggles,
      disableTooltips: this.props.disableTooltips,
      enableReportLink,
      linkToReport,
      nullHandling,
      focusedData: this.props.focusedData ?? [],
      focusedDataPoints: this.props.focusedDataPoints ?? [],
      onFocusedDataPointUpdate: focusedDataPoints =>
        this.setFocusedDataPointsThrottled(focusedDataPoints),
      useDataPointSelectionMode: this.props.useDataPointSelectionMode,
      dataPointSelectionMode: this.props.dataPointSelectionMode,
      i18nPrefs,
      customFormatProps: Viz.getDataCustomFormatters(this.props.viz),
    });

    const hasChartSummary =
      supportsSummary && _.isFunction(this.primaryPlot.getChartSummary);
    if (hasChartSummary) {
      // Adjust chart height to fit available chart summary contents
      this.setChartHeight(
        this.chartHeight - this.primaryPlot.getChartSummaryHeight(),
      );
    }

    // Primary plot determines final width
    const adjustedWidth = this.primaryPlot.getPreferredWidth();
    if (adjustedWidth !== this.chartWidth) {
      if (adjustedWidth > this.chartWidth) {
        scrollingHorizontally = true;
      } else if (adjustedWidth >= 0 && adjustedWidth < this.chartWidth) {
        // the axis width is bigger than default, reset the chartWidth to account for that
        this.setChartWidth(adjustedWidth);
      }
      if (_.isFunction(this.primaryPlot?.setWidth)) {
        this.primaryPlot.setWidth(adjustedWidth);
      }
    }

    // Compute XAxis size. labels going vertical affect size as well
    const adjustedXAxisHeight = this.primaryPlot.getXAxisHeight(xAxisHeight);
    const adjustedHeight = this.primaryPlot.getPreferredHeight();
    if (adjustedHeight !== this.chartHeight) {
      if (adjustedHeight > this.chartHeight) {
        scrollingVertically = true;
      } else if (adjustedHeight >= 0 && adjustedHeight < this.chartHeight) {
        this.setChartHeight(adjustedHeight);
      }
      if (_.isFunction(this.primaryPlot?.setHeight)) {
        this.primaryPlot.setHeight(adjustedHeight);
      }
    }
    if (adjustedXAxisHeight !== xAxisHeight) {
      xAxisHeight = adjustedXAxisHeight;
    }

    if (this.preventVerticalScrolling) {
      scrollingVertically = false;
    }

    // With dimensions reconciled create secondary plot
    if (this.secondaryYAxisEnabled()) {
      this.secondaryPlot = new this.yAxisShelves[1].Plot({
        vizId: this.props.vizId,
        viz: this.props.viz,
        valuesName: this.yAxisShelves[1].shelf,
        xAxisName: 'XAXIS',
        primaryPlot: this.primaryPlot,
        data: this.props[this.yAxisShelves[1].dataId], // the key for this should not be dynamically set
        querySort: this.props.queryResults.executeQuery.querySort,
        layout: viz.layout,
        width: adjustedWidth,
        height: this.chartHeight,
        showDataLabels,
        paletteOffset: this.primaryPlot
          ? viz.layout[this.yAxisShelves[0].shelf].length
          : 0,
        chartPadding: mainChartPadding,
        presetPadding,
        xScale: this.primaryPlot.getXScale(),
        defaultXAxisHeight: this.props.xAxisHeight,
        defaultYAxisWidth: this.props.yAxisWidth,
        xAxisPadding: this.props.xAxisPadding,
        yAxisPadding: this.props.yAxisPadding,
        hoverFunction: (data, type, x, y) =>
          this.hoverFunction(data, type, x, y),
        isMobile,
        hideAxis: this.props.hideAxis,
        ...this.props.secondaryPlotProps,
        customFormatToggles,
        disableTooltips: this.props.disableTooltips,
        enableReportLink,
        linkToReport,
        nullHandling,
        focusedData: this.props.focusedData,
        focusedDataPoints: this.props.focusedDataPoints ?? [],
        useDataPointSelectionMode: false,
        dataPointSelectionMode: false,
        i18nPrefs,
        customFormatProps: Viz.getDataCustomFormatters(this.props.viz),
        isSecondaryPlot: true,
      });
    }

    const offsetForLeftAxis =
      this.primaryYAxisEnabled() && !hideAxis
        ? this.primaryPlot.getYAxisWidth()
        : 0;

    const adjustedYRange =
      this.chartHeight - this.getDashletVerticalAdjustment();

    // Determine if y axes should be aligned at 0
    if (this.alignYAxesAtZero()) {
      const zeroAlignedScales = BarLineChartUtils.getZeroAlignedScales(
        this.primaryPlot.getYDomain(),
        this.secondaryPlot.getYDomain(),
        adjustedYRange,
        this.state.numTicks,
        this.props.collapsedAxisRange,
        this.props.fitAllData,
      );
      // Set adjusted scales aligned at 0
      this.primaryPlot.setYScale(zeroAlignedScales.barYScale);
      this.secondaryPlot.setYScale(
        this.secondaryUsesPrimaryScale()
          ? zeroAlignedScales.barYScale
          : zeroAlignedScales.lineYScale,
      );
    }

    const primaryPlotComponent = this.primaryPlot
      ? this.primaryPlot.getComponent({
          plotRef: plot => {
            this.primaryPlotComponent = plot;
          },
          showLabels: showDataLabels,
          getX: (d, layout) => ChartUtils.getX(d, layout),
          getX0: d => ChartUtils.getX0(d),
          getY: d => ChartUtils.getY(d),
          getY0: d => ChartUtils.getY(d),
          focus: () => {
            this.setState({ focusedChart: 'primaryPlot' });
          },
          showGlobalTooltip: this.props.showGlobalTooltip,
          className: `primaryPlot ${this.props?.primaryPlotProps?.className ??
            ''}`,
          dataFormatters,
          focusedDataPoints: this.props.focusedDataPoints ?? [],
          useDataPointSelectionMode: this.props.useDataPointSelectionMode,
          dataPointSelectionMode: this.props.dataPointSelectionMode,
        })
      : null;

    const secondaryPlotComponent = this.secondaryPlot
      ? this.secondaryPlot.getComponent({
          showLabels: showDataLabels,
          plotRef: plot => {
            this.secondaryPlotComponent = plot;
          },
          getX: (d, layout) => ChartUtils.getX(d, layout),
          getX0: d => ChartUtils.getX0(d),
          getY: d => ChartUtils.getY(d),
          getY0: d => ChartUtils.getY(d),
          focus: () => {
            this.setState({ focusedChart: 'secondaryPlot' });
          },
          showGlobalTooltip: this.props.showGlobalTooltip,
          className: `secondaryPlot ${
            this.props.secondaryPlotProps
              ? this.props.secondaryPlotProps.className || ''
              : ''
          }`,
          dataFormatters,
        })
      : null;

    const isVertical =
      this.primaryPlot.getMetricOrientation() === METRIC_ORIENTATION.VERTICAL;
    const ChartGrid = (
      <ScrollableContainer>
        <ReactResizeDetector handleHeight>
          {({ height }) => (
            <ViewportDimensionsInjector>
              {({ width }) => {
                if (
                  this.axisGridWidth !== width ||
                  this.axisGridHeight !== height
                ) {
                  this.alreadyMeasured = false;
                  this.axisGridWidth = width;
                  this.axisGridHeight = height;
                }
                return (
                  <AxisGrid
                    primaryPlotGetMetricOrientation={() =>
                      this?.primaryPlot?.getMetricOrientation()
                    }
                    primaryPlotGetYScale={() => this?.primaryPlot?.getYScale()}
                    secondaryPlotGetYScale={() =>
                      this?.secondaryPlot?.getYScale()
                    }
                    primaryPlotGetXScale={() => this?.primaryPlot?.getXScale()}
                    numTicks={this?.state?.numTicks}
                    isMobile={this?.props?.isMobile}
                    primaryYAxisEnabled={() => this?.primaryYAxisEnabled()}
                    secondaryYAxisEnabled={() => this?.secondaryYAxisEnabled()}
                    chartWidth={isVertical ? adjustedWidth : -adjustedHeight}
                  />
                );
              }}
            </ViewportDimensionsInjector>
          )}
        </ReactResizeDetector>
      </ScrollableContainer>
    );

    const horizontalAxis = this.renderHorizontalAxis(
      this.chartHeight,
      this.chartWidth,
      xAxisHeight,
      offsetForLeftAxis,
      scrollingHorizontally,
      isMobile,
      adjustedYRange,
    );

    const hideGrid = this.props.hideGrid || this.primaryPlot.hideGrid;
    const svgHeight = hasChartSummary
      ? `calc(${
          this.props.height
        }px - ${this.primaryPlot.getChartSummaryHeight()}px)`
      : this.props.height;

    const offsetForTopAxis =
      _.get(this, 'props.spec.xAxisOrient', 'bottom') === 'top'
        ? xAxisHeight
        : 0;

    const clipPathVerticalAdjustment = this.getDashletVerticalAdjustment();

    const {
      offscreenWidth,
      offscreenHeight,
    } = this.getOffscreenDimensionsAsync();

    return (
      <div className={'viz-chart-wrapper'}>
        <ScrollContext.Provider
          value={{
            leftPct: this.state.scrollPctLeft ?? 0,
            topPct: this.state.scrollPctTop ?? 0,
            offscreenWidth,
            offscreenHeight,
          }}
        >
          {hasChartSummary &&
            summaryOrientation === 'top' &&
            this.primaryPlot.getChartSummary()}
          <svg
            height={svgHeight}
            width={this.props.width}
            className={`viz-chart ${_.kebabCase(this.props.viz.chartType)}`}
            onMouseLeave={() => this.setMouseOverChart(false)}
          >
            <g
              id={`${this.props.vizId}-mainContainer`}
              transform={`translate(${mainChartPadding}, ${mainChartPadding})`}
            >
              {!this.props.hideAxis &&
                this.renderVerticalAxes(
                  this.chartHeight,
                  this.chartWidth,
                  offsetForLeftAxis,
                  this.primaryPlot.getYAxisWidth(),
                  mainChartPadding,
                  scrollingVertically,
                  customFormatToggles,
                  offsetForTopAxis,
                )}
              {!this.props.hideAxis && horizontalAxis}
              <g
                transform={`translate(${offsetForLeftAxis}, ${offsetForTopAxis})`}
                ref={chart => {
                  this.chartCanvas = chart;
                }}
                className='captureMouseEvents'
                onMouseMove={() => this.setMouseOverChart(true)}
              >
                <clipPath
                  id={`${this.props.vizId}-clip`}
                  transform={`translate(0, -${clipPathVerticalAdjustment})`}
                >
                  <rect
                    width={Math.ceil(this.chartWidth) + 1}
                    height={Math.ceil(
                      this.chartHeight +
                        (this.secondaryPlot ? 0 : clipPathVerticalAdjustment),
                    )}
                  />
                </clipPath>
                <g
                  className='clipped-chart-body measure-container'
                  style={{
                    clipPath: `url(#${this.props.vizId}-clip)`,
                    pointerEvents: 'all',
                  }}
                >
                  {/* allows us to get mouse events */}
                  <rect
                    id='chartArea'
                    style={{ visibility: 'hidden' }}
                    width={Math.ceil(this.chartWidth)}
                    height={Math.ceil(this.chartHeight)}
                  />
                  {!hideGrid && ChartGrid}
                  {/*Global mouse vertical dotted line*/}
                  {this.props.spec.showTooltipIndicator &&
                    this.props.showGlobalTooltip &&
                    this.state.mouseOverChart &&
                    this.state.tooltipData && (
                      <line
                        className={`line-chart-tooltip-indicator`}
                        x1={this.state.tooltipData.hoverX}
                        x2={this.state.tooltipData.hoverX}
                        y2={this.chartHeight}
                      />
                    )}
                  {this.props.dataPointSelectionMode && (
                    <line
                      className={`line-chart-tooltip-indicator bold`}
                      x1={(_.head(this.props.focusedDataPoints) as any)?.posX}
                      x2={(_.head(this.props.focusedDataPoints) as any)?.posX}
                      y2={this.chartHeight}
                    />
                  )}
                  <g
                    ref={group => {
                      this.chartPlotGroup = group;
                    }}
                  >
                    {this.primaryPlot && (
                      <g className={labelManagerPrimaryPlotClass}>
                        <ScrollableContainer>
                          {primaryPlotComponent}
                        </ScrollableContainer>
                      </g>
                    )}

                    {this.secondaryPlot && (
                      <g className={labelManagerSecondaryPlotClass}>
                        <ScrollableContainer>
                          {secondaryPlotComponent}
                        </ScrollableContainer>
                      </g>
                    )}
                  </g>
                </g>
                <ChartToolTip
                  tooltipData={this.state.tooltipData}
                  height={this.chartHeight}
                  width={this.chartWidth}
                  vizId={this.props.vizId}
                  showGlobalTooltip={this.props.showGlobalTooltip}
                  focusedData={this.props.focusedData}
                  mouseOverChart={this.state.mouseOverChart}
                  mouseOverTooltip={over => {
                    this.mouseOverTooltip = over;
                    if (this.clearTooltipTimeout) {
                      clearTimeout(this.clearTooltipTimeout);
                      this.clearTooltipTimeout = null;
                    }
                  }}
                  linkToReport={linkToReport}
                  enableReportLink={
                    this.props.dataPointSelectionMode && enableReportLink
                  }
                  openReportLink={() =>
                    this.props.openReportLink(this.props.reportDetailInfo)
                  }
                />
              </g>
            </g>
          </svg>
          {hasChartSummary &&
            summaryOrientation === 'bottom' &&
            this.primaryPlot.getChartSummary()}
        </ScrollContext.Provider>
      </div>
    );
  }

  renderHorizontalAxis(
    chartHeight,
    chartWidth,
    xAxisHeight,
    yAxisWidth,
    scrolling,
    isMobile,
    adjustedYRange,
  ) {
    let textOrientation = LABEL_ORIENTATION.HORIZONTAL;
    if (this.primaryPlot.shouldRotateAxisLabels) {
      textOrientation =
        this.primaryPlot.labelRotation || LABEL_ORIENTATION.ANGLED;
    }
    const isVertical =
      this.primaryPlot.getMetricOrientation() === METRIC_ORIENTATION.VERTICAL;
    const xTicks = isVertical
      ? this.primaryPlot.getDistinctGroups()
      : this.state.numTicks;

    // the vertical formatter is really the values formatter, use it if the chart orientation is horizontal
    const primaryFormatter = this.getAxisFormatter('horizontal', false);

    let tickFormat = primaryFormatter.formatSmall;
    // only truncate vertical text, horizontal text is managed via wrapTextWidth
    if (isVertical && this.primaryPlot.shouldRotateAxisLabels) {
      tickFormat = d => {
        return _.truncate(d, { length: this.primaryPlot.maxChars });
      };
    }

    let adjustedYAxisWidth = yAxisWidth;
    if (_.isFunction(this?.primaryPlot?.getYAxisWidthAdjustment)) {
      adjustedYAxisWidth += this?.primaryPlot?.getYAxisWidthAdjustment();
    }

    const hideAxisTickLabels = _.get(
      this,
      'props.spec.hideXAxisTickLabels',
      false,
    );
    const orientation = _.get(this, 'props.spec.xAxisOrient', 'bottom');
    const includesLineMetric =
      (this.props.viz?.layout?.LINES ?? [])?.length > 0;
    const translateY =
      ['column_line', 'stack_line'].includes(this.props.viz.chartType) &&
      includesLineMetric
        ? adjustedYRange
        : chartHeight;
    const translate =
      orientation === 'top'
        ? `translate(${adjustedYAxisWidth}, 0)`
        : `translate(${yAxisWidth}, ${translateY})`;
    const wrapTextWidth = this.primaryPlot.shouldRotateAxisLabels
      ? -1
      : this.primaryPlot.getStepSize() - 12;
    let xLabelXLoc =
      wrapTextWidth > 0 && !scrolling ? xAxisHeight - 10 : xAxisHeight - 20;

    if (
      isMobile &&
      !this.props.isDashletMode &&
      !this.primaryPlot.shouldRotateAxisLabels
    ) {
      xLabelXLoc -= 8;
    }
    if (orientation === 'top') {
      xLabelXLoc = this.props.mainChartPadding;
    }
    const domain = this.primaryPlot.getYScale().domain();
    // if either side of domain is negative, mute axis line
    return (
      <HorizontalAxis
        querySort={this.props.queryResults?.executeQuery?.querySort}
        queryId={this.props.viz.queryId}
        mutedLine={domain[0] < 0 || domain[1] < 0}
        scale={this.primaryPlot.getXScale()}
        ticks={xTicks}
        height={xAxisHeight}
        scrolling={scrolling}
        orient={orientation}
        getOffscreenDimensionsAsync={this.getOffscreenDimensionsAsync}
        onScroll={this.onScroll}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        xLabelXLoc={xLabelXLoc}
        hideAxisTickLabels={hideAxisTickLabels}
        showAxesToolTipBottom={this.showAxesToolTipBottom}
        hideAxesToolTipBottom={this.hideAxesToolTipBottom}
        onMouseOver={this.setMouseNotOverChart}
        xAxisLabel={this.primaryPlot.getXAxisLabel()}
        showAxisToolTipBottom={this.state.showAxisToolTipBottom}
        isMobile={this.props.isMobile}
        textOrientation={textOrientation}
        wrapTextWidth={wrapTextWidth}
        transform={translate}
        tickSizeInner={isVertical ? (xTicks <= 1 ? 0 : 5) : 0}
        chartPadding={this.props.mainChartPadding}
        tickFormat={tickFormat}
        showAxisBaseline={isVertical}
        scrollPctLeft={this.state.scrollPctLeft ?? 0}
      />
    );
  }

  showAxesToolTip(position) {
    this.setState(() => {
      return {
        [`showAxisToolTip${position}`]: true,
      };
    });
  }

  hideAxesToolTip(position) {
    this.setState(() => {
      return {
        [`showAxisToolTip${position}`]: false,
      };
    });
  }

  getAxisFormatter(axisDirection = 'vertical', isSecondary = false) {
    let shelf;
    let formatField;
    let defaultFormatter;
    let formatter;
    let plot = this.primaryPlot;
    if (isSecondary && this.secondaryYAxisEnabled()) {
      plot = this.secondaryPlot;
    }
    const metricOrientation = plot.getMetricOrientation();
    if (axisDirection === 'vertical') {
      shelf =
        metricOrientation === METRIC_ORIENTATION.VERTICAL
          ? plot.valuesName
          : plot.xAxisName;
      if (shelf === plot.xAxisName) {
        // always formatted strings
        return DATA_FORMATTER.STRING;
      }
      if (_.isFunction(plot.showAsPercentage) && plot.showAsPercentage()) {
        return DATA_FORMATTER.WHOLE_PERCENT;
      }

      formatField = plot.layout[shelf][0];
      defaultFormatter =
        metricOrientation === METRIC_ORIENTATION.VERTICAL
          ? DATA_FORMATTER.NUMBER
          : DATA_FORMATTER.STRING;

      formatter =
        this.props.dataFormatters[formatField ? formatField.name : ''] ||
        defaultFormatter;
      if (isSecondary && formatter === DATA_FORMATTER.WHOLE_NUMBER) {
        //
        // If the secondary axis is supposed to be a whole number, we must force it to number.
        // The values from the primary axis almost certainly NOT line up with the whole numbers in the secondary axis.
        // This would cause the axis to show incorrect axis lines and mislead the user
        //
        formatter = AXIS_FORMATTER.NUMBER_AXIS;
      }
    } else {
      shelf =
        metricOrientation === METRIC_ORIENTATION.HORIZONTAL
          ? plot.valuesName
          : plot.xAxisName;
      if (shelf === plot.xAxisName) {
        // always formatted strings
        return DATA_FORMATTER.STRING;
      }
      if (_.isFunction(plot.showAsPercentage) && plot.showAsPercentage()) {
        return DATA_FORMATTER.WHOLE_PERCENT;
      }
      formatField = plot.layout[shelf][0];
      defaultFormatter =
        metricOrientation === METRIC_ORIENTATION.HORIZONTAL
          ? DATA_FORMATTER.NUMBER
          : DATA_FORMATTER.STRING;
      formatter =
        this.props.dataFormatters[formatField ? formatField.name : ''] ||
        defaultFormatter;
    }
    return formatter;
  }

  renderVerticalAxes(
    chartHeight,
    chartWidth,
    offsetForLeftAxis,
    yAxisWidth,
    chartPadding,
    scrolling,
    customFormatToggles,
    offsetForTopAxis,
  ) {
    const isVertical =
      this.primaryPlot.getMetricOrientation() === METRIC_ORIENTATION.VERTICAL;
    const yTicks = isVertical
      ? this.state.numTicks
      : this.primaryPlot.getDistinctGroups();
    const domain = isVertical ? [0, 0] : this.primaryPlot.getXScale().domain();
    let tickSizeInner = isVertical ? 0 : yTicks <= 1 ? 0 : 5;
    let showAxisBaseline = !isVertical;

    let axesLabelYPos = this.props.isMobile ? -70 : -100;
    if (this.props.spec.id === 'funnel') {
      // no ticks and no axis line on the funnel chart
      tickSizeInner = 0;
      showAxisBaseline = false;
    }
    if (_.includes(['funnel', 'stack_bar'], this.props.spec.id)) {
      axesLabelYPos = this.props.isMobile ? -yAxisWidth - 10 : -yAxisWidth;
    }

    const label = this.primaryPlot.getYAxisLabel();
    const primaryAxis = this.primaryYAxisEnabled() ? (
      <PrimaryVerticalAxis
        key='primary-vertical-axis'
        queryId={this.props.viz.queryId}
        querySort={this.props.queryResults?.executeQuery?.querySort}
        scrollPctTop={this.state.scrollPctTop ?? 0}
        primaryFormatter={this.getAxisFormatter('vertical', false)}
        axesLabelYPos={axesLabelYPos}
        label={label}
        customFormatProps={
          this.primaryPlot?.customFormatProps[label?.split('|')[0]?.trim()]
        }
        isMobile={this.props.isMobile}
        scale={this.primaryPlot.getYScale()}
        yTicks={yTicks}
        tickSizeInner={tickSizeInner}
        yAxisWidth={yAxisWidth}
        offsetForTopAxis={offsetForTopAxis}
        showAxisBaseline={showAxisBaseline}
        domain={domain}
        scrolling={scrolling}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        onMouseOver={this.setMouseNotOverChart}
        isVertical={isVertical}
        i18nPrefs={this.props.i18nPrefs}
        onScroll={this.onScroll}
        showAxesToolTipPrimary={this.showAxesToolTipPrimary}
        hideAxesToolTipPrimary={this.hideAxesToolTipPrimary}
        maxChars={this.primaryPlot.maxChars}
        showAxisToolTipPrimary={this.state.showAxisToolTipPrimary}
        getOffscreenDimensionsAsync={this.getOffscreenDimensionsAsync}
      />
    ) : (
      ''
    );
    const secondaryAxis = this.secondaryYAxisEnabled() ? (
      <SecondaryVerticalAxis
        queryId={this.props.viz.queryId}
        querySort={this.props.queryResults?.executeQuery?.querySort}
        key='secondary-vertical-axis'
        scrollPctTop={this.state.scrollPctTop}
        getOffscreenDimensionsAsync={this.getOffscreenDimensionsAsync}
        secondaryFormatter={this.getAxisFormatter('vertical', true)}
        plot={this.secondaryPlot}
        customFormatProps={
          this.primaryPlot?.customFormatProps[label?.split('|')[0]?.trim()]
        }
        offsetForLeftAxis={offsetForLeftAxis}
        onScroll={this.onScroll}
        scrolling={scrolling}
        numTicks={this.state.numTicks}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        showAxesToolTipSecondary={this.showAxesToolTipSecondary}
        hideAxesToolTipSecondary={this.hideAxesToolTipSecondary}
        showAxisToolTipSecondary={this.state.showAxisTooltipSecondary}
        isMobile={this.props.isMobile}
        i18nPrefs={this.props.i18nPrefs}
      />
    ) : (
      ''
    );
    return [primaryAxis, secondaryAxis];
  }

  onScroll = (scrollPctLeft, scrollPctTop = 0) => {
    scrollPctLeft = Math.min(Math.max(scrollPctLeft, 0), 1);
    scrollPctTop = Math.min(Math.max(scrollPctTop, 0), 1);
    this.setState({ scrollPctLeft, scrollPctTop });
  };

  getOffscreenDimensionsAsync = () => {
    if (this.alreadyMeasured) {
      const { offscreenWidth, offscreenHeight } = this;
      return { offscreenWidth, offscreenHeight };
    }
    const previousWidth = this.offscreenWidth;
    const previousHeight = this.offscreenHeight;
    this.captureOffscreenSize();
    const offscreenWidth = this.getOffscreenWidthOfChart(previousWidth);
    const offscreenHeight = this.getOffscreenHeightOfChart(previousHeight);
    return { offscreenWidth, offscreenHeight };
  };

  getOffscreenWidthOfChart = (previous?) => {
    if (!previous) {
      previous = this.offscreenWidth;
      this.captureOffscreenSize();
    }

    let scrollOffset = 0;
    if (this.primaryPlot.width > this.chartWidth) {
      scrollOffset = this.primaryPlot.width - this.chartWidth;
    }

    if (scrollOffset || (previous !== 0 && this.offscreenWidth !== previous)) {
      return Math.max(0, scrollOffset);
    } else if (this.chartWidth === this.primaryPlot.getPreferredWidth()) {
      return 0;
    } else {
      // console.warn('using previous')
      this.offscreenWidth = previous;
      return Math.max(0, this.offscreenWidth);
    }
  };

  getOffscreenHeightOfChart = (previous?) => {
    if (!previous) {
      previous = this.offscreenHeight;
      this.captureOffscreenSize();
    }
    let scrollOffset = 0;
    if (this.primaryPlot.height > this.chartHeight) {
      scrollOffset = this.primaryPlot.height - this.chartHeight;
    }

    if (scrollOffset || (previous !== 0 && this.offscreenHeight !== previous)) {
      return Math.max(0, scrollOffset);
    } else if (this.chartHeight === this.primaryPlot.getPreferredHeight()) {
      return 0;
    } else {
      // console.warn('using previous')
      this.offscreenHeight = previous;
      return Math.max(0, this.offscreenHeight);
    }
  };

  extractXY(attr) {
    const xy = /translate\((-?\d*\.?\d+?),\s(-?\d*\.?\d+?)\)/g;
    const match = xy.exec(attr);
    if (!_.isNil(match) && match.length === 3) {
      return {
        x: parseFloat(match[1]),
        y: parseFloat(match[2]),
      };
    }
    return { x: 0, y: 0 };
  }

  setToolTipData(id, tooltipData) {
    this.setState(() => {
      return {
        tooltipData,
      };
    });
  }

  handleTooltipPosition(data, type, posX, posY) {
    if (!data) {
      // clear
      if (!this.mouseOverTooltip && _.isNil(this.clearTooltipTimeout)) {
        this.clearTooltipTimeout = setTimeout(() => {
          this.setToolTipData(this.props.vizId, {
            hoverData: null,
            originPlot: null,
            dataFormatters: null,
            hoverX: 0,
            hoverY: 0,
          });
          this.clearTooltipTimeout = null;
        }, 200);
      }
      return;
    }

    if (this.clearTooltipTimeout) {
      clearTimeout(this.clearTooltipTimeout);
      this.clearTooltipTimeout = null;
    }
    const originPlot = [this.primaryPlot, this.secondaryPlot].find(
      s => s && s.getType() === type,
    );

    this.setToolTipData(this.props.vizId, {
      hoverData: data,
      originPlot,
      dataFormatters: this.props.dataFormatters,
      hoverX: posX,
      hoverY: posY,
      useFiscalCalendar: this.props.useFiscalCalendar,
    });

    // Make sure we show
    this.setMouseOverChart(true);
  }

  // this hover function assumes x and y are deltas from the mouse pointer, not position
  onHover(data, type, deltaX, deltaY?) {
    let x = deltaX;
    let y = deltaY;

    let coords = {
      x: 0,
      y: 0,
    };
    if (!_.isEmpty(this.primaryPlotComponent)) {
      const domNode = ReactDOM.findDOMNode(this.primaryPlotComponent)
        ?.parentElement;
      const d3Elem = d3.select(domNode);
      const attr = d3Elem.attr('transform');
      // Adjust X & Y for scrolling
      coords = this.extractXY(attr);
    }

    x += coords.x;
    y += coords.y;

    this.handleTooltipPosition(data, type, x, y);
  }
}

(BaseCartesianChart as any).mapStateToProps = (state, props): IStateToProps => {
  const { isMobile } = state.main;
  let formatters;
  let customFormatProps;
  let { enableReportLink, viz, spec } = props;
  const isDashletUser = isDashletUserSelector(state?.account);
  if (viz?.layout) {
    formatters = Viz.getDataFormatters(viz);
    customFormatProps = Viz.getDataCustomFormatters(viz);
  }
  const isDashletMode = !!state?.dashlet?.isDashletMode;
  if (isDashletMode && isDashletUser) {
    enableReportLink = false;
  }

  const useFiscalCalendar =
    (VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting as any)(state, props) &&
    (VIZ_SELECTORS.getActiveVizFiscalSetting as any)(state, props) === 'true';

  const { i18nPrefs = {} } = state?.account?.currentUser;

  const focusedData = (VIZ_SELECTORS.focusedData as any)(state, {} as any);
  const focusedDataPoints = (VIZ_SELECTORS.focusedDataPoints as any)(
    state,
    {} as any,
  );

  const { reportDetailInfo = {} } = state.discover;

  const isLineChart = _.isEqual(spec.id, 'line');

  const customFormatToggles = Viz.getCustomFormatTogglesFromViz(viz);
  const linkToReport = JSON.parse(_.get(viz, 'options.linkToReport', '{}'));

  // datapoint selection mode is currently supported only on line charts
  const useDataPointSelectionMode =
    isLineChart && enableReportLink && viz.layout.LINES?.length > 0;

  return {
    showGlobalTooltip: state.main.controlDown || isLineChart,
    isMobile,
    yAxisWidth: isMobile ? 58 : 90,
    mainChartPadding: _.isNumber(props.mainChartPadding)
      ? props.mainChartPadding
      : isMobile
      ? 12
      : 20,
    xAxisHeight: isMobile ? (isDashletMode ? 60 : 36) : 48,
    alignYAxesAtZero:
      JSON.parse(_.get(props, 'viz.options.alignYAxesAtZero', 'true')) ||
      props.alignYAxesAtZero,
    dataFormatters: formatters,
    focusedData,
    focusedDataPoints,
    dataPointSelectionMode: !_.isEmpty(focusedDataPoints),
    useDataPointSelectionMode,
    isComboChart:
      _.isEqual(props.spec.id, 'column_line') ||
      _.isEqual(props.spec.id, 'stack_line'),
    isDashletMode,
    useFiscalCalendar,
    i18nPrefs,
    reportDetailInfo,
    customFormatToggles,
    linkToReport,
    enableReportLink,
    customFormatProps,
  };
};
(BaseCartesianChart as any).mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setVizLegendData: (id, legendData: ILegendDatum[]) => {
      dispatch(Discover.setVizLegendData(id, legendData));
    },
    setFocusedData(dataItem) {
      dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
    },
    setScrollPos: (id, pctLeft, pctTop) => {
      dispatch(Discover.setScrollPos(id, pctLeft, pctTop));
    },
    setFocusedDataPoints(pointData, lineData, collectDetailInfoArgs) {
      dispatch(
        Discover.setFocusedDataPoints(
          ownProps.vizId,
          pointData,
          lineData,
          collectDetailInfoArgs,
        ),
      );
    },
    openReportLink(reportDetailInfo) {
      dispatch(Discover.openReportLink(reportDetailInfo, ownProps?.history)); // history provided by withRouter
    },
  };
};
