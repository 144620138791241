import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginAction from '../../common/redux/actions/LoginActions';
import _, { noop } from 'lodash';
import MobileMenuButton from '../../components/MobileMenuButton';
import ProfileImage from '../../components/ProfileImage';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';
import { compose } from 'react-recompose';
import { messages } from '../../i18n';
import { IUserInfo } from '../interfaces';
import { HelpBlock } from '../../views/VizSaveDialog/ui';

interface ISettingsAccountProps {
  logout: any;
  userInfo: IUserInfo;
  navigate: any;
}

interface ISettingsAccountState {
  showLogoutDialog: boolean;
}

class UnconnectedSettingsAccount extends Component<
  ISettingsAccountProps,
  ISettingsAccountState
> {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutDialog: false,
    };
  }

  showLogout(val) {
    this.setState({ showLogoutDialog: val });
  }

  logout() {
    this.props.logout();
  }

  cancelLogout() {
    this.showLogout(false);
  }

  render() {
    return (
      <div className='settings-account'>
        <div className='settings-account__user-card'>
          <ProfileImage
            className='settings-account__profile-image'
            src={this.props.userInfo.profileImageURL}
          />
          <div>
            <div className='settings-account__fullName'>
              {this.props.userInfo.fullName}
            </div>
            <div className='settings-account__email'>
              {this.props.userInfo.email}
            </div>
          </div>
        </div>
        <MobileMenuButton onClick={this.props.navigate.bind(this, 'profile')}>
          {messages.account.profile}
        </MobileMenuButton>
        <MobileMenuButton
          onClick={this.props.navigate.bind(this, 'notifications')}
        >
          {messages.account.notifications}
        </MobileMenuButton>
        <MobileMenuButton
          icon={false}
          onClick={this.showLogout.bind(this, true)}
        >
          {messages.account.logout}
        </MobileMenuButton>
        <PromptDialog
          show={this.state.showLogoutDialog}
          className='prompt-dialog--mobile'
          title={messages.account.logout}
          yesText={messages.account.logout}
          noText={messages.cancel}
          detail={
            <HelpBlock className='promptMessage'>
              {messages.formatString(
                messages.account.logOutConfirmPrompt,
                <strong>{_.toLower(messages.account.logout)}</strong>,
              )}
            </HelpBlock>
          }
          doYes={() => this.logout()}
          doNo={() => this.cancelLogout()}
        />
      </div>
    );
  }
}

(UnconnectedSettingsAccount as any).defaultProps = {
  navigate: noop,
  logout: noop,
  userInfo: {},
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      LoginAction.logout(dispatch); // why is this different?
    },
    navigate: destination => {
      ownProps.history.push(`account/${destination}`);
    },
  };
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    userInfo: state.account.currentUser,
  };
};

export const SettingsAccount = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UnconnectedSettingsAccount);
