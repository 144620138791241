import _ from 'lodash';
import { Types } from '../common/Constants';

const findFieldWithOrdinal = shelf => {
  return _.slice(
    shelf.filter(field => !_.isEmpty(field.ordinalAttribute)),
    0,
    1,
  );
};
const nonOrdinalFields = shelf => {
  return shelf.filter(field => _.isEmpty(field.ordinalAttribute));
};
const orderDatesFirst = shelf => {
  const timeTypes = [Types.TIME_CALC, Types.TIMESTAMP];
  return _.sortBy(shelf, f => {
    const isTime = _.includes(timeTypes, f.attributeType);
    return isTime ? 0 : 1;
  });
};
const getDateFields = shelf => {
  const timeTypes = [Types.TIME_CALC, Types.TIMESTAMP];
  return shelf.filter(f => {
    return _.includes(timeTypes, f.attributeType);
  });
};
const getNonDateFields = shelf => {
  const timeTypes = [Types.TIME_CALC, Types.TIMESTAMP];
  return shelf.filter(f => {
    return !_.includes(timeTypes, f.attributeType);
  });
};
const TableTransform = (prevLayout, nextType, nextLayout) => {
  const numericalFields = _.filter(prevLayout.COLUMNS, {
    attributeType: 'NUMBER',
  });
  const nonNumericalFields = _.filter(prevLayout.COLUMNS, {
    attributeType: 'NUMBER',
  });
  switch (nextType) {
    case 'pivot':
      // First non-number
      nextLayout.COLUMNS = _.slice(nonNumericalFields, 0, 1);
      // Remaining non-numbers
      nextLayout.ROWS = _.slice(nonNumericalFields, 1);
      // Numbers
      nextLayout.VALUES = numericalFields;
      break;
    case 'bar':
      // Remaining non-numbers
      nextLayout.COLUMNS = _.slice(nonNumericalFields, 1);
      // First non-number
      nextLayout.XAXIS = _.slice(nonNumericalFields, 0, 1);
      // Numbers
      nextLayout.VALUES = numericalFields;
      break;
    case 'line':
    case 'area':
      // Remaining non-numbers
      nextLayout.LINES = _.slice(nonNumericalFields, 1);
      // First non-number
      nextLayout.XAXIS = _.slice(nonNumericalFields, 0, 1);
      // Numbers
      nextLayout.VALUES = numericalFields;
      break;
    case 'column_line':
      // All number except last
      nextLayout.COLUMNS = _.slice(
        numericalFields,
        0,
        numericalFields.length - 1,
      );
      // Last number
      nextLayout.LINES = _.slice(
        numericalFields.length - 1,
        numericalFields.length,
      );
      // First non-number
      nextLayout.XAXIS = _.slice(nonNumericalFields, 0, 1);
      break;
    case 'stack_line':
      // Remaining non-numbers
      nextLayout.STACK = _.slice(nonNumericalFields, 1);
      // First non-number
      nextLayout.XAXIS = _.slice(nonNumericalFields, 0, 1);
      // Numbers
      nextLayout.VALUES = numericalFields;
      // Last number
      nextLayout.LINES = _.slice(
        numericalFields.length - 1,
        numericalFields.length,
      );
      break;
    case 'stack_bar':
    case 'stack':
      // Remaining non-numbers
      nextLayout.STACK = _.slice(nonNumericalFields, 1);
      // First non-number
      nextLayout.XAXIS = _.slice(nonNumericalFields, 0, 1);
      // Numbers
      nextLayout.VALUES = _.slice(
        numericalFields,
        0,
        numericalFields.length - 1,
      );
      break;
    case 'scatter':
      // First number
      nextLayout.XAXIS = _.slice(numericalFields, 0, 1);
      // Second number
      nextLayout.YAXIS = _.slice(numericalFields, 1, 2);
      // First non-number
      nextLayout.POINTS = _.slice(nonNumericalFields, 0, 1);
      // Remaining non-numbers
      nextLayout.COLORBY = _.slice(numericalFields, 1);
      // Third number
      nextLayout.SIZEBY = _.slice(numericalFields, 2, 3);
      break;
    case 'funnel':
      // First non-number
      nextLayout.XAXIS = findFieldWithOrdinal(nonNumericalFields);
      nextLayout.STACK = nonOrdinalFields(nonNumericalFields);
      // First number
      nextLayout.VALUES = _.slice(numericalFields, 0, 1);
      break;
  }
  return nextLayout;
};

const PipelineChangesTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'pivot':
      nextLayout.COLUMNS = prevLayout?.COLUMNS ?? [];
      nextLayout.ROWS = prevLayout?.ROWS ?? [];
      nextLayout.VALUES = prevLayout?.VALUES ?? [];
      break;
    case 'table':
      nextLayout.COLUMNS = [];
      break;
    case 'bar':
      nextLayout.COLUMNS = [];
      nextLayout.XAXIS = [];
      nextLayout.VALUES = [];
      break;
    case 'area':
    case 'area_line':
    case 'line':
      nextLayout.LINES = [];
      nextLayout.XAXIS = [];
      nextLayout.VALUES = [];
      break;
    case 'column_line':
      nextLayout.COLUMNS = [];
      nextLayout.LINES = [];
      nextLayout.XAXIS = [];
      break;
    case 'stack_line':
      nextLayout.STACK = [];
      nextLayout.XAXIS = [];
      // All values except last
      nextLayout.VALUES = [];
      // Last value
      nextLayout.LINES = [];
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = [];
      nextLayout.XAXIS = [];
      nextLayout.VALUES = [];
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = [];
      // Second value
      nextLayout.YAXIS = [];
      nextLayout.POINTS = [];
      nextLayout.COLORBY = [];
      // Third value
      nextLayout.SIZEBY = [];
      break;
    case 'funnel':
      nextLayout.XAXIS = [];
      nextLayout.STACK = [];
      nextLayout.VALUES = [];
      break;
  }
  return nextLayout;
};

const PivotTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.COLUMNS,
        prevLayout.ROWS,
        prevLayout.VALUES,
      );
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.ROWS;
      nextLayout.XAXIS = prevLayout.COLUMNS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area':
    case 'area_line':
    case 'line':
      nextLayout.LINES = prevLayout.ROWS;
      nextLayout.XAXIS = prevLayout.COLUMNS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'column_line':
      // All values except last
      nextLayout.COLUMNS = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.COLUMNS, prevLayout.ROWS),
      );
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.ROWS;
      nextLayout.XAXIS = prevLayout.COLUMNS;
      // All values except last
      nextLayout.VALUES = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.ROWS;
      nextLayout.XAXIS = prevLayout.COLUMNS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = [prevLayout.VALUES[1]] | [];
      nextLayout.POINTS = prevLayout.ROWS;
      nextLayout.COLORBY = prevLayout.COLUMNS;
      // Third value
      nextLayout.SIZEBY = _.slice(prevLayout.VALUES, 2, 3);
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.COLUMNS,
        ...prevLayout.ROWS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.COLUMNS,
        ...prevLayout.ROWS,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
    case 'pipeline_changes':
      nextLayout.COLUMNS = prevLayout?.COLUMNS ?? [];
      nextLayout.ROWS = prevLayout?.ROWS ?? [];
      nextLayout.VALUES = prevLayout?.VALUES ?? [];
      break;
  }
  return nextLayout;
};

const BarTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.COLUMNS,
        prevLayout.XAXIS,
        prevLayout.VALUES,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.XAXIS;
      nextLayout.ROWS = prevLayout.COLUMNS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area':
    case 'area_line':
    case 'line':
      nextLayout.LINES = prevLayout.COLUMNS;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'column_line':
      // All values except last
      nextLayout.COLUMNS = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.COLUMNS, prevLayout.XAXIS),
      );
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.COLUMNS;
      nextLayout.XAXIS = prevLayout.XAXIS;
      // All values except last
      nextLayout.VALUES = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.COLUMNS;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = _.slice(prevLayout.VALUES, 1, 2);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = prevLayout.COLUMNS;
      // Third value
      nextLayout.SIZEBY = _.slice(prevLayout.VALUES, 2, 3);
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.COLUMNS,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.COLUMNS,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
  }
  return nextLayout;
};

const LineTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.LINES,
        prevLayout.XAXIS,
        prevLayout.VALUES,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.XAXIS;
      nextLayout.ROWS = prevLayout.LINES;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area':
      nextLayout.LINES = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area_line':
    case 'column_line':
      // All values except last
      nextLayout.COLUMNS = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.LINES, prevLayout.XAXIS),
      );
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      // All values except last
      nextLayout.VALUES = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = _.slice(prevLayout.VALUES, 1, 2);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = prevLayout.LINES;
      // Third value
      nextLayout.SIZEBY = _.slice(prevLayout.VALUES, 2, 3);
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.LINES,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.LINES,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
  }
  return nextLayout;
};

const AreaTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.LINES,
        prevLayout.XAXIS,
        prevLayout.VALUES,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.XAXIS;
      nextLayout.ROWS = prevLayout.LINES;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'line':
      nextLayout.LINES = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area_line':
    case 'column_line':
      // All values except last
      nextLayout.COLUMNS = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.LINES, prevLayout.XAXIS),
      );
      break;
    case 'stack_line':
      nextLayout.STACK = [];
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      nextLayout.LINES = prevLayout.LINES;
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.LINES;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = _.slice(prevLayout.VALUES, 1, 2);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = prevLayout.LINES;
      // Third value
      nextLayout.SIZEBY = _.slice(prevLayout.VALUES, 2, 3);
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.LINES,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.LINES,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
  }
  return nextLayout;
};

const ColumnLineTransform = (prevLayout, nextType, nextLayout) => {
  const dateFields = getDateFields(prevLayout.XAXIS);
  const nonDateFields = getNonDateFields(prevLayout.XAXIS);
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.COLUMNS,
        prevLayout.LINES,
        prevLayout.XAXIS,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = _.isEmpty(dateFields) ? nonDateFields : dateFields;
      nextLayout.ROWS = _.isEmpty(dateFields) ? [] : nonDateFields;
      nextLayout.VALUES = _.union(prevLayout.COLUMNS, prevLayout.LINES);
      break;
    case 'bar':
      // Remaining fields
      nextLayout.COLUMNS = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 1, prevLayout.XAXIS.length)
        : nonDateFields;
      // First field
      nextLayout.XAXIS = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 0, 1)
        : dateFields;
      nextLayout.VALUES = _.union(prevLayout.COLUMNS, prevLayout.LINES);
      break;
    case 'area':
    case 'area_line':
    case 'line':
      // Remaining fields
      nextLayout.LINES = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 1, prevLayout.XAXIS.length)
        : nonDateFields;
      // First field
      nextLayout.XAXIS = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 0, 1)
        : dateFields;
      nextLayout.VALUES = _.union(prevLayout.COLUMNS, prevLayout.LINES);
      break;
    case 'stack_line':
      nextLayout.XAXIS = _.isEmpty(dateFields) ? nonDateFields : dateFields;
      nextLayout.STACK = _.isEmpty(dateFields) ? [] : nonDateFields;
      nextLayout.VALUES = prevLayout.COLUMNS;
      nextLayout.LINES = prevLayout.LINES;
      break;
    case 'stack_bar':
    case 'stack':
      // Remaining fields
      nextLayout.STACK = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 1, prevLayout.XAXIS.length)
        : nonDateFields;
      // First field
      nextLayout.XAXIS = _.isEmpty(dateFields)
        ? _.slice(prevLayout.XAXIS, 0, 1)
        : dateFields;
      nextLayout.VALUES = _.union(prevLayout.COLUMNS, prevLayout.LINES);
      break;
    case 'scatter':
      // First column field
      nextLayout.XAXIS = _.slice(prevLayout.COLUMNS, 0, 1);
      // First line field
      nextLayout.YAXIS = _.slice(prevLayout.LINES, 0, 1);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = [];
      nextLayout.SIZEBY = _.union(
        _.slice(prevLayout.COLUMNS, 1, 2),
        _.slice(prevLayout.LINES, 1, 2),
      );
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal(prevLayout.XAXIS);
      nextLayout.STACK = nonOrdinalFields(prevLayout.XAXIS);
      nextLayout.VALUES = _.slice(prevLayout.COLUMNS, 0, 1);
      break;
  }
  return nextLayout;
};

const StackTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.STACK,
        prevLayout.XAXIS,
        prevLayout.VALUES,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.XAXIS;
      nextLayout.ROWS = prevLayout.STACK;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area':
    case 'line':
      nextLayout.LINES = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area_line':
    case 'column_line':
      // All values except last
      nextLayout.COLUMNS = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      // Last value
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.STACK, prevLayout.XAXIS),
      );
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = _.slice(prevLayout.VALUES, 1, 2);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = prevLayout.STACK;
      // Third value
      nextLayout.SIZEBY = _.slice(prevLayout.VALUES, 2, 3);
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = _.slice(
        prevLayout.VALUES,
        0,
        prevLayout.VALUES.length - 1,
      );
      nextLayout.LINES = _.slice(
        prevLayout.VALUES,
        prevLayout.VALUES.length - 1,
        prevLayout.VALUES.length,
      );
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.STACK,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.XAXIS,
        ...prevLayout.STACK,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
  }
  return nextLayout;
};

const ScatterTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.XAXIS,
        prevLayout.YAXIS,
        prevLayout.POINTS,
        prevLayout.COLORBY,
        prevLayout.SIZEBY,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.COLORBY;
      nextLayout.ROWS = prevLayout.POINTS;
      nextLayout.VALUES = _.union(
        prevLayout.XAXIS,
        prevLayout.YAXIS,
        prevLayout.SIZEBY,
      );
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.COLORBY;
      nextLayout.XAXIS = prevLayout.POINTS;
      nextLayout.VALUES = _.union(
        prevLayout.XAXIS,
        prevLayout.YAXIS,
        prevLayout.SIZEBY,
      );
      break;
    case 'area':
    case 'line':
      nextLayout.LINES = prevLayout.COLORBY;
      nextLayout.XAXIS = prevLayout.POINTS;
      nextLayout.VALUES = _.union(
        prevLayout.XAXIS,
        prevLayout.YAXIS,
        prevLayout.SIZEBY,
      );
      break;
    case 'area_line':
    case 'column_line':
      nextLayout.COLUMNS = _.union(prevLayout.XAXIS, prevLayout.SIZEBY);
      nextLayout.LINES = prevLayout.YAXIS;
      nextLayout.XAXIS = _.union(prevLayout.POINTS, prevLayout.COLORBY);
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.COLORBY;
      nextLayout.XAXIS = prevLayout.POINTS;
      nextLayout.VALUES = prevLayout.YAXIS;
      nextLayout.LINES = prevLayout.SIZEBY;
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.COLORBY;
      nextLayout.XAXIS = prevLayout.POINTS;
      nextLayout.VALUES = _.union(
        prevLayout.XAXIS,
        prevLayout.YAXIS,
        prevLayout.SIZEBY,
      );
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal(prevLayout.POINTS);
      nextLayout.VALUES = _.slice(prevLayout.XAXIS, 0, 1);
      break;
  }
  return nextLayout;
};

const FunnelTransform = (prevLayout, nextType, nextLayout) => {
  // we haven't always supported stacks in funnel charts so the layout might not have that shelf defined
  const prevLayoutStack = _.get(prevLayout, 'STACK', []);
  const dateFields = getDateFields([...prevLayout.XAXIS, ...prevLayoutStack]);
  const nonDateFields = getNonDateFields([
    ...prevLayoutStack,
    ...prevLayout.XAXIS,
  ]);

  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.XAXIS,
        prevLayout.VALUES,
        prevLayoutStack,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = !_.isEmpty(dateFields)
        ? dateFields
        : prevLayout.XAXIS;
      nextLayout.ROWS = !_.isEmpty(dateFields)
        ? nonDateFields
        : prevLayoutStack;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'bar':
      nextLayout.COLUMNS = !_.isEmpty(dateFields)
        ? nonDateFields
        : prevLayoutStack;
      nextLayout.XAXIS = !_.isEmpty(dateFields) ? dateFields : prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area':
    case 'line':
      nextLayout.LINES = !_.isEmpty(dateFields)
        ? nonDateFields
        : prevLayoutStack;
      nextLayout.XAXIS = !_.isEmpty(dateFields) ? dateFields : prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
    case 'area_line':
    case 'column_line':
      nextLayout.COLUMNS = prevLayout.VALUES;
      nextLayout.LINES = [];
      nextLayout.XAXIS = orderDatesFirst([
        ...prevLayout.XAXIS,
        ...prevLayoutStack,
      ]);
      break;
    case 'scatter':
      nextLayout.XAXIS = prevLayout.VALUES;
      nextLayout.YAXIS = [];
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = [];
      nextLayout.SIZEBY = [];
      break;
    case 'stack_line':
      nextLayout.STACK = !_.isEmpty(dateFields)
        ? nonDateFields
        : prevLayoutStack;
      nextLayout.XAXIS = !_.isEmpty(dateFields) ? dateFields : prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      nextLayout.LINES = [];
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = !_.isEmpty(dateFields)
        ? nonDateFields
        : prevLayoutStack;
      nextLayout.XAXIS = !_.isEmpty(dateFields) ? dateFields : prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      break;
  }
  return nextLayout;
};

const StackLineTransform = (prevLayout, nextType, nextLayout) => {
  switch (nextType) {
    case 'table':
      nextLayout.COLUMNS = _.union(
        prevLayout.STACK,
        prevLayout.XAXIS,
        prevLayout.VALUES,
        prevLayout.LINES,
      );
      break;
    case 'pivot':
      nextLayout.COLUMNS = prevLayout.XAXIS;
      nextLayout.ROWS = prevLayout.STACK;
      nextLayout.VALUES = [...prevLayout.LINES, ...prevLayout.VALUES];
      break;
    case 'bar':
      nextLayout.COLUMNS = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = [...prevLayout.VALUES, ...prevLayout.LINES];
      break;
    case 'area':
    case 'line':
      nextLayout.LINES = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = [...prevLayout.VALUES, ...prevLayout.LINES];
      break;
    case 'area_line':
    case 'column_line':
      nextLayout.COLUMNS = prevLayout.VALUES;
      nextLayout.LINES = prevLayout.LINES;
      nextLayout.XAXIS = orderDatesFirst(
        _.union(prevLayout.STACK, prevLayout.XAXIS),
      );
      break;
    case 'scatter':
      // First value
      nextLayout.XAXIS = _.slice(prevLayout.VALUES, 0, 1);
      // Second value
      nextLayout.YAXIS = _.slice(prevLayout.VALUES, 1, 2);
      nextLayout.POINTS = prevLayout.XAXIS;
      nextLayout.COLORBY = prevLayout.STACK;
      // Third value
      nextLayout.SIZEBY = prevLayout.LINES;
      break;
    case 'stack_line':
      nextLayout.STACK = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = prevLayout.VALUES;
      nextLayout.LINES = prevLayout.LINES;
      break;
    case 'stack_bar':
    case 'stack':
      nextLayout.STACK = prevLayout.STACK;
      nextLayout.XAXIS = prevLayout.XAXIS;
      nextLayout.VALUES = [...prevLayout.VALUES, ...prevLayout.LINES];
      break;
    case 'funnel':
      nextLayout.XAXIS = findFieldWithOrdinal([
        ...prevLayout.STACK,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.STACK = nonOrdinalFields([
        ...prevLayout.STACK,
        ...prevLayout.XAXIS,
      ]);
      nextLayout.VALUES = _.slice(prevLayout.VALUES, 0, 1);
      break;
  }
  return nextLayout;
};

/* Transition function for mapping current visualization layout
   to next selected layout */
export const VizLayoutMapping = (prevLayout, prevType, nextType) => {
  if (prevType === nextType) {
    return prevLayout;
  }

  const nextLayout = {};

  // same for all types
  nextLayout.SLICER = prevLayout.SLICER || [];

  switch (prevType) {
    case 'table':
      return TableTransform(prevLayout, nextType, nextLayout);
    case 'pivot':
      return PivotTransform(prevLayout, nextType, nextLayout);
    case 'bar':
      return BarTransform(prevLayout, nextType, nextLayout);
    case 'area':
    case 'area_line':
      return AreaTransform(prevLayout, nextType, nextLayout);
    case 'line':
      return LineTransform(prevLayout, nextType, nextLayout);
    case 'column_line':
      return ColumnLineTransform(prevLayout, nextType, nextLayout);
    case 'stack_bar':
    case 'stack':
      return StackTransform(prevLayout, nextType, nextLayout);
    case 'scatter':
      return ScatterTransform(prevLayout, nextType, nextLayout);
    case 'funnel':
      return FunnelTransform(prevLayout, nextType, nextLayout);
    case 'stack_line':
      return StackLineTransform(prevLayout, nextType, nextLayout);
    case 'waterfall':
    case 'pipeline_changes':
      return PipelineChangesTransform(prevLayout, nextType, nextLayout);
    default:
      return prevLayout;
  }
};
