import { withTheme } from '@emotion/react';
import { useCallback, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, Navbar, NavItem } from '@sugar-discover/react-bootstrap-wrapper';
import { compose, shouldUpdate } from 'react-recompose';
import _ from 'lodash';
import Main from '../../common/redux/actions/MainActions';
import Login from '../../common/redux/actions/LoginActions';
import { withRouter } from 'react-router-dom';
import {
  ACCOUNT_SELECTORS,
  isAdmin,
  getIsReadOnlyUser,
} from '../../common/redux/selectors/AccountSelectors';
import ConfirmLogoutPrompt from '../../components/ConfimLogoutPrompt';
import DiscoverActions from '../../common/redux/actions/DiscoverActions';
import { HELP_LINK } from '../../common/Constants';
import { messages } from '../../i18n';
import Util from '../../common/Util';
import {
  AccountIcon,
  DiscoverIcon,
  DownChevron,
  FeedIcon,
  LibraryIcon,
  LogoutIcon,
  ProfileIcon,
  SettingsCogIcon,
} from '../../icons';
import { getIsDarkModeEnabled } from '../../common/redux/selectors/main-selector-hooks';
import { IconContainer, ProfileDropdownRoot } from './header.styles';
import { PinnedVizQuery } from '../../common/graphql/util';
import { IHeaderProps } from './interfaces';
import { IconDropdown } from '../../components/icon-dropdown';
import MenuItem from '@mui/material/MenuItem';
import { FeatureToggle } from '../../common/utilities/feature-flag/feature-toggle';

const HeaderComponent = ({
  loadPinnedDiscoveries,
  pinnedDiscoveries,
  openDiscoveries,
  openPinnedDiscoveries,
  location,
  history,
  isAdmin,
  isMobile,
  isAdvancedMode,
  feedAvailable,
  feed,
  isReadOnly,
  theme = {},
  currentUser,
  appUrl = '',
}: IHeaderProps) => {
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [pinnedDiscoveriesLoaded, setPinnedDiscoveriesLoaded] = useState(false);
  const { colors: { PrimaryColor = '' } = {} } = theme || ({} as any);
  const feedEnabled = (feedAvailable || !_.isEmpty(feed)) && !isReadOnly;

  useEffect(() => {
    if (
      loadPinnedDiscoveries &&
      !pinnedDiscoveriesLoaded &&
      !_.isEmpty(pinnedDiscoveries) &&
      _.isEmpty(openDiscoveries)
    ) {
      openPinnedDiscoveries(pinnedDiscoveries);
      setPinnedDiscoveriesLoaded(true);
    }
  }, [
    loadPinnedDiscoveries,
    pinnedDiscoveries,
    openDiscoveries,
    openPinnedDiscoveries,
    pinnedDiscoveriesLoaded,
  ]);

  const locationPathname = location?.pathname;

  const menuItemSelected = useCallback(
    eventKey => {
      if (eventKey === 'logout') {
        setShowLogoutDialog(true);
      } else if (eventKey === 'account') {
        eventKey = 'account';
      }
      if (!(eventKey === 'open' && locationPathname?.startsWith('/open'))) {
        history.push(`/${eventKey}`);
      }
    },
    [setShowLogoutDialog, history, locationPathname],
  );

  const openHelp = () => {
    window.open(HELP_LINK, '_blank', 'noopener,noreferrer');
  };

  const accountNavSelected = useCallback(eventKey => {
    if (eventKey === 'help') {
      return openHelp();
    }
  }, []);

  const path = history?.location?.pathname ?? '';
  const pathMatch = /\/([^/]*)\/*/?.exec(path);
  const activeTab = pathMatch ? pathMatch[1] : '';

  const isActiveTab = key => {
    return activeTab === key;
  };

  const getIconColor = isActiveRoute =>
    isActiveRoute ? PrimaryColor : undefined;

  const currentUserTenant = currentUser?.tenant;

  const imgSrc = Util.assetUrl({ appUrl, path: 'discover_logo.svg' });

  return (
    <Navbar
      id='mainHeader'
      className={feedEnabled && isActiveTab('') ? ' feedActive' : ''}
      collapseOnSelect
    >
      <Navbar.Header>
        <Navbar.Brand>
          <div>
            <img src={imgSrc} className='invertable logo updatedLogo2' />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <If check={!isMobile}>
        <Navbar.Collapse className='text-uppercase left-slide mainHeader__main-menu'>
          <Nav
            className='menu-items mainHeader__main-menu__list'
            activeKey={activeTab}
            onSelect={e => menuItemSelected(e)}
          >
            {isMobile && (
              <NavItem eventKey={'account'}>
                <div data-menu-location='account' className='menu-item mobile'>
                  {messages.nav.account}
                </div>
              </NavItem>
            )}
            {isMobile && (
              <NavItem eventKey={'logout'}>
                <div data-menu-location='logout' className='menu-item mobile'>
                  {messages.nav.logout}
                </div>
              </NavItem>
            )}
          </Nav>
          <Nav
            className='accountNavItem'
            pullRight
            onSelect={accountNavSelected}
          >
            <ProfileDropdownRoot>
              {isAdvancedMode && (
                <>
                  <span className={'tenant-name'}>{currentUserTenant}</span>
                  <FeatureToggle />
                </>
              )}
              <ProfileIcon size={16} />
              <IconDropdown
                IconComponent={DownChevron}
                slotProps={{
                  paper: {
                    sx: {
                      width: 150,
                    },
                  },
                }}
              >
                <MenuItem
                  id={'profile-link'}
                  className='menu-item'
                  onClick={() => menuItemSelected('account/user')}
                >
                  <ProfileIcon size={12} />
                  {messages.nav.profile}
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    id={'admin-link'}
                    className='menu-item'
                    onClick={() => menuItemSelected('administration')}
                  >
                    <SettingsCogIcon size={12} />
                    {messages.nav.admin}
                  </MenuItem>
                )}
                <MenuItem
                  id={'logout-link'}
                  className='menu-item'
                  onClick={() => setShowLogoutDialog(true)}
                >
                  <LogoutIcon size={12} />
                  {messages.nav.logout}
                </MenuItem>
              </IconDropdown>
            </ProfileDropdownRoot>
          </Nav>
        </Navbar.Collapse>
      </If>
      {showLogoutDialog && (
        <ConfirmLogoutPrompt onHide={() => setShowLogoutDialog(false)} />
      )}

      <div className='MobileHeader'>
        {feedEnabled && (
          <IconContainer className={'Icon'}>
            <FeedIcon
              onClick={() => menuItemSelected('')}
              hover
              color={getIconColor(isActiveTab(''))}
            />
            <span>{messages.nav.feed}</span>
          </IconContainer>
        )}
        <IconContainer className={'Icon'}>
          <DiscoverIcon
            onClick={() => menuItemSelected('open')}
            hover
            color={getIconColor(isActiveTab('open'))}
          />
          <span>{messages.nonTranslated.discover}</span>
        </IconContainer>
        <IconContainer className={'Icon'}>
          <LibraryIcon
            onClick={() => menuItemSelected('library')}
            hover
            color={getIconColor(isActiveTab('library'))}
          />
          <span>{messages.nav.library}</span>
        </IconContainer>
        <IconContainer className={'Icon'}>
          <AccountIcon
            onClick={() => menuItemSelected('account')}
            hover
            color={getIconColor(isActiveTab('account'))}
          />
          <span>{messages.nav.account}</span>
        </IconContainer>
      </div>
    </Navbar>
  );
};

const mapStateToProps = function(state) {
  const {
    main: {
      activeTab,
      activeSubmenuTab,
      appUrl,
      isMobile,
      advanced: isAdvancedMode,
      activityFeedAvailable: feedAvailable,
    },
    discover: { openDiscoveries },
    account: { currentUser },
  } = state;

  const isDarkModeEnabled = getIsDarkModeEnabled(state);

  return {
    activeTab,
    isDarkModeEnabled,
    activeSubmenuTab,
    openDiscoveries,
    currentUser,
    isAdmin: isAdmin(state.account),
    isReadOnly: getIsReadOnlyUser(state),
    isMobile,
    isAdvancedMode,
    pinnedDiscoveryIds: ACCOUNT_SELECTORS.getPinnedDiscoveries(state),
    feedAvailable,
    appUrl,
  };
};

const mapDispatchToProps = function(dispatch) {
  return {
    setActiveTab(id) {
      dispatch(Main.setActiveTab(id));
    },
    setActiveSubmenuTab(activeTab, submenuTab) {
      dispatch(Main.setActiveTab(activeTab));
      dispatch(Main.setActiveSubmenuTab(activeTab, submenuTab));
    },
    logout() {
      Login.logout(dispatch);
    },
    openPinnedDiscoveries(pins) {
      dispatch(DiscoverActions.openPinnedDiscoveries(pins));
    },
  };
};

export const Header = compose<IHeaderProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
  shouldUpdate((curr: any, next: any) => {
    return (
      !![
        'activeTab',
        'isDarkModeEnabled',
        'activeSubmenuTab',
        'currentUser',
        'isAdmin',
        'isAdvancedMode',
        'pinnedDiscoveryIds',
        'feedAvailable',
      ].find(x => !_.isEqual(curr[x], next[x])) ||
      !_.isEqual(
        Object.keys(curr.openDiscoveries),
        Object.keys(next.openDiscoveries),
      ) ||
      !_.isEqual(curr.location.pathname, next.location.pathname)
    );
  }),
  PinnedVizQuery,
)(HeaderComponent);

const If = ({ check, children }) => {
  return check ? <Fragment>{children}</Fragment> : null;
};
