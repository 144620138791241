import { memo, useState, Fragment } from 'react';
import {
  SubMenu,
  IconWrapper,
  KabobIconStyled,
  MenuListStyled,
  MenuItemStyled,
  SubMenuTitle,
} from './sub-menu.styles';
import { ISubMenuProps } from '../interfaces';
import { CloseIcon, KebabIcon } from '../../../icons';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash';
import { DarkModeProvider } from '../../../common/emotion/theme';
import { messages } from '../../../i18n';
import { useOutsideClick } from '../../../components/ClickOutsideListener';

export const NUMBER_OF_ITEMS_TO_SHOW = 5;

export const SubMenuComponent = memo(
  ({
    setOpenedSubMenu,
    unsetOpenedSubMenu,
    menuItems,
    collapsedTitle = null,
  }: ISubMenuProps) => {
    const [open, setIsOpen] = useState(false);
    const [menuItemLimit, setMenuItemLimit] = useState(NUMBER_OF_ITEMS_TO_SHOW);
    const moreThenMinimum = menuItems.length > NUMBER_OF_ITEMS_TO_SHOW;

    const handleClose = () => {
      setIsOpen(false);
      unsetOpenedSubMenu();
    };

    const handleToggle = e => {
      e.stopPropagation();
      if (open) {
        unsetOpenedSubMenu();
      } else {
        setOpenedSubMenu();
      }

      setIsOpen(prev => !prev);
    };

    const showMore = () => {
      setMenuItemLimit(prev => {
        if (prev === NUMBER_OF_ITEMS_TO_SHOW) {
          return menuItems.length;
        } else {
          return NUMBER_OF_ITEMS_TO_SHOW;
        }
      });
    };

    const menuListRef = useOutsideClick<HTMLUListElement>(handleClose, open);

    const renderContent = ({ icon, label }) => (
      <Fragment>
        <IconWrapper>{icon}</IconWrapper>
        <span>{label}</span>
      </Fragment>
    );

    return (
      <SubMenu>
        <KabobIconStyled
          className={`kabob-dropdown ${open ? 'active' : 'inactive'}`}
          onClick={handleToggle}
        >
          <KebabIcon hover size={12} />
        </KabobIconStyled>
        <DarkModeProvider>
          <MenuListStyled open={open} ref={menuListRef}>
            {collapsedTitle && (
              <SubMenuTitle>
                <span>{collapsedTitle}</span>
                <CloseIcon onClick={handleClose} size={12} />
              </SubMenuTitle>
            )}
            {menuItems
              .slice(0, menuItemLimit)
              .map(({ key, label, icon, link, handleOnItemClick }) => (
                <MenuItemStyled
                  key={key || label}
                  onClick={() => {
                    if (isFunction(handleOnItemClick)) {
                      handleOnItemClick();
                    }
                    handleClose();
                  }}
                >
                  {link ? (
                    <Link to={link}>{renderContent({ icon, label })}</Link>
                  ) : (
                    <div>{renderContent({ icon, label })}</div>
                  )}
                </MenuItemStyled>
              ))}
            {moreThenMinimum && (
              <MenuItemStyled onClick={showMore}>
                <div>
                  <span>
                    {menuItemLimit === NUMBER_OF_ITEMS_TO_SHOW
                      ? `${messages.nav.moreReports}`
                      : messages.nav.lessReports}
                  </span>
                </div>
              </MenuItemStyled>
            )}
          </MenuListStyled>
        </DarkModeProvider>
      </SubMenu>
    );
  },
);
