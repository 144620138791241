import { css } from '@emotion/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDiscoverTheme } from '../../common/emotion';
import {
  ISlicerOption,
  noBottomBorder,
  SLICER_MENU_MAX_HEIGHT,
  SlicerButtonFooter,
  SlicerMenuItem,
  SlicerOptions,
  SlicerSearchMenuItem,
  SlicerShadowBackdrop,
  SlicerSearchbox,
  SlicerThemeProvider,
  SlicerTooltip,
  SelectAllItem,
  SlicerSelectionColumn,
  isStatusValid,
  isIndeterminateStatus,
} from '../slicer';
import { Dropdown } from '@sugar-discover/react-bootstrap-wrapper';
import { SlugSpan } from '../slicer/slicer-widget/item-count-slug/item-count-slug.styles';
import _ from 'lodash';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { messages } from '../../i18n';
import { SugarIcon } from '../../icons';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import {
  FilterWidgetMenuItem,
  FilterWidgetStyledDropdownMenu,
} from './filter-widget.styles';
import { FilterWidgetContext } from './filter-widget.context';

type IFilterOptions = ISlicerOption;

interface SaveFunc {
  (tags: string[]): any;
}

export interface IFilterWidgetProps {
  placeholder?: string;
  onlyEnabled?: boolean;
  disableAdd?: boolean;
  disableSelectAll?: boolean;
  onSave: SaveFunc;
  attachnodeid?: string;
  isDashletTag?: boolean;
  widgetType?: IWidgetType;
}

export type IWidgetType = 'tags' | 'chartTypes' | 'datasets';

const MIN_DROPDOWN_WIDTH = 80;
const TAG_MENU_MAX_HEIGHT = SLICER_MENU_MAX_HEIGHT + 45;
const TAG_MENU_TOGGLE_HEIGHT = 40;
const TAG_MENU_TOGGLE_HEIGHT_DASHLET = 30;
const TAG_DROPDOWN_ID = 'tag-dropdown';

const menuStyle: CSSProperties = {
  overflowX: 'visible',
  overflowY: 'visible',
  pointerEvents: 'none',
  width: '100%',
};

const createOptionsList = (
  allTags: string[],
  selectedTags?: string[],
): IFilterOptions[] => {
  const isSelected = (tag: string): boolean =>
    !_.isEmpty(selectedTags) ? _.includes(selectedTags, tag) : false;

  return _.orderBy(
    _.reduce(
      allTags,
      (acc, tag) => {
        return [...acc, { option: tag, isSelected: isSelected(tag) }];
      },
      [],
    ),
    ['isSelected', 'option'],
    ['desc', 'asc'],
  );
};

const encodeLabels = tagOptions =>
  _.map(_.filter(tagOptions, { isSelected: true }), 'option').join(', ');

export const FilterWidget = ({
  placeholder = messages.tags.emptyTags,
  disableAdd = false,
  disableSelectAll = true,
  onSave,
  attachnodeid,
  isDashletTag = false,
  widgetType = 'tags',
}: IFilterWidgetProps) => {
  const theme = useDiscoverTheme();
  const {
    isMobile,
    isDashletMode,
    colors: { ContentBackground, MediumBlue, BorderColor },
  } = theme;
  const { loading, data, selectedTypes, labelDisplayMap } = useContext(
    FilterWidgetContext,
  );
  const getDisplayValues = useCallback(
    (values: string[]) =>
      !labelDisplayMap || _.isEmpty(labelDisplayMap[widgetType])
        ? values
        : _.map(values, v => _.get(labelDisplayMap[widgetType], v)),
    [labelDisplayMap, widgetType],
  );
  const getFilterValues = useCallback(
    (labels: string[]) =>
      !labelDisplayMap || _.isEmpty(labelDisplayMap[widgetType])
        ? labels
        : _.map(labels, v => _.get(_.invert(labelDisplayMap[widgetType]), v)),
    [labelDisplayMap, widgetType],
  );
  const selectedOptions = getDisplayValues(selectedTypes[widgetType]);
  const [dropdownWidth, setDropdownWidth] = useState(MIN_DROPDOWN_WIDTH);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('');
  const [allTagOptions, setAllTagOptions] = useState(
    createOptionsList(selectedOptions),
  );
  const [selectAllToggleActive, setSelectAllToggleActive] = useState(false);

  const selectAllLabel = selectAllToggleActive
    ? messages.deselectAll
    : messages.selectAll;

  const allTags = useMemo(
    () =>
      _.uniq([
        ...getDisplayValues(_.flatMap(data?.visualizations, widgetType)),
        ...selectedOptions,
      ]),
    [data?.visualizations, getDisplayValues, selectedOptions, widgetType],
  );
  const hasAllTagsChanged = useHasValueChanged({ value: allTags });
  const hasSelectedTagsChanged = useHasValueChanged({ value: selectedOptions });

  useEffect(() => {
    if (!loading && (hasAllTagsChanged || hasSelectedTagsChanged)) {
      setAllTagOptions(createOptionsList(allTags, selectedOptions));
    }
  }, [
    loading,
    allTags,
    selectedOptions,
    hasAllTagsChanged,
    hasSelectedTagsChanged,
  ]);

  const [selectedTagsLabel, originalTagsLabel] = useMemo(
    () => [
      encodeLabels(allTagOptions),
      encodeLabels(createOptionsList(allTags, selectedOptions)),
    ],
    [allTagOptions, selectedOptions, allTags],
  );
  const hasSelectedTagsLabelChanged = selectedTagsLabel !== originalTagsLabel;

  const filteredTagOptions = _.filter(allTagOptions, t =>
    _.includes(_.toLower(t.option), _.toLower(currentFilter)),
  );

  const toggleOption = useCallback(
    (option: string) => {
      const updatedOptions = allTagOptions.map(opt =>
        _.isEqual(opt.option, option)
          ? ({ option, isSelected: !opt.isSelected } as IFilterOptions)
          : opt,
      );
      setAllTagOptions(updatedOptions);
    },
    [allTagOptions],
  );

  const addOptionToTags = useCallback(() => {
    const newOption: IFilterOptions = {
      option: currentFilter,
      isSelected: true,
    };
    setCurrentFilter('');
    setAllTagOptions([newOption, ...allTagOptions]);
  }, [allTagOptions, currentFilter]);

  const tagRef = useCallback(node => {
    if (!_.isNil(node)) {
      const { width } = node.getBoundingClientRect();
      setDropdownWidth(Math.max(width, MIN_DROPDOWN_WIDTH) - 2);
    }
  }, []);

  const toggleAll = useCallback(() => {
    const isActive = !selectAllToggleActive;
    setSelectAllToggleActive(isActive);
    const updatedOptions = allTagOptions.map(opt => ({
      option: opt.option,
      isSelected: isActive,
    }));
    setAllTagOptions(updatedOptions);
  }, [selectAllToggleActive, allTagOptions]);

  const ref = useRef(null);

  const onCancel = useCallback(() => {
    const resetOptions = createOptionsList(
      _.map(allTagOptions, 'option'),
      selectedOptions,
    );
    setCurrentFilter('');
    setAllTagOptions(resetOptions);
    setIsOpen(false);
    setSelectAllToggleActive(false);
  }, [allTagOptions, selectedOptions]);

  const selectStatus = useMemo(
    () =>
      _.every(allTagOptions, 'isSelected')
        ? 'all'
        : !_.some(allTagOptions, 'isSelected')
        ? 'none'
        : 'some',
    [allTagOptions],
  );

  return (
    <div
      id={`tag-wrapper-${widgetType}`}
      ref={tagRef}
      className={'combo-dropdown'}
      css={css({
        width: '100%',
      })}
    >
      <SlicerThemeProvider
        isOpen={isOpen}
        width={dropdownWidth}
        toggleHeight={
          isDashletMode
            ? TAG_MENU_TOGGLE_HEIGHT_DASHLET
            : TAG_MENU_TOGGLE_HEIGHT
        }
      >
        <SlicerTooltip
          options={_.filter(allTagOptions, 'isSelected')}
          isOpen={isOpen}
          loading={loading}
        >
          <span id={`tag-tooltip-${widgetType}`}>
            <Dropdown
              id={`${TAG_DROPDOWN_ID}-${widgetType}`}
              ref={ref}
              open={isOpen}
              className={'tag-dropdown'}
              css={css({
                width: '100%',
              })}
              onToggle={(open, event) => {
                if (
                  !open &&
                  (!event?.target ||
                    _.some(
                      [
                        // Ignore any clicks to the menu or the modal
                        ref.current,
                      ],
                      node => node?.contains(event.target as any),
                    ))
                ) {
                  // only concerned with outside clicks
                  return;
                }
                onCancel();
              }}
            >
              <Dropdown.Toggle
                id={`tag-toggle-${widgetType}`}
                css={css({
                  width: '100%',
                  height: `${
                    isDashletMode
                      ? TAG_MENU_TOGGLE_HEIGHT_DASHLET
                      : TAG_MENU_TOGGLE_HEIGHT
                  }px !important`,
                  boxShadow: 'none !important',
                  borderColor: `${BorderColor} !important`,
                  display: 'flex !important',
                  placeContent: 'stretch flex-start',
                  flexDirection: 'row',
                  alignItems: 'center',
                  overflow: 'hidden',
                  ...(isOpen
                    ? {
                        backgroundColor: `${ContentBackground} !important`,
                        ...noBottomBorder(),
                      }
                    : {}),
                  '&:hover': {
                    color: 'grey',
                    borderColor: `${BorderColor} !important`,
                    backgroundColor: `${ContentBackground} !important`,
                    ...(isOpen ? noBottomBorder() : {}),
                  },
                })}
                onClick={() => {
                  isOpen ? onCancel() : setIsOpen(true);
                }}
              >
                <SlugSpan
                  css={css({
                    width: '100%',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  })}
                  className={'tag-slug'}
                >
                  {selectedTagsLabel || placeholder}
                </SlugSpan>
              </Dropdown.Toggle>
              <FilterWidgetStyledDropdownMenu
                attachnodeid={attachnodeid}
                offsetTop={-2}
                style={menuStyle}
                open={isOpen}
                anchorId={`tag-toggle-${widgetType}`}
                className={`combo-dropdown__dropdown-menu-no-scroll tag-dropdown`}
                shouldUpdate={_.constant(true)}
                isMobile={false}
              >
                {!isMobile && <SlicerShadowBackdrop />}
                <div
                  id={`tag-options-dropdown-${widgetType}`}
                  css={css({
                    pointerEvents: 'all',
                    display: isOpen ? 'flex' : 'none',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: `${ContentBackground} !important`,
                    maxHeight: !isMobile && TAG_MENU_MAX_HEIGHT,
                    userSelect: 'none',
                  })}
                >
                  <SlicerSearchMenuItem disabled draggable={false}>
                    <div
                      className='search-input'
                      css={css({ display: 'flex' })}
                    >
                      <SlicerSearchbox
                        onChange={setCurrentFilter}
                        value={currentFilter}
                        isOpen={isOpen}
                      />
                      {!disableAdd && !_.isEmpty(currentFilter) && (
                        <span
                          title={messages.tags.addTag}
                          css={css({ display: 'flex' })}
                        >
                          <SugarIcon
                            css={css({
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '12px',
                            })}
                            icon={'plus'}
                            hoverColor={MediumBlue}
                            onClick={addOptionToTags}
                          />
                        </span>
                      )}
                    </div>
                  </SlicerSearchMenuItem>
                  {!disableSelectAll && (
                    <SelectAllItem role='presentation'>
                      <SlicerSelectionColumn
                        isHovered={false}
                        option={selectAllLabel}
                        selected={isStatusValid(selectStatus)}
                        labelRef={null}
                        onSelect={toggleAll}
                        width={'100%'}
                        name={messages.selectAll}
                        indeterminate={isIndeterminateStatus(selectStatus)}
                      />
                    </SelectAllItem>
                  )}
                  <SlicerMenuItem
                    disableSelectAll={disableSelectAll}
                    draggable={false}
                    onDragStart={event => {
                      event.preventDefault();
                      return false;
                    }}
                  >
                    <SlicerOptions
                      slicerErrorVisible={false}
                      width={dropdownWidth - 22}
                      options={filteredTagOptions}
                      name={'tags'}
                      toggleOption={toggleOption}
                      selectOnly={_.noop}
                      isDashletTag={isDashletTag}
                    />
                  </SlicerMenuItem>

                  <FilterWidgetMenuItem disabled>
                    <SlicerButtonFooter
                      width={isMobile ? '100%' : dropdownWidth}
                      name={'bar'}
                      onCancel={onCancel}
                      onSave={() => {
                        const selectedOptions = getFilterValues(
                          _.map(
                            _.filter(allTagOptions, 'isSelected'),
                            'option',
                          ),
                        );
                        onSave(selectedOptions);
                        setCurrentFilter('');
                        setIsOpen(false);
                      }}
                      saveDisabled={!hasSelectedTagsLabelChanged}
                    />
                  </FilterWidgetMenuItem>
                </div>
              </FilterWidgetStyledDropdownMenu>
            </Dropdown>
          </span>
        </SlicerTooltip>
      </SlicerThemeProvider>
    </div>
  );
};
