import styled from '@emotion/styled';
import { hbox } from '../../../common/emotion';
import { css } from '@emotion/react';

export const SlicerPanelContainer = styled.div(css`
  ${hbox()}
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
  z-index: 5;
`);
