import { Component } from 'react';
import TableHeaderColumn from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from '@sugar-discover/react-bootstrap-wrapper';
import SortAscIcon from '../../../images/sdd/sort_ascending.svg';
import SortDescIcon from '../../../images/sdd/sort_descending.svg';
import shortid from 'shortid';

/**
 * This Table component just wraps the TableHeaderColumn from react-bootstrap-table to allow for a common
 * location to add custom functionality.
 * Refer to https://github.com/AllenFang/react-bootstrap-table for documentation.
 */
class TableColumn extends Component {
  renderSortableHeader(id, content) {
    const isSelected = value =>
      this.props.sort === value ? 'menuitem-selected' : '';
    const renderSortIcon = () => {
      switch (this.props.sort) {
        case 'asc':
          return <SortAscIcon className='sort-icon sort-ascending' />;
        case 'desc':
          return <SortDescIcon className='sort-icon sort-descending' />;
        default:
          return <span className='sort-icon sort-none'> </span>;
      }
    };
    const handleToggle = isOpen => {
      if (this.props.handleHeaderDropdownToggle) {
        this.props.handleHeaderDropdownToggle(isOpen);
      }
    };

    return (
      <Dropdown
        id={id}
        className='tableHeaderDropdown'
        onSelect={(value, event) =>
          this.headerDropdownSelectionHandler(id, value, event)
        }
        onToggle={isOpen => handleToggle(isOpen)}
      >
        <Dropdown.Toggle>
          {content}
          {renderSortIcon()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className={isSelected('asc')} eventKey='asc'>
            Sort Ascending
          </MenuItem>
          <MenuItem className={isSelected('desc')} eventKey='desc'>
            Sort Descending
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  headerDropdownSelectionHandler(id, value) {
    if (this.props.sort !== value) {
      // handleSort is given to us by the parent table, use it since it manages each column's sort prop
      this.props.handleSort(value, this.props.dataField);
    }
  }

  handleMouseDown(event) {
    this.props.setResizing(true, this, event);
  }

  render() {
    const id = this.props.tableId ? this.props.tableId : shortid.generate();
    const menuId = `${id}_${this.props.dataField}_menu`;
    let { children } = this.props;

    if (this.props.dataSort) {
      children = this.renderSortableHeader(menuId, this.props.children);
    }
    return (
      <TableHeaderColumn
        {...this.props}
        sortIndicator={false}
        ref={col => {
          this.backingColumn = col;
        }}
        isOnlyHead
      >
        {this.props.resizable ? (
          <div className='resizable'>
            <div className='resize-content'>{children}</div>
            <div
              className='resize-handle'
              onMouseDown={event => this.handleMouseDown(event)}
              ref={el => {
                this.resizeHandle = el;
              }}
            />
          </div>
        ) : (
          children
        )}
      </TableHeaderColumn>
    );
  }
}

TableColumn.propTypes = {
  ...TableHeaderColumn.propTypes,
  resizable: PropTypes.bool,
  minWidth: PropTypes.string,
  handleSort: PropTypes.func,
  tableId: PropTypes.string,
};
TableColumn.defaultProps = {
  minWidth: '70',
};

export default TableColumn;
