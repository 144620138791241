import { Component } from 'react';
import FormAutoSave from '../../components/FormUtils/FormAutoSave';
import { Form } from '@sugar-discover/react-bootstrap-wrapper';
import {
  Field as UntypedField,
  Form as FormController,
} from 'react-final-form';
import { FormControlAdapter } from '../../components/FormUtils/FormControlAdapters';
import LoadingButton from '../../common/widgets/LoadingButton';
import { validatePhoneNumber } from '../../common/Util';
import { compose } from 'react-recompose';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { messages } from '../../i18n';
import { IUserInfo } from '../interfaces';
import { AccountMutation, AccountQuery } from '../graphql';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Field: any = styled(UntypedField)(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    input[type='text']:not(:disabled) {
      background-color: ${ContentBackground};
    }
  `,
);

interface ISettingsNotificationsProps {
  updateUser: any;
  userInfo: IUserInfo;
}

interface ISettingsNotficationsState {
  loading?: boolean;
}

class UnconnectedSettingsNotifications extends Component<
  ISettingsNotificationsProps,
  ISettingsNotficationsState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit(values, valid) {
    if (valid && !isEqual(this.props.userInfo, values)) {
      this.props.updateUser(values);
    }
  }

  handleSaveButton() {
    this.setLoading(true);
    setTimeout(() => {
      this.setLoading(false);
    }, 1000);
  }

  setLoading(val) {
    this.setState(() => {
      return { loading: val };
    });
  }

  render() {
    const { userInfo } = this.props;

    return (
      <div className='settings-notifications'>
        <FormController
          onSubmit={this.onSubmit.bind(this)}
          initialValues={userInfo}
          render={({ invalid, errors }) => {
            return (
              <Form className='settings-notifications__form form--default'>
                <p className='settings__text'>
                  {messages.formatString(
                    (messages.administration as any).customizeNotifications,
                    messages.nonTranslated.discover,
                  )}
                </p>
                <p className='settings-notifications__headline settings__text--headline'>
                  {messages.account.mobileNotificationsInfo}
                </p>
                <Field
                  id='phone'
                  label=''
                  name='phoneNumber'
                  type='tel'
                  autoComplete='tel-national'
                  countries={['US']}
                  convertToNational
                  showCountrySelect={false}
                  className={classnames('phone-number', 'close-margin', {
                    'has-error': errors.phoneNumber,
                  })}
                  validate={value => !validatePhoneNumber(value)}
                  component={FormControlAdapter}
                />
                <p className='settings__text--small med-margin'>
                  {messages.account.optInForNotifications}
                </p>
                <LoadingButton
                  block
                  loading={this.state.loading} // faux
                  loadingText={messages.account.saving}
                  disabled={invalid}
                  bsStyle='primary'
                  onClick={this.handleSaveButton.bind(this)}
                >
                  {messages.account.savePhoneNumber}
                </LoadingButton>
                <FormAutoSave save={this.onSubmit.bind(this)} />
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export const SettingsNotifications = compose(
  AccountQuery,
  AccountMutation,
)(UnconnectedSettingsNotifications);
