import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Fonts from './fonts';
import { SortDirection } from 'react-virtualized';
import { isEqual, isEmpty } from 'lodash';
import classnames from 'classnames';
import { compose, branch, renderNothing } from 'react-recompose';
import { SortIndicator } from '../../icons';

export const TableToolBar = styled.div(
  ({
    theme: { darkMode, colors: { ContentBackground, MediumBlue } = {} } = {},
  }) => css`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background: ${darkMode ? MediumBlue : ContentBackground};
    padding: 16px;
    column-gap: 10px;
  `,
);

export const HeaderCell = styled.div`
  font-size: 14px;
  font-weight: ${Fonts.MediumFontWeight};
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 12px 0;

  &:not(:hover) .unsorted-sort-indicator {
    opacity: 0;
  }
  -webkit-user-select: none;
  user-select: none;
`;

export const HorizontalCell = styled.div(
  ({ error, theme: { colors: { TableTextErrorColor } = {} } = {} }) => css`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: ${Fonts.LightFontWeight};
    ${error ? `color: ${TableTextErrorColor};` : ''}
  `,
);

export const HorizontalCellInteractive = styled(HorizontalCell)`
  cursor: pointer;
  height: 100%;
  align-items: center;
`;

export const VerticalCell = styled(HorizontalCell)`
  flex-direction: column;
  align-items: flex-start;
`;

export const VerticalCellInteractive = styled(VerticalCell)`
  cursor: pointer;
  height: 100%;
  justify-content: center;
`;

export const VerticalCellInteractiveName = styled(VerticalCellInteractive)`
  color: ${({ theme }) => theme?.darkMode && theme?.colors?.ActiveLinkText};
`;

// don't render anything if there is not a child to display.
export const SubCell = compose(
  branch(props => isEmpty(props.children), renderNothing),
)(
  styled.div(
    ({ theme: { colors: { TableRowSecondaryTextColor } = {} } = {} }) => css`
      font-size: 12px;
      margin-top: 8px;
      color: ${TableRowSecondaryTextColor};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
  ),
);

const StyledSortIndicator = styled(SortIndicator)`
  margin-left: 8px;
`;

export const HeaderRenderer = ({ label }) => {
  return <HeaderCell>{label}</HeaderCell>;
};

export const HeaderRendererWithSort = ({ label, dataKey, sortDirection }) => {
  return (
    <HeaderCell>
      {label}
      <StyledSortIndicator
        sortDirection={sortDirection[dataKey]}
        className={classnames(
          'sort-indicator',
          'sorted',
          isEqual(sortDirection[dataKey], SortDirection.ASC) ? 'asc' : 'desc',
        )}
      />
    </HeaderCell>
  );
};
