import { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Form } from '@sugar-discover/react-bootstrap-wrapper';
import {
  Field as UntypedField,
  Form as UntypedFormController,
} from 'react-final-form';
import { isEqual, noop } from 'lodash';
import { FormControlAdapter } from '../../components/FormUtils';
import { compose, withProps, withState } from 'react-recompose';
import { messages } from '../../i18n';
import { InfoPanel } from '../../components/ui/panels';
import { IUserInfo } from '../interfaces';
import {
  AccountMutation,
  AccountQuery,
  IAccountMutationOutputProps,
  IAccountQueryProps,
} from '../graphql';
import Account from '../../common/redux/actions/AccountActions';
import { isAdmin } from '../../common/redux/selectors/AccountSelectors';
import _ from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Field: any = styled(UntypedField)(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    input[type='text']:not(:disabled) {
      background-color: ${ContentBackground};
    }
  `,
);
const FormController: any = UntypedFormController;

interface ISettingsProfileGraphqlProps
  extends IAccountMutationOutputProps,
    IAccountQueryProps {}

interface ISettingsProfileProps extends ISettingsProfileGraphqlProps {
  autoFocusField?: string;
  refreshUser(userInfo: IUserInfo);
  disabled: boolean;
  externalManagementUrl: string;
}

interface ISettingsProfileState {
  modifiedUser: IUserInfo;
  profileImageURL: string;
}

export class UnconnectedSettingsProfile extends Component<
  ISettingsProfileProps,
  ISettingsProfileState
> {
  form;

  static defaultProps = {
    autoFocusField: '',
    userInfo: {},
    updateUser: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      modifiedUser: props.userInfo,
      profileImageURL: props.currentUser.profileImageURL,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.autoFocusField)) {
      return;
    }

    try {
      const node: any = ReactDOM.findDOMNode(this);
      node.querySelector(`#${this.props.autoFocusField}`).focus();
    } catch (e) {
      console.warn('Failed to fetch DOM node', e);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.userInfo, prevProps.userInfo)) {
      this.setState({ modifiedUser: this.props.userInfo });
    }
  }

  onFormChange(values, valid) {
    if (valid && !isEqual(this.state.modifiedUser, values)) {
      const modifiedUser = {
        ...this.state.modifiedUser,
        givenName: values.givenName,
        surname: values.surname,
        title: values.title,
        phoneNumber: values.phoneNumber,
      };
      this.setState({ modifiedUser });
    }
  }

  render() {
    const { disabled, externalManagementUrl } = this.props;

    return (
      <div className='settings-profile'>
        {disabled && (
          <InfoPanel>
            {messages.formatString(messages.account.updatesAtLink, {
              link: (
                <a
                  target='_blank'
                  href={externalManagementUrl}
                  rel='noreferrer'
                >
                  {messages.nonTranslated.cloudSettings}
                </a>
              ),
            })}
          </InfoPanel>
        )}
        <FormController
          onSubmit={this.onFormChange.bind(this)}
          initialValues={this.state.modifiedUser}
          ref={r => {
            this.form = r?.form;
          }}
          render={() => {
            return (
              <Form className='settings-profile__form form--default'>
                <Field
                  id='first-name'
                  label={messages.account.firstName}
                  name='givenName'
                  autoComplete='fname'
                  className={'inline first-name'}
                  component={FormControlAdapter}
                  disabled
                />
                <Field
                  id='last-name'
                  label={messages.account.lastName}
                  name='surname'
                  autoComplete='lname'
                  className={'inline last-name'}
                  component={FormControlAdapter}
                  disabled
                />
                <Field
                  label={`${messages.account.email}*`}
                  name='email'
                  type='email'
                  autoComplete='email'
                  disabled
                  component={FormControlAdapter}
                />
                <Field
                  id='title'
                  label={messages.account.jobTitle}
                  name='title'
                  autoComplete='title'
                  placeholder={messages.account.jobTitlePlaceholder}
                  component={FormControlAdapter}
                  disabled
                />
                <Field
                  id='phone'
                  label={messages.account.phoneNumber}
                  name='phoneNumber'
                  type='tel'
                  autoComplete='tel-national'
                  countries={['US']}
                  convertToNational
                  showCountrySelect={false}
                  component={FormControlAdapter}
                  disabled
                />
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshUser: user => {
      dispatch(Account.setCurrentUser(user));
    },
  };
};

const mapStateToProps = state => {
  return {
    currentUser: state.account.currentUser,
    isAdmin: isAdmin(state.account),
    externalManagementUrl:
      state?.account?.currentUser?.idm?.accountManagementUrl,
  };
};

export const SettingsProfile = compose(
  connect(mapStateToProps, mapDispatchToProps),
  AccountQuery,
  AccountMutation, // note AccountMutation consumes isAdmin, setSaving and refreshUser
  withState('saving', 'setSaving', false),
  withProps((props: any) => {
    return {
      autoFocusField: props.match ? props.match.params.formField : null,
    };
  }),
)(UnconnectedSettingsProfile);
