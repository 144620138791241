import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@sugar-discover/react-bootstrap-wrapper';
import classnames from 'classnames';
import { CancelIconBubble } from '../../Icons';
import noop from 'lodash/noop';

const styles = {
  root: {
    position: 'relative',
  },
  cancel: {
    position: 'absolute',
    top: 0,
    right: 8,
    height: '100%',
    cursor: 'pointer',
  },
};

class component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
    };
  }

  render() {
    let { className, value, ...otherProps } = this.props;
    value = !value ? '' : value;
    return (
      <div
        className={classnames(className, 'textfield-form-control')}
        style={styles.root}
      >
        <FormControl type='text' value={value} {...otherProps} />
        <CancelIconBubble
          onClick={this.props.onClear}
          className='textfield-form-control-cancel'
          style={{
            ...styles.cancel,
            display: value.length <= 0 ? 'none' : 'block',
          }}
        />
      </div>
    );
  }
}

component.propTypes = {
  value: PropTypes.string,
  onClear: PropTypes.func,
};

component.defaultProps = {
  value: '',
  onClear: noop,
};

export default component;
