import { styled } from '@mui/material/styles';
import { isEmpty, merge } from 'lodash';
import { useDiscoverTheme } from '../../../common/emotion';
import { CheckIcon, CloseIconSmall } from '../../../icons/icons';
import { CSSProperties } from 'react';

export const ClearIcon = styled(CloseIconSmall)`
  align-self: center;
  margin-left: 5px;
`;

export const SelectedCheck = styled(CheckIcon)`
  align-self: center;
  margin-right: 5px;
`;

export const useListSx = ({
  disableSearch = false,
  providedListSx = {},
  disableScroll = false,
}) => {
  const {
    darkMode,
    colors: { Gray10, DropdownButtonBackgroundColor } = {},
  } = useDiscoverTheme();

  return merge(
    {
      backgroundColor: darkMode ? DropdownButtonBackgroundColor : Gray10,
    },
    disableSearch
      ? {}
      : {
          paddingTop: '34px',
          maxHeight: '20rem',
          overflow: disableScroll ? 'hidden' : 'hidden scroll',
          '& li:first-of-type': {
            width: 'calc(100% - 2px)',
            position: 'fixed',
            zIndex: '2',
            top: '0',
          },
          '& li:first-of-type:before': {
            content: '""',
            height: '34px',
            width: 'calc(100% - 4px)',
            position: 'fixed',
            top: '0',
            left: '2px',
            backgroundColor: darkMode ? DropdownButtonBackgroundColor : Gray10,
          },
        },
    providedListSx,
  );
};

export const useHeaderSx = ({ providedHeaderSx, errorMessage }) => {
  const { colors: { DarkRed } = {} } = useDiscoverTheme();

  const headerSx = merge(
    !isEmpty(errorMessage)
      ? {
          borderColor: DarkRed,
          color: DarkRed,
        }
      : {},
    providedHeaderSx,
  );

  if (!isEmpty(errorMessage)) {
    headerSx.borderColor = DarkRed;
    headerSx.color = DarkRed;
  }

  return headerSx;
};

export const titleContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: '100%',
};

export const titleSpanStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
};

export const useTitleStyles = () => ({
  titleContainerStyle,
  titleSpanStyle,
});

export const useSearchableDropdownSx: () => {
  [key: string]: CSSProperties;
} = () => {
  const {
    colors: { ContentBackground, PrimaryColor } = {},
  } = useDiscoverTheme();

  return {
    searchMenuItemSx: {
      paddingLeft: '6px',
      paddingRight: '6px',
      '&:hover': {
        backgroundColor: ContentBackground,
      },
    },
    searchInputSx: {
      backgroundColor: ContentBackground,
      border: `1px solid ${PrimaryColor}`,
      padding: '6px',
      height: '28px',
      fontSize: '12px',
      width: '100%',
    },
    menuItemSx: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
};
