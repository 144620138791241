import { css } from '@emotion/react';
import { Component } from 'react';
import { compose } from 'react-recompose';
import {
  ControlLabel,
  Form,
  FormGroup,
} from '@sugar-discover/react-bootstrap-wrapper';
import { CorvanaCheckbox } from '../common/widgets';
import { InfoIcon } from '../icons/icons/icons.component';
import { isEmpty, isEqual } from 'lodash';
import { format } from 'libphonenumber-js';
import { Link, Redirect, withRouter } from 'react-router-dom';
// import SlackNotificationConfig from '../components/SlackNotificationConfig' // @NOTE: See note below
import { AccountMutation, AccountQuery } from './graphql';
import { messages } from '../i18n';

class Notifications extends Component {
  emailToggled(on) {
    const modifiedUser = { ...this.props.userInfo, emailNotifications: on };
    this.props.updateUser(modifiedUser);
  }

  smsToggled(on) {
    const modifiedUser = { ...this.props.userInfo, smsNotifications: on };
    this.props.updateUser(modifiedUser);
  }

  render() {
    const { userInfo } = this.props;
    return (
      <div className='notifications'>
        {isEqual(
          this.props.location.pathname,
          '/account/notifications/slack',
        ) && <Redirect to='/account/notifications' />}
        <p>
          {messages.formatString(
            messages.account.selectNotificationPreferences,
            messages.nonTranslated.discover,
          )}
        </p>
        <Form>
          {/* @NOTE: Slack has not been approved yet from Sugar IT. */}
          {/* Also @NOTE: Slack config in services/ui-service/app/frontend/js/views/SettingsNotifications/icon-dropdown.component.tsx */}
          {/* <FormGroup> */}
          {/*  <ControlLabel> */}
          {/*    Slack */}
          {/*    <OverlayTrigger placement='top' overlay={<Tooltip id='slackTooltip' />}> */}
          {/*      <InfoIcon width='14' height='14' className='info' /> */}
          {/*    </OverlayTrigger> */}
          {/*  </ControlLabel> */}
          {/*  <SlackNotificationConfig /> */}
          {/* </FormGroup> */}
          <FormGroup>
            <ControlLabel>
              {messages.account.sms}
              <InfoIcon
                css={css`
                  padding: 1em 0.5em;
                `}
                width='14'
                height='14'
                className='info'
              />
            </ControlLabel>
            {userInfo.phoneNumber && (
              <CorvanaCheckbox
                checked={userInfo.smsNotifications}
                onChange={e => this.smsToggled(e.currentTarget.checked)}
              >
                {messages.formatString(
                  messages.account.sendToPhoneWithLink,
                  <span className='phone-number-text'>
                    {format(userInfo.phoneNumber, 'US', 'National')}
                  </span>,
                )}
                <br />
                <span className='info'>{messages.account.optInForSms}</span>
              </CorvanaCheckbox>
            )}
            {isEmpty(userInfo.phoneNumber) && (
              <CorvanaCheckbox disabled>
                {messages.formatString(
                  messages.account.addNumberToReceiveNotifications,
                  <Link to={{ pathname: '/account/profile/phone' }}>
                    {messages.account.addPhoneNumber}
                  </Link>,
                )}
              </CorvanaCheckbox>
            )}
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  AccountQuery,
  AccountMutation,
)(Notifications);
