import { useResizeDetector } from 'react-resize-detector';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from 'react';
import _ from 'lodash';
import { DiscoverModalContainerContext } from '@sugar-discover/react-bootstrap-wrapper';

import {
  SlicerMenuContainer,
  SlicerDropdownToggle,
  SlicerWidgetContainer,
  SlicerTitle,
  StyledDropdownMenu,
  DropdownCaret,
  MobileDropdownCaret,
  SlicerShadowBackdrop,
  SlicerSearchMenuItem,
  SlicerComboDropdown,
  SlicerMenuItem,
  MobileBackButton,
  SelectAllItem,
  SlicerFooterMenuItem,
} from './slicer-widget.styles';
import { useSlicerReducer } from './slicer-widget.reducer';

import { SlicerTooManyItemsDialog } from './slicer-error-dialog';
import { SlicerTooltip } from './slicer-tooltip';
import { ItemCountSlug } from './item-count-slug';
import { SlicerButtonFooter } from './slicer-button-footer';
import { SlicerOptions } from './slicer-options';
import { SlicerSearchbox } from './slicer-searchbox';
import { NoFlex } from '../../../common/emotion';
import { CSSProperties } from '@mui/styles';
import {
  DASHLET_WIDTH_OFFSET,
  MOBILE_WIDTH_OFFSET,
  SlicerThemeProvider,
} from './common';
import { MAX_SLICER_WIDTH, MIN_SLICER_WIDTH } from './common';
import { useSelector } from 'react-redux';
import { useSlicerError } from './common/slicer-error.hook';
import { useViewportDimensions } from '../../../common/utilities/dimensions.hook';
import { TimestampSlicerOptions, useStatusString } from '../timestamp-slicer';
import { useTimestampSlicer } from './common/timestamp-slicer.hook';
import { LeftChevron } from '../../../icons';
import { useSlicerMobileBreadcrumbs } from '../timestamp-slicer/common/slicer-mobile-breadcrumbs.hook';
import { isIndeterminateStatus, isStatusValid, joinBreadcrumbs } from '../util';
import { SlicerSelectionColumn } from '../slicer-option';
import { messages } from '../../../i18n';

export const SlicerWidget = memo<{
  name;
  onToggle?;
  initialOpen?;
  className?;
  width?;
  attachnodeid?;
  vizId?;
}>(
  ({
    name,
    onToggle,
    initialOpen,
    className,
    attachnodeid,
    width: overrideWidth,
    vizId,
  }) => {
    const isMobile = useSelector((state: any) => state.main.isMobile);
    const isDashletMode = useSelector(
      (state: any) => state.dashlet.isDashletMode,
    );
    const { isTimestampSlicer } = useTimestampSlicer({
      name,
      vizId,
    });
    const {
      loading,
      options,
      reset,
      toggleOption,
      selectAll,
      selectOnly,
      clear,
      updateSearchInput,
      searchInput,
      hasChanged,
      noneSelected,
      isPaged,
      selectStatus,
      save,
      selectTimeFrame,
    } = useSlicerReducer(name, vizId);
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const id = `slicer-dropdown-${_.kebabCase(name)}`;
    const { width: windowWidth } = useViewportDimensions();
    const [buttonWidth, setButtonWidth] = useState(0);
    const [width, setWidth] = useState(0);
    const [open, setIsOpen] = useState(false);
    const {
      breadcrumbs,
      pushBreadcrumb,
      popBreadcrumb,
    } = useSlicerMobileBreadcrumbs();
    const isBreadcrumbsNotEmpty = useMemo(() => !_.isEmpty(breadcrumbs), [
      breadcrumbs,
    ]);
    const isOpen = open && !loading;
    const { onHideSlicerError, shouldShowSlicerError } = useSlicerError({
      loading,
      isPaged,
      isOpen,
      isTimestampSlicer,
    });
    const onResizeButtonWidth = useCallback(
      _width => {
        // When in mobile, we want all menu items to span the whole viewport,
        // but they should be no wider than 300 px in desktop mode.
        const offset = isDashletMode
          ? DASHLET_WIDTH_OFFSET
          : MOBILE_WIDTH_OFFSET;
        const maxWidth = isMobile ? windowWidth - offset : MAX_SLICER_WIDTH;
        const refWidth = _.isNumber(_width)
          ? Math.max(
              Math.min(Math.floor(_width + 2), maxWidth),
              MIN_SLICER_WIDTH,
            )
          : 0;
        if (refWidth - buttonWidth >= 3) {
          setButtonWidth(refWidth);
        }
      },
      [isMobile, isDashletMode, buttonWidth, windowWidth],
    );

    // We don't want to set the button width when the menu is open -- the closed
    // width should drive both the menu width and the width of the toggle when
    // in open state. This useEffect hook will ensure that the resize only happens
    // when the menu is closed.
    useEffect(() => {
      if (!(isOpen || isMobile) && buttonWidth) {
        setWidth(buttonWidth);
      } else if (isMobile) {
        const offset = isDashletMode
          ? DASHLET_WIDTH_OFFSET
          : MOBILE_WIDTH_OFFSET;
        setWidth(windowWidth - offset);
      }
    }, [buttonWidth, isOpen, isMobile, isDashletMode, windowWidth]);

    const rootElem = useContext(DiscoverModalContainerContext);
    const root = rootElem || document;

    const findById = useCallback(
      id => {
        return root.querySelector(`#${id}`);
      },
      [root],
    );

    useResizeDetector({ targetRef: buttonRef, onResize: onResizeButtonWidth });

    const memoizedWidth = useMemo(() => overrideWidth || width, [
      width,
      overrideWidth,
    ]);

    const menuId = `slicer-dropdown-menu-${_.kebabCase(name)}`;
    const setOpen = useCallback(
      open => {
        if (!open) {
          reset();
        }
        setIsOpen(open);
        if (_.isFunction(onToggle)) {
          onToggle(open, containerRef.current.offsetLeft);
        }
      },
      [onToggle, reset],
    );
    useEffect(() => {
      if (_.isBoolean(initialOpen) && initialOpen !== open) {
        setOpen(initialOpen);
        if (isBreadcrumbsNotEmpty) {
          popBreadcrumb(_.last(breadcrumbs));
        }
      }
    }, [
      initialOpen,
      setOpen,
      open,
      isBreadcrumbsNotEmpty,
      popBreadcrumb,
      breadcrumbs,
    ]);

    const modalId = `slicer-items-dialog-${_.kebabCase(name)}`;
    const menuStyle: CSSProperties = {
      overflowX: 'visible',
      overflowY: 'visible',
      pointerEvents: 'none',
    };

    const renderSlicerOptions = () => {
      const slicerOptionsProps = {
        slicerErrorVisible: shouldShowSlicerError,
        width: memoizedWidth - (isDashletMode ? 20 : 22),
        options,
        name,
      };

      if (isTimestampSlicer) {
        return (
          <TimestampSlicerOptions
            {...slicerOptionsProps}
            selectTimeFrame={selectTimeFrame}
            pushBreadcrumb={pushBreadcrumb}
            breadcrumbs={breadcrumbs}
            clear={clear}
            width={memoizedWidth - (isDashletMode ? 20 : 0)}
          />
        );
      }

      return (
        <SlicerOptions
          {...slicerOptionsProps}
          toggleOption={toggleOption}
          selectOnly={selectOnly}
        />
      );
    };

    const { timestampStatusString } = useStatusString({
      options,
      isTimestampSlicer,
    });

    const handleToggleDropdown = (val: boolean) => {
      popBreadcrumb(_.last(breadcrumbs));
      setOpen(val);
    };

    const handleSelectAll = () => {
      if (selectStatus === 'none') {
        selectAll();
      } else {
        clear();
      }
    };

    const selectAllLabel = isStatusValid(selectStatus)
      ? messages.deselectAll
      : messages.selectAll;

    return (
      <SlicerWidgetContainer
        containerRef={containerRef}
        className={className}
        width={memoizedWidth}
      >
        <SlicerThemeProvider isOpen={isOpen} width={memoizedWidth}>
          <SlicerTooltip
            options={
              isTimestampSlicer
                ? [{ option: timestampStatusString, isSelected: true }]
                : options
            }
            isOpen={isOpen}
            loading={loading}
          >
            <span id='keeps-tooltip-working'>
              <SlicerComboDropdown
                id={id}
                style={{ width: memoizedWidth + 2 }}
                onSelect={option => {
                  toggleOption(option);
                }}
                onToggle={(open, event) => {
                  if (
                    !open &&
                    (!event?.target ||
                      isMobile ||
                      _.some(
                        [
                          // Ignore any clicks to the menu or the modal
                          findById(id).parentNode,
                          findById(modalId),
                        ],
                        node => node?.contains(event.target as any),
                      ))
                  ) {
                    // only concerned with outside clicks
                    return;
                  }
                  setOpen(open);
                }}
                open={isOpen}
              >
                <SlicerDropdownToggle
                  ref={() => {
                    const domNode = findById(id);
                    if (buttonRef.current === null) {
                      onResizeButtonWidth(domNode?.clientWidth);
                    }
                    buttonRef.current = domNode;
                  }}
                  disabled={loading}
                  onClick={() => handleToggleDropdown(!isOpen)}
                  noCaret
                >
                  {isMobile && isBreadcrumbsNotEmpty && (
                    <MobileBackButton
                      onClick={e => {
                        e.stopPropagation();
                        popBreadcrumb(_.last(breadcrumbs));
                      }}
                    >
                      <LeftChevron size={16} />
                    </MobileBackButton>
                  )}
                  <SlicerTitle className='title'>
                    {isTimestampSlicer && isMobile && isBreadcrumbsNotEmpty
                      ? joinBreadcrumbs(name, breadcrumbs)
                      : name}
                  </SlicerTitle>
                  <NoFlex>
                    {!isOpen && (
                      <ItemCountSlug
                        options={options}
                        timestampStatusString={timestampStatusString}
                      />
                    )}
                  </NoFlex>
                  {isMobile ? <MobileDropdownCaret /> : <DropdownCaret />}
                </SlicerDropdownToggle>
                <StyledDropdownMenu
                  attachnodeid={attachnodeid}
                  offsetTop={-2}
                  style={menuStyle}
                  open={isOpen}
                  anchorId={id}
                  className={`combo-dropdown__dropdown-menu-no-scroll slicer-widget-dropdown`}
                  shouldUpdate={_.constant(true)}
                  isMobile
                >
                  {!isMobile && <SlicerShadowBackdrop />}
                  <SlicerMenuContainer id={menuId}>
                    {!isTimestampSlicer && (
                      <SlicerSearchMenuItem disabled draggable={false}>
                        <SlicerSearchbox
                          value={searchInput}
                          isOpen={isOpen}
                          onChange={updateSearchInput}
                        />
                      </SlicerSearchMenuItem>
                    )}
                    {isOpen && !isTimestampSlicer && (
                      <SelectAllItem role='presentation'>
                        <SlicerSelectionColumn
                          isHovered={false}
                          option={selectAllLabel}
                          selected={isStatusValid(selectStatus)}
                          labelRef={null}
                          onSelect={handleSelectAll}
                          width={'100%'}
                          name={messages.selectAll}
                          indeterminate={isIndeterminateStatus(selectStatus)}
                        />
                      </SelectAllItem>
                    )}
                    <SlicerMenuItem
                      draggable={false}
                      onDragStart={event => {
                        event.preventDefault();
                        return false;
                      }}
                      className='slicer-widget-menu-item'
                      isTimestampSlicer={isTimestampSlicer}
                    >
                      <SlicerTooManyItemsDialog
                        modalId={modalId}
                        onHide={onHideSlicerError}
                        shouldShow={shouldShowSlicerError}
                        name={name}
                      />
                      {renderSlicerOptions()}
                    </SlicerMenuItem>

                    <SlicerFooterMenuItem disabled>
                      <SlicerButtonFooter
                        width={isDashletMode && memoizedWidth}
                        name={name}
                        onCancel={() => handleToggleDropdown(false)}
                        onSave={() => {
                          handleToggleDropdown(false);
                          save();
                        }}
                        saveDisabled={!hasChanged || noneSelected}
                      />
                    </SlicerFooterMenuItem>
                  </SlicerMenuContainer>
                </StyledDropdownMenu>
              </SlicerComboDropdown>
            </span>
          </SlicerTooltip>
        </SlicerThemeProvider>
      </SlicerWidgetContainer>
    );
  },
);
