import styled from '@emotion/styled';

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.65rem;
  span: {
    display: block;
  }
`;

export const ProfileDropdownRoot = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
