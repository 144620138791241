import { useOutsideClick } from './click-outside-listener.hook';

export const ClickOutsideListener = ({
  isChildrenVisible,
  onClickAway,
  children,
}) => {
  const subMenuRef = useOutsideClick<HTMLDivElement>(
    () => onClickAway(),
    isChildrenVisible,
  );

  return (
    <div ref={subMenuRef} style={{ width: '100%' }}>
      {children}
    </div>
  );
};
