import ColumnSvgIcon from '../../../../images/sdd/viz/visualization-selection-column.svg';
import StackSvgIcon from '../../../../images/sdd/viz/visualization-selection-stacked.svg';
import ForecastSvgIcon from '../../../../images/sdd/viz/visualization-selection-forecast-changes.svg';
import LineSvgIcon from '../../../../images/sdd/viz/visualization-selection-line.svg';
import StackBarSvgIcon from '../../../../images/sdd/viz/visualization-selection-stacked-bar.svg';
import PivotSvgIcon from '../../../../images/sdd/viz/visualization-selection-pivot-table.svg';
import ColLineSvgIcon from '../../../../images/sdd/viz/visualization-selection-combo.svg';
import StackLineSvgIcon from '../../../../images/sdd/viz/visualization-selection-stacked-line.svg';
import FunnelSvgIcon from '../../../../images/sdd/viz/visualization-selection-funnel.svg';

export const IconByChartType = {
  pivot: <PivotSvgIcon />,
  column_line: <ColLineSvgIcon />,
  line: <LineSvgIcon />,
  area: <LineSvgIcon />,
  area_line: <LineSvgIcon />,
  bar: <ColumnSvgIcon />,
  stack: <StackSvgIcon />,
  stack_bar: <StackBarSvgIcon />,
  stack_line: <StackLineSvgIcon />,
  funnel: <FunnelSvgIcon />,
  pipeline_changes: <ForecastSvgIcon />,
};
