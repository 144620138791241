import { css } from '@emotion/react';

export const mobileAccountSettingsStyles = ({
  theme: {
    colors: { ContentBackground },
  },
}) => css`
  .mobile-account-settings,
  .mobile-account-settings > div {
    height: 100%;
  }
  .mobile-account-settings__screen {
    display: grid;
    grid-template-rows: 52px 1fr;
    height: 100%;
  }
  .mobile-account-settings__navigation {
    overflow: hidden;
  }
  .mobile-account-settings__content {
    position: relative;
  }
  .mobile-account-settings__navigation > div,
  .mobile-account-settings__content > div {
    display: inline;
  }
  .mobile-account-settings__content > div > div {
    position: absolute;
    width: 100vw;
    overflow: auto;
  }
  /**/
  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
  /**/
  .slide-forward-enter {
    transform: translate3d(100vw, 0, 0);
  }
  .slide-forward-enter.slide-forward-enter-active {
    transform: translate3d(0vw, 0, 0);
    transition: transform 500ms cubic-bezier(0.1, 0.7, 0.1, 1);
  }
  .slide-forward-exit {
    transform: translate3d(0vw, 0, 0);
  }
  .slide-forward-exit.slide-forward-exit-active {
    transform: translate3d(-100vw, 0, 0);
    transition: transform 500ms cubic-bezier(0.1, 0.7, 0.1, 1);
  }
  /**/
  .slide-back-enter {
    transform: translate3d(-100vw, 0, 0);
  }
  .slide-back-enter.slide-back-enter-active {
    transform: translate3d(0vw, 0, 0);
    transition: transform 500ms cubic-bezier(0.1, 0.7, 0.1, 1);
  }
  .slide-back-exit {
    transform: translate3d(0vw, 0, 0);
  }
  .slide-back-exit.slide-back-exit-active {
    transform: translate3d(100vw, 0, 0);
    transition: transform 500ms cubic-bezier(0.1, 0.7, 0.1, 1);
  }
  .settings__text {
    padding: 24px 0;
  }
  .settings__tabs .nav-tabs {
    margin-bottom: 20px;
  }
  .settings__tabs ul li {
    border-bottom: none;
  }
  .settings__tabs ul li a {
    line-height: 15px;
    padding: 8px 48px 8px 8px;
    cursor: default;
    border: none;
  }
  .settings__tabs ul li.active a {
    border: none;
    box-sizing: border-box;
  }
  .settings__tabs ul li.active a:focus {
    border: none;
  }
  .settings__tabs ul li.active a:hover {
    background-color: ${ContentBackground};
    border: none;
  }
  .settings__tabs ul li a:hover {
    background-color: ${ContentBackground};
  }
  .settings__tabs ul li.active a::before {
    content: none;
  }
  .mobile-account-settings__content {
    overflow: scroll;
  }
`;
