/* global graphqlEndpoint */
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import { client } from './common/ApolloClient';
import store, { init } from './common/redux';
import fetch from 'isomorphic-fetch';

import { Provider, connect } from 'react-redux';
import { Global } from '@emotion/react';
import { compose, pure } from 'react-recompose';

import { onWindowBlur, onKeyDown, onKeyUp } from './sdd.util';

import {
  mainStyles,
  DiscoverEmotionThemeProvider,
  useDiscoverTheme,
} from './common/emotion';

import { getConfig } from './common/global-discover-config';
import { WorkerRefContextProvider } from './common/utilities/web-worker/web-worker.context';
import { PortalProvider } from './common/utilities/portal';
import { ErrorBoundary } from 'react-error-boundary';
import {
  FullScreenFallBack,
  LogErrorBoundary,
} from './components/error-boundary';
import { FeatureFlagProvider } from './common/utilities/feature-flag/feature-flag.provider';

const {
  IDM_TOKEN,
  IS_GRAPHIQL,
  SUPPRESS_PROP_WARNINGS,
  STRICT_MODE = false,
} = getConfig();

if (SUPPRESS_PROP_WARNINGS) {
  const consoleError = console.error.bind(console);

  console.error = (message, ...rest) => {
    if (
      message.toString().includes('Warning: Failed prop type:') ||
      message.toString().includes('Warning: Failed %s type: %s%s') ||
      message
        .toString()
        .includes(
          'Warning: React does not recognize the `%s` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `%s` instead. If you accidentally passed it from a parent component, remove it from the DOM element.%s',
        )
    ) {
      return;
    }
    consoleError(message, ...rest);
  };
}

init();

const GlobalStyles = compose(
  connect(state => {
    let {
      main: { mobileMaxWidth, appUrl, isMobile },
      dashlet: { isDashletMode },
    } = state;
    // @NOTE see getMobileMaxWidth selector
    mobileMaxWidth = isDashletMode ? 10000 : mobileMaxWidth;
    return { mobileMaxWidth, appUrl, isDashletMode, isMobile };
  }),
  pure,
)(({ mobileMaxWidth, appUrl, isDashletMode, isMobile }) => {
  const theme = useDiscoverTheme();
  const styles = mainStyles({
    mobileMaxWidth: `${mobileMaxWidth}px`,
    theme,
    appUrl,
    isDashletMode,
    isMobile,
  });
  return <Global styles={styles} />;
});

function renderApp() {
  function graphQLFetcher(graphQLParams) {
    return fetch(window.location.origin + graphqlEndpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-IDM-ACCESS-TOKEN': IDM_TOKEN ?? '',
      },
      body: JSON.stringify(graphQLParams),
      credentials: 'include',
    }).then(response => response.json());
  }
  if (IS_GRAPHIQL) {
    // There is a bug with webpack 4 production mode & graphiql where if the query it wants to load initially can't be parsed,
    // it will give a white screen of death. This is a hacky way to prevent that, we just set the query to something we know is good.
    // The user can just use their history to get back to previous queries
    const query =
      'query {\n' +
      '  currentUser {\n' +
      '    fullName\n' +
      '    email\n' +
      '  }\n' +
      '}';

    import('graphiql').then(mod => {
      const container = document.getElementById('main');
      const root = createRoot(container);
      const GraphiQL = mod.default;
      root.render(<GraphiQL fetcher={graphQLFetcher} query={query} />);
    });
  } else {
    client.setDispatch(store.dispatch);
    const container = document.getElementById('main');
    const root = createRoot(container);
    const AppTree = (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <FeatureFlagProvider>
            <DiscoverEmotionThemeProvider>
              <WorkerRefContextProvider>
                <PortalProvider>
                  {!IS_GRAPHIQL && <GlobalStyles />}
                  <ErrorBoundary
                    fallbackRender={FullScreenFallBack}
                    onError={LogErrorBoundary}
                  >
                    <App />
                  </ErrorBoundary>
                </PortalProvider>
              </WorkerRefContextProvider>
            </DiscoverEmotionThemeProvider>
          </FeatureFlagProvider>
        </ApolloProvider>
      </Provider>
    );
    const rootComponentTree = STRICT_MODE ? (
      <StrictMode>{AppTree}</StrictMode>
    ) : (
      AppTree
    );
    root.render(rootComponentTree);

    // This avoids going back a page when delete is clicked during deletion of steps
    try {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('blur', onWindowBlur);
    } catch (err) {
      console.log('Failed to remove event listeners', err);
    }
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    window.addEventListener('blur', onWindowBlur);

    /* eslint-enable */
  }
}

renderApp();

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    renderApp();
  });
}
