import { useState, useMemo, useEffect, useCallback, memo } from 'react';
import Axis from '../../../common/d3/Axis';
import Util from '../../../common/Util';
import { AxesLabel } from './axes-label.component';
import { useVerticalAxisWrapper } from './base-cartesian-chart.hook';
import { LabelToolTip } from './base-cartesian-chart.styles';
import _ from 'lodash';

export const SecondaryVerticalAxis = memo<{
  getOffscreenDimensionsAsync;
  secondaryFormatter;
  customFormatProps;
  plot;
  numTicks;
  chartWidth;
  offsetForLeftAxis;
  i18nPrefs;
  scrolling;
  onScroll;
  showAxesToolTipSecondary;
  hideAxesToolTipSecondary;
  chartHeight;
  showAxisToolTipSecondary;
  isMobile;
  queryId;
  querySort;
  scrollPctTop;
}>(
  ({
    getOffscreenDimensionsAsync,
    secondaryFormatter,
    plot,
    numTicks,
    chartWidth,
    offsetForLeftAxis,
    i18nPrefs,
    scrollPctTop: scrollPct,
    scrolling,
    onScroll,
    showAxesToolTipSecondary,
    hideAxesToolTipSecondary,
    chartHeight,
    showAxisToolTipSecondary,
    isMobile,
    queryId,
    querySort,
  }) => {
    const [labelSize, setLabelSize] = useState(0);
    const tickValues = plot.getTicks(numTicks);
    const label = plot.getYAxisLabel();
    const scale = plot.getYScale();
    useEffect(() => {
      setLabelSize(Util.calcTextWidth(label));
    }, [label]);
    const tickFormat = useCallback(
      d => {
        // purposefully hiding custom formatting
        return secondaryFormatter.formatSmall(d, i18nPrefs);
      },
      [i18nPrefs, secondaryFormatter],
    );
    const { scrollTop } = useVerticalAxisWrapper({
      scrollPct,
      getOffscreenDimensionsAsync,
    });
    const scrollCb = useCallback(
      (scrollPctLeft, scrollPctTop) => {
        onScroll(0, scrollPctTop);
      },
      [onScroll],
    );
    const yAxisLabel = useMemo(() => plot.getYAxisLabel(), [plot]);
    return (
      <Axis
        key='second-axis'
        queryId={queryId}
        querySort={querySort}
        scale={scale}
        tickValues={tickValues}
        ticks={numTicks} // enforces number of ticks when primary chart is not in play
        tickSizeInner={0}
        orient={'right'}
        transform={`translate(${chartWidth + offsetForLeftAxis}, 0)`}
        tickFormat={tickFormat}
        scrolling={scrolling}
        scrollable={'true'}
        scrollPct={scrollPct}
        scrollTop={scrollTop}
        onScroll={scrollCb}
      >
        <AxesLabel
          onMouseEnter={showAxesToolTipSecondary}
          onMouseLeave={hideAxesToolTipSecondary}
          width={chartHeight}
          rotate={90}
          x={0}
          y={isMobile ? -70 : -100}
          label={yAxisLabel}
          isMobile={isMobile}
        />
        <LabelToolTip
          show={showAxisToolTipSecondary}
          height={44}
          width={500}
          arrow='right'
          xPos={-labelSize - 10}
          y={chartHeight / 2 + 33}
        >
          {label}
        </LabelToolTip>
      </Axis>
    );
  },
);
