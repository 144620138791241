import { css } from '@emotion/react';
import { useCallback, useMemo, memo } from 'react';
import { List } from 'react-virtualized';
import { ISlicerOption } from '../../interfaces';
import { SlicerOption } from '../../slicer-option';
import { SCROLL_ROW_HEIGHT, useSlicerTheme } from '../common';
import _ from 'lodash';
import { useSlicerDimensions } from '../common/slicer-dimensions.hook';
import { messages } from '../../../../i18n';

export interface ISlicerOptionsCommonProps {
  width: number;
  options: ISlicerOption[];
  name: string;
  slicerErrorVisible: boolean;
  isDashletTag?: boolean;
}

export interface ISlicerOptionsProps extends ISlicerOptionsCommonProps {
  toggleOption: (option: string) => any;
  selectOnly: (option: string) => any;
}

export const SlicerOptions = memo<ISlicerOptionsProps>(
  ({
    width: propsWidth,
    options,
    name,
    toggleOption,
    slicerErrorVisible,
    selectOnly,
    isDashletTag,
  }) => {
    const { isMobile } = useSlicerTheme();

    const { height, width } = useSlicerDimensions({
      optionsLength: options.length,
      slicerErrorVisible,
      propsWidth,
      isDashletTag,
    });

    const rowWidthStyle = useMemo(
      () => css({ width: `${width}px !important` }),
      [width],
    );

    const RowRenderer = useCallback(
      ({ key, index, style }) => {
        const { option, isSelected } = options[index];
        return (
          <SlicerOption
            isMobile={isMobile}
            style={style}
            width={width}
            css={rowWidthStyle}
            key={key}
            name={name}
            option={
              _.isEmpty(option) ? `(${messages.slicer.emptyOption})` : option
            }
            selected={isSelected}
            onSelect={() => toggleOption(option)}
            onSelectOnly={() => selectOnly(option)}
            disableOnly={selectOnly == _.noop}
          />
        );
      },
      [options, isMobile, width, rowWidthStyle, name, toggleOption, selectOnly],
    );
    return (
      <List
        width={propsWidth}
        height={height}
        style={{ overflowX: 'hidden', maxHeight: height }}
        rowCount={options.length}
        rowHeight={SCROLL_ROW_HEIGHT}
        rowRenderer={RowRenderer}
      />
    );
  },
);
