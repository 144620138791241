import { css } from '@emotion/react';
import styled from '@emotion/styled';
import InputBase from '@mui/material/InputBase';

export const SlicerTextSearchField = styled(InputBase)(
  ({
    theme: {
      isDashletMode,
      darkMode,
      colors: {
        MediumBlue,
        ContentText,
        SugarGrayPalette: { Gray30 },
        Strokes,
      } = {} as any,
    } = {} as any,
  }: any) => css`
    width: 100%;
    padding: 8px 10px;

    input {
      font-weight: 300;
      font-style: inherit;
      font-size: 12px ${isDashletMode && ' !important'};
      color: inherit;
      background-color: inherit;
      height: 25px;
      padding: 8px 4px;
      border-radius: 2px;
      border: 1px solid ${darkMode ? Strokes : Gray30};
      transition: border linear 0.2s, box-shadow linear 0.2s;
      box-sizing: border-box;

      &:focus {
        border-color: ${darkMode ? Strokes : MediumBlue};
        box-shadow: none;
        color: ${ContentText};
      }
    }
  `,
) as any;
