import { Component } from 'react';
import _, { noop, isNil, capitalize } from 'lodash';
import { Route, withRouter, Redirect } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation';
import { SettingsAccount } from '../settings-account';
import { SettingsProfile } from '../settings-profile';
import { SettingsNotifications } from '../settings-notifications';
import { SettingsI18n } from '../settings-i18n';
import { Segue } from './utils';
import { compose, withProps } from 'react-recompose';
import { messages } from '../../i18n';
import { URLs } from '../../common/Urls';

const settings = {
  account: SettingsAccount,
  profile: SettingsProfile,
  notifications: SettingsNotifications,
  locale: SettingsI18n,
};

const homePanel = 'account';
const forwardDirection = 'forward';
const backDirection = 'back';

interface IMobileAccountSettingsProps {
  back: any;
  history: any;
  location: any;
  match: any;
}

interface IMobileAccountSettingsState {
  direction: any;
}

export class UnconnectedMobileAccountSettings extends Component<
  IMobileAccountSettingsProps,
  IMobileAccountSettingsState
> {
  constructor(props) {
    super(props);
    this.state = {
      direction: forwardDirection,
    };
  }

  _handleGoBack() {
    this.setState(() => {
      return {
        direction: backDirection,
      };
    });
    this.props.history.push('/account');
    setTimeout(() => {
      this.setState(() => {
        return {
          direction: forwardDirection,
        };
      });
    }, 700);
  }

  onClose() {
    this.props.history.push('/');
  }

  render() {
    const { location } = this.props;

    return (
      <div className='mobile-account-settings'>
        <div className='mobile-account-settings__screen'>
          <div className='mobile-account-settings__navigation'>
            <Segue location={location} classNames='fade'>
              <Route
                path={`${this.props.match.url}/:tabURL?`}
                render={({ match }) => {
                  const panel = match.params.tabURL || homePanel;

                  return (
                    <HeaderNavigation
                      key={panel}
                      title={capitalize(_.get(messages.account, panel))}
                      left={({ LinkButton, Chevron }) => {
                        return panel === 'account' ? (
                          <LinkButton onClick={this.onClose.bind(this)}>
                            {messages.close}
                          </LinkButton>
                        ) : (
                          <LinkButton onClick={this._handleGoBack.bind(this)}>
                            <Chevron /> {messages.account.account}
                          </LinkButton>
                        );
                      }}
                    />
                  );
                }}
              />
            </Segue>
          </div>
          <div className='mobile-account-settings__content'>
            <Segue
              location={location}
              classNames={`slide-${this.state.direction}`}
              timeout={500}
            >
              <Route
                path={`${this.props.match.url}/:tabURL?`}
                render={props => {
                  const panel = props.match.params.tabURL || homePanel;
                  const PanelComponent = settings[panel];
                  if (panel === 'profile') {
                    return (
                      <Route
                        path={`${props.match.url}/:formField?`}
                        render={routeProps => (
                          <PanelComponent {...routeProps} />
                        )}
                      />
                    );
                  }
                  return !isNil(PanelComponent) ? (
                    <PanelComponent />
                  ) : (
                    <Redirect to={`${this.props.match.url}/profile`} />
                  );
                }}
              />
            </Segue>
          </div>
        </div>
      </div>
    );
  }
}

(UnconnectedMobileAccountSettings as any).defaultProps = {
  back: noop,
};

export const MobileAccountSettings = compose(
  withRouter,
  withProps(() => ({ back: () => URLs.goTo('/account') })),
)(UnconnectedMobileAccountSettings);
