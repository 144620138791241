import { useMemo, useState } from 'react';
import {
  ControlLabel,
  FormGroup,
  StyledCustomFormatModal,
  StyledDisplayFormatArea,
  StyledFormRow,
  StyledModalBody,
  StyledPreviewDiv,
} from './custom-format-modal.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../../ui';
import _ from 'lodash';
import { messages } from '../../i18n';
import { DATA_FORMATTER, negativeFormats } from '../../common';
import {
  ICustomFormatProps,
  ICustomNegativeFormat,
} from './custom-format-modal.interfaces';
import { FormInput } from '../../components/ui/form-input';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';
import { useDiscoverTheme } from '../../common/emotion/theme';
import { IUserInfo } from '../../account/interfaces';
import { useAccount } from '../../common/utilities/account';

interface IMenuItem extends ICustomNegativeFormat {
  key: string;
  value: string;
}

const defaultCustomFormatProps: ICustomFormatProps = {
  decimalPlaces: 0,
  negativeFormat: negativeFormats[0],
  prefixValue: '',
  suffixValue: '',
  includeSeparator: false,
  includeCurrency: false,
};

export const CustomFormatModal = ({
  onHide,
  onSubmit,
  customFormatProps = defaultCustomFormatProps,
  currencySymbol = '$',
}) => {
  customFormatProps = !_.isNil(customFormatProps)
    ? customFormatProps
    : defaultCustomFormatProps;
  const exampleValue = 1234;
  const previewValue = 1000;
  const menuItems: IMenuItem[] = _.map(negativeFormats, i => {
    return {
      ...i,
      key: `${i.pre}${i.post}`,
      value: `${i.pre}${exampleValue}${i.post}`,
    };
  });

  const {
    darkMode,
    colors: {
      PanelBorder,
      SugarGrayPalette: { Gray80, Gray30 },
    },
  } = useDiscoverTheme();

  const getMenuItem = (negVal: ICustomNegativeFormat) => {
    if (_.isNil(negVal)) {
      return menuItems[0];
    }
    const selectItem = _.find(menuItems, {
      key: `${negVal.pre}${negVal.post}`,
    });
    return !_.isNil(selectItem) ? selectItem : menuItems[0];
  };

  const [negativeFormat, setNegativeFormat] = useState(
    getMenuItem(customFormatProps?.negativeFormat) as IMenuItem,
  );

  const [decimalPlaces, setDecimalPlaces] = useState(
    customFormatProps.decimalPlaces,
  );
  const [prefixValue, setPrefixValue] = useState(customFormatProps.prefixValue);
  const [suffixValue, setSuffixValue] = useState(customFormatProps.suffixValue);
  const [includeSeparator, setIncludeSparator] = useState(
    customFormatProps.includeSeparator,
  );
  const [includeCurrency, setIncludeCurrency] = useState(
    customFormatProps.includeCurrency,
  );

  const { currentUser = {} as IUserInfo } = useAccount();
  const { i18nPrefs = {} } = currentUser;

  const formattedPreview = useMemo(
    () =>
      DATA_FORMATTER.CUSTOM.format(previewValue, i18nPrefs, {
        decimalPlaces,
        negativeFormat,
        prefixValue,
        suffixValue,
        includeSeparator,
        includeCurrency,
      }),
    [
      decimalPlaces,
      i18nPrefs,
      includeCurrency,
      includeSeparator,
      negativeFormat,
      prefixValue,
      suffixValue,
    ],
  );

  const negativeFormattedPreview = useMemo(
    () =>
      DATA_FORMATTER.CUSTOM.format(-1 * previewValue, i18nPrefs, {
        decimalPlaces,
        negativeFormat,
        prefixValue,
        suffixValue,
        includeSeparator,
        includeCurrency,
      }),
    [
      decimalPlaces,
      i18nPrefs,
      includeCurrency,
      includeSeparator,
      negativeFormat,
      prefixValue,
      suffixValue,
    ],
  );

  return (
    <StyledCustomFormatModal
      title={messages.customFormatModal.title}
      contentClassName='custom-format-dialog'
      buttonGroup={
        <ButtonContainer
          style={{
            marginTop: '0.8rem',
            marginBottom: '0.8rem',
            alignSelf: 'flex-end',
            marginRight: '1rem',
          }}
        >
          <SecondaryButton
            onClick={() => {
              onHide();
            }}
          >
            {messages.cancel}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              const cleanDecimal =
                !_.isNaN(decimalPlaces) && !_.isEmpty(decimalPlaces)
                  ? decimalPlaces
                  : 0;
              onSubmit({
                decimalPlaces: cleanDecimal,
                negativeFormat,
                prefixValue,
                suffixValue,
                includeSeparator,
                includeCurrency,
              });
            }}
          >
            {messages.ok}
          </PrimaryButton>
        </ButtonContainer>
      }
    >
      <StyledModalBody>
        <StyledFormRow>
          <FormGroup>
            <ControlLabel>
              {messages.customFormatModal.decimalPlaces}
            </ControlLabel>
            <FormInput
              defaultValue={decimalPlaces}
              type='number'
              onChange={e => {
                const val = +e.target.value;
                if (val > -1 && val < 100) {
                  setDecimalPlaces(val);
                }
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              {messages.customFormatModal.negativeValues}
            </ControlLabel>
            <SelectDropdown
              id={'negative-select'}
              title={negativeFormat.value}
              headerSx={{
                borderColor: darkMode ? Gray80 : PanelBorder,
              }}
            >
              {menuItems.map(({ key, value }) => {
                return (
                  <SelectItem
                    key={key}
                    onClick={() =>
                      setNegativeFormat(_.find(menuItems, { key }))
                    }
                  >
                    {value}
                  </SelectItem>
                );
              })}
            </SelectDropdown>
          </FormGroup>
        </StyledFormRow>
        <StyledFormRow>
          <FormGroup>
            <ControlLabel>{messages.customFormatModal.prefix}</ControlLabel>
            <FormInput
              placeholder={'prefix'}
              defaultValue={prefixValue}
              onChange={e => {
                setPrefixValue(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{messages.customFormatModal.suffix}</ControlLabel>
            <FormInput
              placeholder={'suffix'}
              defaultValue={suffixValue}
              onChange={e => {
                setSuffixValue(e.target.value);
              }}
            />
          </FormGroup>
        </StyledFormRow>
        <div className={'cf-row'}>
          <FormControlLabel
            sx={{
              marginLeft: '-2px',
              marginBottom: '9px',
            }}
            control={
              <Checkbox
                sx={{
                  padding: '0 2px 0 0',
                  color: darkMode ? Gray80 : Gray30,
                  svg: {
                    fontSize: '1.1rem',
                  },
                }}
                checked={includeSeparator}
                onChange={() => {
                  setIncludeSparator(!includeSeparator);
                }}
              />
            }
            label={
              <Typography sx={{ color: darkMode && Gray30 }}>
                {messages.formatString(
                  messages.customFormatModal.includeSeparator,
                  _.isEmpty(i18nPrefs?.numGroupSeparator)
                    ? ','
                    : i18nPrefs.numGroupSeparator,
                )}
              </Typography>
            }
          />
        </div>
        <div className={'cf-row'}>
          <FormControlLabel
            sx={{ marginLeft: '-2px', marginBottom: '15px' }}
            control={
              <Checkbox
                sx={{
                  padding: '0 2px 0 0',
                  color: darkMode ? Gray80 : Gray30,
                  svg: {
                    fontSize: '1.1rem',
                  },
                }}
                checked={includeCurrency}
                onChange={() => {
                  setIncludeCurrency(!includeCurrency);
                }}
              />
            }
            label={
              <Typography sx={{ color: darkMode && Gray30 }}>
                {messages.formatString(
                  messages.customFormatModal.includeCurrency,
                  currencySymbol,
                )}
              </Typography>
            }
          />
        </div>
        <div className={'cf-row header'}>
          <b>{messages.customFormatModal.sample}</b>
          <span>{messages.customFormatModal.sampleDescription}</span>
        </div>
        <StyledDisplayFormatArea>
          <StyledPreviewDiv>{formattedPreview}</StyledPreviewDiv>
          <StyledPreviewDiv>{negativeFormattedPreview}</StyledPreviewDiv>
        </StyledDisplayFormatArea>
      </StyledModalBody>
    </StyledCustomFormatModal>
  );
};
