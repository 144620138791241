import React from 'react';
import { messages } from '../../../../i18n';
import _ from 'lodash';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
} from '../../../../ui';

interface ISlicerButtonFooterProps {
  width: number | string;
  onSave: () => any;
  onCancel: () => any;
  saveDisabled: boolean;
  name: string;
}

export function SlicerButtonFooter({
  width,
  onSave,
  onCancel,
  name,
  saveDisabled,
}: ISlicerButtonFooterProps) {
  return (
    <ButtonContainer
      style={{ marginTop: '0.5rem', marginBottom: '0.5rem', width }}
    >
      <SecondaryButton
        id={`slicer-cancel-button-${_.kebabCase(name)}`}
        onClick={onCancel}
        sx={{
          marginRight: '0.5rem',
        }}
      >
        {messages.slicer.cancel}
      </SecondaryButton>
      <PrimaryButton
        id={`slicer-apply-button-${_.kebabCase(name)}`}
        onClick={onSave}
        disabled={saveDisabled}
      >
        {messages.slicer.apply}
      </PrimaryButton>
    </ButtonContainer>
  );
}
