import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../common/emotion';
import { StyledDropdownMenu } from '../slicer';
import { MenuItem } from '@sugar-discover/react-bootstrap-wrapper';

export const FilterWidgetStyledDropdownMenu = styled(StyledDropdownMenu)(() => {
  const { isMobile, isDashletMode } = useDiscoverTheme();

  return (
    isMobile &&
    css`
      width: ${!isDashletMode && 'calc(100% - 16px)'};

      &.tag-dropdown {
        min-width: ${!isDashletMode && 'unset !important'};
      }

      .ReactVirtualized__Grid__innerScrollContainer {
        min-width: ${!isDashletMode && 'unset !important'};
        max-width: ${!isDashletMode && 'unset !important'};
      }

      .ReactVirtualized__Grid.ReactVirtualized__List,
      [id*='slicer-option-row-tags'] {
        width: 100% !important;
      }

      .MuiFormControlLabel-label div {
        width: auto;
      }
    `
  );
});

export const FilterWidgetMenuItem = styled(MenuItem)(() => {
  const { isMobile, isDashletMode } = useDiscoverTheme();

  return css`
    width: ${isMobile || isDashletMode ? '100%' : 'inherit'};

    a {
      width: ${isMobile || isDashletMode ? '100%' : 'inherit'};
      cursor: default;

      :hover,
      :focus {
        text-decoration: none !important;
      }
    }
  `;
});
