import { ComponentClass } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { font } from '../../common/emotion/mixins';
import { IDataset } from '../../datasets/interfaces';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';
import ImportReportsDuplicateDialog from '../../common/widgets/dialogs/ImportReportsDuplicateDialog';

export const libraryStyles = ({
  theme: {
    colors: {
      ShareDropdownColor,
      MediumBorder,
      LightFontWeight,
      ContentBackground,
      DropdownBackgroundHover,
      ContentText,
    },
  },
}) => css`
  .library-table__toolbar .btn.query-tool {
    margin: 0 8px;
  }
  .library-table__toolbar .btn.report-defs {
    margin: 0 0 0 8px;
  }
  .library-table__toolbar .btn-group {
    display: inline-flex;
  }
  .library-table .share-dropdown .btn.dropdown-toggle {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92px;
    height: 32px;
  }
  .library-table .share-dropdown .btn.dropdown-toggle:not(:hover) {
    background: transparent;
    border-color: transparent;
  }
  .library-table .share-dropdown .btn.dropdown-toggle:focus,
  .library-table .share-dropdown .btn.dropdown-toggle:active {
    background: ${ContentBackground};
    border-color: ${MediumBorder};
  }
  li.panel-icon-dropdown .share-dropdown .dropdown-menu > li {
    padding-left: 0px;
    & > a {
      padding-left: 30px !important;
    }
  }
  .share-dropdown .dropdown-menu > li {
    padding-left: 30px;
  }
  .share-dropdown .dropdown-menu > li:not(.disabled):hover {
    background-color: ${DropdownBackgroundHover};
  }
  .share-dropdown .dropdown-menu > li:not(.disabled):hover a {
    color: ${ContentText} !important;
    background-color: inherit;
  }
  .share-dropdown .dropdown-menu > li > a .sicon-check {
    margin-left: -20px;
    margin-right: 10px;
  }
  .share-dropdown .dropdown-menu > li > a {
    ${font({ size: '12px', weight: LightFontWeight })}
    padding: 8px 16px 8px 0px !important;
    display: flex;
    align-items: center;
  }
  .share-dropdown__dropdown-menu > li > a small {
    font-size: 12px;
    color: ${ShareDropdownColor};
    margin-left: 5px;
  }
  /* add padding offset to sharing column header */
  .library-table .ReactVirtualized__Table__headerColumn:nth-of-type(4) {
    padding-left: 21px;
  }
`;

export const SearchOptionGroup = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 10px;

  @media (min-width: 1300px) {
    width: calc(100% - 635px);
    margin-top: 0;
  }

  > div {
    width: 250px;
  }
`;

export const PromptDialogTyped = PromptDialog as ComponentClass<{
  show?: boolean;
  detail?: any;
  doYes?: any;
  doNo?: any;
}>;

export const ImportReportsDuplicateDialogTyped = ImportReportsDuplicateDialog as ComponentClass<{
  show?: boolean;
  doSave?: any;
  doCancel?: any;
  reportsRequiringInput?: any;
  reportsNotRequiringInput?: any;
  datasets?: IDataset[];
}>;
