export interface ISlicerOption {
  option: string;
  isSelected: boolean;
  labelOverride?: string;
}

export interface ISlicer {
  name: string;
  options: ISlicerOption[];
}

export enum TimestampSlicerTypes {
  RANGE = 'RANGE',
  SINCE = 'SINCE',
  BETWEEN = 'BETWEEN',
}
