import { useEffect, useRef, forwardRef, KeyboardEvent } from 'react';
import { Box } from '@mui/system';
import { useDiscoverTheme } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';
import { DownChevron } from '../../../icons/icons';
import Paper from '@mui/material/Paper';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import { messages } from '../../../i18n';
import { BoxProps, BoxTypeMap } from '@mui/system/Box/Box';
import {
  StyledDiv,
  useDropdownStyles,
  useHeaderSx,
  useSelectItemSx,
} from './select-dropdown.styles';
import { usePopperProps } from './select-dropdown.hook';
import { ISelectDropdownProps } from './select-dropdown.interface';

export const SelectDropdown = forwardRef<
  HTMLDivElement,
  ISelectDropdownProps & { children?: any }
>(
  (
    {
      title,
      children,
      id,
      className,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      headerSx: providedHeaderSx = {},
      popperSx: providedPopperSx = {},
      listSx: providedListSx = {},
      disablePortal = true,
      ...remainingProps
    },
    forwardingRef,
  ) => {
    const listRef = useRef(null);

    const {
      PopperComponent,
      anchorEl,
      handleToggle: handlePopperToggle,
      handleClickAway: handlePopperClickAway,
      isOpen: isPopperOpen,
      renderedPlacement,
    } = usePopperProps();

    // asyncronously close menu on child click. allows the onClick for the ListItem to run
    useEffect(() => {
      if (listRef?.current) {
        listRef.current.addEventListener('click', e => {
          if (e.target.tagName !== 'INPUT') {
            setTimeout(() => handlePopperToggle(e), 50);
          }
        });
      }
    }, [listRef, handlePopperToggle]);

    const hasTitle = !!title;

    const { headerSx, popperSx, listSx } = useDropdownStyles({
      renderedPlacement,
      hasTitle,
      anchorEl,
      isOpen: isPopperOpen,
      headerSx: providedHeaderSx,
      popperSx: providedPopperSx,
      listSx: providedListSx,
    });

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e?.code === 'Space') {
        handlePopperToggle(e);
      }
    };

    const handleKeyUpCapture = (e: KeyboardEvent) => {
      if (e?.code === 'Escape') {
        handlePopperToggle(e);
      }
    };

    return (
      <StyledDiv
        ref={forwardingRef}
        id={id}
        className={className}
        onKeyUpCapture={handleKeyUpCapture}
        role={'listbox'}
        {...remainingProps}
      >
        <DropdownHeader
          sx={headerSx}
          onClick={handlePopperToggle}
          onKeyUp={handleKeyUp}
          tabIndex={0}
          isOpen={isPopperOpen}
          aria-labelledby={ariaLabelledby}
          aria-label={ariaLabel}
          role={'list'}
        >
          {title ?? `${messages.search}...`}
        </DropdownHeader>
        <PopperComponent
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          sx={popperSx}
          disablePortal={disablePortal}
        >
          <ClickAwayListener onClickAway={handlePopperClickAway}>
            <Paper square elevation={0}>
              <List ref={listRef} sx={listSx} disablePadding>
                {children}
              </List>
            </Paper>
          </ClickAwayListener>
        </PopperComponent>
      </StyledDiv>
    );
  },
);

export const SelectItem = ({ sx, children, ...props }: ListItemProps) => {
  const itemSx = useSelectItemSx({ providedSx: sx });

  return (
    <ListItem
      role={'listitem'}
      tabIndex={0}
      sx={itemSx}
      disablePadding
      {...props}
    >
      {children}
    </ListItem>
  );
};

export const DropdownHeader = ({
  sx = {},
  children,
  isOpen = false,
  'aria-labelledby': ariaLabelledby,
  'aria-label': ariaLabel,
  ...props
}: BoxProps<BoxTypeMap['defaultComponent'], { isOpen?: boolean }>) => {
  const { colors: { Gray70 } = {} } = useDiscoverTheme();

  const headerSx = useHeaderSx({
    providedSx: sx,
    isOpen,
  });

  return (
    <Box sx={headerSx} {...props}>
      <span
        className={'title'}
        aria-labelledby={ariaLabelledby}
        aria-label={ariaLabel}
      >
        {children}
      </span>
      <DownChevron className={'caret'} size={12} color={Gray70} />
    </Box>
  );
};
