import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollingNav } from '../../components/scrolling-nav';
import { VizComponent as Viz } from '../viz';
import Discover from '../../common/redux/actions/DiscoverActions';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getIsReadOnlyUser } from '../../common/redux/selectors/AccountSelectors';
import { messages } from '../../i18n';
import { AddIcon, CloseIcon } from '../../icons/icons';
import { IndicatorBubble } from '../../icons/sugar-icon/sugar-icon.util';
import {
  TabStyled,
  DiscoveryTabText,
  CloseDiscovery,
  DiscoveryDirtyIndicator,
  AddDiscovery,
} from './discover-tabs.styles';
import { IRevision } from '../interfaces';

const DiscoveryContent = props => <Viz vizId={props.id} />;

export const DiscoverTabs = () => {
  const {
    openDiscoveries,
    openDiscoveriesTabList,
    displayDiscovery,
    isMobile,
    isUserReadOnly,
  } = useSelector((state: any) => {
    const firstOpen = _.keys(state.discover.openDiscoveries)[0];
    const isDisplayDiscovery = _.get(
      state,
      'discover.displayDiscovery',
      firstOpen,
    );
    return {
      openDiscoveries: state.discover.openDiscoveries,
      openDiscoveriesTabList: state.discover.openDiscoveriesTabList,
      displayDiscovery: isDisplayDiscovery || firstOpen,
      isMobile: state.main.isMobile,
      isUserReadOnly: getIsReadOnlyUser(state),
    };
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const setDisplayDiscovery = id => {
    dispatch(Discover.setDisplayDiscovery(id));
  };
  const closeDiscovery = (id, force = false) => {
    dispatch(Discover.closeDiscovery(id, { force }));
  };
  const showNewViz = () => {
    history.push('/newviz');
  };

  // maintaining order with open discoveries
  const existingDiscoveryIds = _.filter(
    openDiscoveriesTabList,
    discId => !!openDiscoveries[discId],
  );
  const discoveryNavItems = _.map(existingDiscoveryIds, discoveryId => {
    const discovery = openDiscoveries[discoveryId];
    const { id, name, dirty, canUpdate, viz, updatedOn } = discovery.present;
    const lastRevision: IRevision = _.head(viz?.revisions);
    const isNewViz =
      _.startsWith(name, 'Untitled') &&
      _.startsWith(id, 'newViz-') &&
      _.isEmpty(viz?.revisions);
    const hasUnversionedSaves =
      _.isEmpty(lastRevision) || !_.isEqual(updatedOn, lastRevision.updatedOn);
    return (
      <TabStyled
        key={id}
        label={
          <>
            {canUpdate && dirty && (
              <DiscoveryDirtyIndicator
                title={messages.viz.unsavedChangesAndVersion}
              >
                <IndicatorBubble size={8} />
              </DiscoveryDirtyIndicator>
            )}
            {!_.isNil(viz) &&
              !isNewViz &&
              canUpdate &&
              !dirty &&
              hasUnversionedSaves && (
                <DiscoveryDirtyIndicator title={messages.viz.unsavedVersion}>
                  <IndicatorBubble size={8} empty={true} />
                </DiscoveryDirtyIndicator>
              )}
            <DiscoveryTabText>{name}</DiscoveryTabText>
            <CloseDiscovery
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                closeDiscovery(id, isUserReadOnly);
              }}
            >
              <CloseIcon size={10} />
            </CloseDiscovery>
          </>
        }
        value={id}
      />
    );
  });

  const open = openDiscoveries[displayDiscovery]?.present;
  return (
    <div className='discover-open-panel'>
      <div className='discover-tab-panel'>
        <ScrollingNav
          activeKey={displayDiscovery}
          navItems={discoveryNavItems}
          handleSelect={id => setDisplayDiscovery(id)}
          addItem={
            !isMobile &&
            !isUserReadOnly && (
              <AddDiscovery
                className={'sticky sticky-right'}
                key={'add-discovery'}
                title={messages.addReportTooltip}
                onClick={() => showNewViz()}
              >
                <AddIcon hover />
              </AddDiscovery>
            )
          }
        />
        {open && <DiscoveryContent {...open} />}
      </div>
    </div>
  );
};
