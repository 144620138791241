import { DiscoverModalContainerContext } from '@sugar-discover/react-bootstrap-wrapper';
import { RefObject, useContext, useRef, useEffect } from 'react';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  callback: () => void,
  isElementVisible: boolean,
): RefObject<T> => {
  const ref = useRef<T>();
  const container = useContext(DiscoverModalContainerContext) ?? document;

  useEffect(() => {
    const handleClick = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    isElementVisible && container?.addEventListener('click', handleClick, true);

    return () => {
      container?.removeEventListener('click', handleClick, true);
    };
  }, [callback, ref, isElementVisible, container]);

  return ref;
};
