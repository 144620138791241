import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import {
  DiscoverModalContainerContext,
  Dropdown,
} from '@sugar-discover/react-bootstrap-wrapper';

class PortalDropdownMenu extends Dropdown.Menu {
  static contextType = DiscoverModalContainerContext;
  get document() {
    return this.context || document;
  }
  get anchorRect() {
    const anchorNode = this.getElementById(this.props.anchorId);
    const clientRect = anchorNode?.getBoundingClientRect();
    if (!this.context) {
      return clientRect;
    }
    const rootRect = this.context.getBoundingClientRect();
    const adjustedRect = _.mapValues(
      _.omit(clientRect, 'width', 'height'),
      (val, key) => val - _.get(rootRect, key, 0),
    );
    return { ...adjustedRect, ..._.pick(clientRect, 'width', 'height') };
  }

  getElementById(id) {
    return this.document.querySelector(`#${id}`);
  }
  constructor(props) {
    super(props);
    this.domNode = document.createElement('div');
    this.domNode.classList.add('portal-dropdown');
    if (props.className) {
      props.className.split(' ').map(item => {
        this.domNode.classList.add(item);
      });
    }
  }

  componentDidMount() {
    const mainDiv = this.getElementById(this.props.attachnodeid);
    if (!_.isNil(mainDiv) && this.domNode) {
      mainDiv.appendChild(this.domNode);
    }
  }

  componentWillUnmount() {
    const mainDiv = this.getElementById(this.props.attachnodeid);
    if (!_.isNil(mainDiv) && this.domNode) {
      mainDiv.removeChild(this.domNode);
    }
    this.domNode?.remove();
    this.domNode = null;
  }

  shouldComponentUpdate(nextProps) {
    if (_.isFunction(this.props.shouldUpdate)) {
      return this.props.shouldUpdate(nextProps);
    }
    return this.props.open !== nextProps.open;
  }

  _isInDashlet() {
    return this.document.getRootNode() !== this.document;
  }

  _positionDropdown(location, tryToRepositionIfItDoesntFit = true) {
    const menuNode = this.domNode.querySelector('.dropdown-menu');
    const rect = this.anchorRect;
    if (rect) {
      const computedStyle = window.getComputedStyle(menuNode);
      const anchorWidth = rect.width;
      let top;
      let left;
      let bottom;
      let right;

      menuNode.style.display = 'block';
      let menuBounds = menuNode.getBoundingClientRect();

      switch (location) {
        case 'right':
          top =
            rect.top -
            (parseFloat(computedStyle['padding-top']) +
              parseFloat(computedStyle['margin-top']));
          left = rect.right;
          break;
        case 'top':
          bottom = rect.top;
          left = rect.left;
          break;
        case 'top-left':
          bottom = rect.top;
          left = rect.right - menuBounds.width;
          break;
        case 'left':
          top =
            rect.top -
            (parseFloat(computedStyle['padding-top']) +
              parseFloat(computedStyle['margin-top']));
          right = rect.left;
          break;
        case 'bottom-left':
          top = rect.top + rect.height;
          left = rect.right - menuBounds.width;
          break;
        case 'bottom':
        default:
          top = rect.top + rect.height;
          left = rect.left;
          break;
      }

      if (this.props.offsetTop) {
        top = (top || 0) + this.props.offsetTop;
      }
      if (this.props.offsetLeft) {
        left += (left || 0) + this.props.offsetLeft;
      }

      if (!_.isNil(top)) {
        menuNode.style.top = `${top}px`;
      }
      if (!_.isNil(left)) {
        menuNode.style.left = `${left}px`;
      }
      if (!_.isNil(bottom)) {
        menuNode.style.top = `${bottom - menuBounds.height - 4}px`;
      }
      if (!_.isNil(right)) {
        menuNode.style.right = `${right}px`;
      }

      // enforce minimum width
      if (this.props.minWidth) {
        menuNode.style.minWidth = `${this.props.minWidth}px`;
      } else if (!_.isNil(anchorWidth)) {
        menuNode.style.minWidth = `${anchorWidth}px`;
        if (this._isInDashlet()) {
          menuNode.style.maxWidth = `${anchorWidth}px`;
        }
      }

      menuBounds = menuNode.getBoundingClientRect();
      const viewWidth = this.document.clientWidth || window.innerWidth;
      const viewHeight = this.document.clientHeight || window.innerHeight;
      // determine if it fits within the bounds of the window...
      if (tryToRepositionIfItDoesntFit) {
        if (
          location === 'bottom' &&
          menuBounds.bottom > viewHeight &&
          !this._isInDashlet() &&
          !this.props.isMobile
        ) {
          this._positionDropdown('top', false);
        } else if (
          location === 'bottom-left' &&
          menuBounds.bottom > viewHeight
        ) {
          this._positionDropdown('top-left', false);
        } else if (location === 'top' && menuBounds.top < 0) {
          this._positionDropdown('bottom', false);
        } else if (location === 'right' && menuBounds.right > viewWidth) {
          this._positionDropdown('left', false);
        } else if (location === 'left' && menuBounds.right < 0) {
          this._positionDropdown('right', false);
        }
      }
    } else {
      menuNode.style.display = 'none';
    }
  }

  componentDidUpdate(prevProps) {
    const { open, location, menuContentWillChange } = this.props;
    const menuNode = this.domNode.querySelector('.dropdown-menu');
    const anchorNode = this.getElementById(this.props.anchorId);
    // if the open prop was toggled...
    if (prevProps.open !== open || menuContentWillChange) {
      if (open && anchorNode) {
        this._positionDropdown(location);
      } else {
        menuNode.style.display = 'none';
      }
    }
  }

  render() {
    const superRender = super.render();
    if (this.domNode) {
      return ReactDOM.createPortal(superRender, this.domNode);
    }
    return null;
  }
}
PortalDropdownMenu.propTypes = {
  ...Dropdown.Menu.propTypes,
  anchorId: PropTypes.string,
};
PortalDropdownMenu.defaultProps = {
  ...Dropdown.Menu.defaultProps,
  attachnodeid: 'mainDiv',
  location: 'bottom',
  open: false,
  minWidth: null,
  shouldUpdate: null,
  menuContentWillChange: false,
  isMobile: false,
};

export default PortalDropdownMenu;
