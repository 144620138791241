import { useMemo } from 'react';
import { includes, filter, map, isFunction, isEmpty } from 'lodash';
import DatasetQueries from '../../common/graphql/DatasetQueries';
import { messages, i18nUtils } from '../../i18n';
import { useQuery } from '@apollo/client';
import { IAggregationDropdownProps } from './aggregation-dropdown.interfaces';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';

export const AggregationDropdown = ({
  dataType = 'NUMBER',
  onSelect,
  defaultValue,
  disablePortal,
  headerSx,
  popperSx,
}: IAggregationDropdownProps) => {
  const { data: { aggregateInfo } = {} } = useQuery(
    DatasetQueries.AggregationInfo,
  );

  const options = useMemo(() => {
    const supportedAggs = filter(aggregateInfo, ({ supportedTypes }) =>
      includes(supportedTypes, dataType),
    );

    return map(supportedAggs, aggValue => (
      <SelectItem
        key={`aggValue`}
        onClick={() => isFunction(onSelect) && onSelect(aggValue)}
      >
        {i18nUtils.getAggregationDisplayText(aggValue?.name)}
      </SelectItem>
    ));
  }, [aggregateInfo, dataType, onSelect]);

  return isEmpty(options) ? (
    <div>{messages.aggregation.noAggregationsFound}</div>
  ) : (
    <SelectDropdown
      title={
        <span className={'dropdown-title'}>
          {i18nUtils.getAggregationDisplayText(defaultValue?.value)}
        </span>
      }
      disablePortal={disablePortal}
      popperSx={popperSx}
      headerSx={headerSx}
    >
      {options}
    </SelectDropdown>
  );
};
