import { ComponentType, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Form, MenuItem } from '@sugar-discover/react-bootstrap-wrapper';

export const I18nSettingsForm: ComponentType<{
  isMobile?: boolean;
  children?: ReactNode;
}> = styled(Form)`
  padding: 15px;
  width: ${({ isMobile }: any) => (isMobile ? '100%' : 'calc(100vw - 240px)')};
` as any;

export const I18nOptionRow = styled(MenuItem)({
  width: '100%',
  button: { width: '100%' },
});
